import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

export default function SvgPathLoader() {
 const controls1 = useAnimation();
  const controls2 = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      // Fade in SVGID_1 first
      await controls1.start({
        opacity: 1,
        transition: { duration: 1 },
      });
      // After a delay, fade in SVGID_2
      await controls2.start({
        opacity: 1,
        transition: { duration: 1 },
      });
    };

    sequence();
  }, [controls1, controls2]);

  return (
        <motion.div
      style={{
        width: '250px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="190.647 -8.993 332.135 327.338"
        width="100%"
        height="100%"
        xmlSpace="preserve"
      >
        {/* SVGID_1 */}
        <motion.g
          animate={controls1}
          initial={{ opacity: 0 }}
          transform="matrix(1, 0, 0, 1, -17.168835, -682.016235)"
        >
          <path
            d="M533.01,805.82l-21.751,40.365c-5.289,70.934-64.664,126.977-136.777,126.977 
           c-39.384,0-75.049-16.85-100.131-43.693c-19.009-20.379-31.941-46.443-35.665-75.445h35.665h27.824l9.799,9.604v25.863h32.136 
           v-30.373l-18.418-18.416l14.501-19.598l-20.38-37.623H274.35h-26.453h-19.398h-6.466c-5.879,16.459-9.016,34.098-9.016,52.713 
           c0,50.945,24.104,96.799,61.334,126.393c27.63,21.941,62.312,34.873,100.131,34.873c88.962,0,161.467-72.498,161.467-161.266 
           C535.948,825.805,534.971,815.615,533.01,805.82z M240.646,806.994h33.704h28.218l8.425,11.17l-8.425,11.758H274.35h-36.839 
           C237.902,822.084,238.882,814.441,240.646,806.994z"
            style={{ fill: 'rgb(255, 255, 255)' }}
          />
        </motion.g>

        {/* SVGID_2 */}
        <motion.g
          animate={controls2}
          initial={{ opacity: 0 }}
          transform="matrix(1, 0, 0, 1, -17.168835, -682.016235)"
        >
          <path
            d="M526.933,793.082l-52.121,96.408h-29.982l-12.342-22.73l-12.348,22.73h-29.982l-57.608-106.203 
           h32.92l39.779,72.5l10.583-19.594l-28.611-52.906h32.725l12.345,22.73l12.54-22.73h32.924l-28.614,52.906l10.781,19.789 
           l40.562-74.264c-21.166-48.598-69.759-82.695-126-82.695c-51.143,0-96.017,28.414-119.53,70.152h-27.237 
           c25.472-55.65,81.71-94.449,146.767-94.449c72.699,0,134.426,48.205,154.415,114.436L526.933,793.082L526.933,793.082z"
            style={{ fill: 'rgb(51, 236, 61)' }}
          />
        </motion.g>
      </svg>
    </motion.div>
  );
};

