import { FC, ChangeEvent, useState, useRef, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
} from "@mui/material";
import { format } from "date-fns";
import { TokenIcon } from "../../components/tokenIcon";
import { IGameFeed, TOKENTYPE } from "../../types/index.d";
import { parseHiddenUsername } from "../../constants";
import { selectCurrentUser, selectUserType } from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { isModUser } from "../chat/textBubble";
import { RoundNumberFourDecimalPoints } from "../../utils";
import { ChallengesDocument } from "../slices/challengesSlice";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import RWFreeTokenIcon from "../../assets/freeCashToken.svg";
function checkStringLength(inputString: string) {
  if (inputString.length > 15) {
    return inputString.slice(0, 15) + "...";
  }
  return inputString;
}

const ChallengesTable = ({
  challenges,
}: {
  challenges: ChallengesDocument[];
}) => {
  return (
    <Card sx={{ my: 3 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#273F55" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                }}
              >
                Challenge
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                }}
                align="right"
              >
                Prize
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {challenges &&
              challenges.map((item, index) => {
                if (index >= 10) {
                  return null;
                }
                return (
                  <TableRow
                    key={item._id}
                    style={{
                      backgroundColor: index % 2 ? "#1C2B39" : "#213240",
                    }}
                  >
                    <TableCell sx={{ borderColor: "#273F55" }}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textWrap: "auto" }}
                      >
                        First to hit {item.multiplier}x with min {item.minBet}{" "}
                        {/* {getTokenIcon(item.tokenType)} */}
                        <img
                          src={
                            item.tokenType === TOKENTYPE.FREE
                              ? RWFreeTokenIcon
                              : RWTokenIcon
                          }
                          style={{
                            width:
                              item.tokenType === TOKENTYPE.FREE
                                ? "1em"
                                : "1.2em",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        play
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        borderColor: "#273F55",
                      }}
                      align="right"
                    >
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.reward}{" "}
                        <img
                          src={
                            item.tokenType === TOKENTYPE.FREE
                              ? RWFreeTokenIcon
                              : RWTokenIcon
                          }
                          style={{
                            width:
                              item.tokenType === TOKENTYPE.FREE
                                ? "1em"
                                : "1.2em",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ChallengesTable;
