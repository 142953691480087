import { Stack, Typography } from "@mui/material";
import { InputFieldStyled } from "../inputField";
import { useEffect, useState } from "react";
import { Button } from "../button";
import HCaptcha from "../HCaptcha";
import { useTurnstile } from "react-turnstile";
import { theme } from "../theme";
import Users from "../../services/user";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, selectToken } from "../slices/userSlice";
import { ORDERTYPES } from "../../types/index.d";

export const PromoCode = ({ currentType }: { currentType: ORDERTYPES }) => {
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [isError, setIsError] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [token, setToken] = useState<any>(null);
  const turnstile = useTurnstile();
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const accessToken = useSelector(selectToken);

  const handlePrmoCode = async () => {
    if (!token) {
      setIsError(true);
      setMessage("Please complete the HCaptcha");
      return;
    }

    try {
      setIsError(false);
      setLoadingPromo(true);
      const res = await Users.promoCode(accessToken, promoCode, token);
      if (res) {
        setIsError(false);
        setMessage(res.message);
      }
    } catch (e: any) {
      setMessage(e.message);
      setIsError(true);
    } finally {
      setLoadingPromo(false);
    }
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleGiftCard = async () => {
    if (!token) {
      setIsError(true);
      setMessage("Please complete the HCaptcha");
      return;
    }

    try {
      setIsError(false);
      setLoadingPromo(true);
      const res = await Users.giftCard(accessToken, promoCode, token);
      if (res) {
        setIsError(false);
        setMessage(res.message);
        if (res.completed) {
          handleClose();
        }
      }
    } catch (e: any) {
      setMessage(e.message);
      setIsError(true);
    } finally {
      setLoadingPromo(false);
    }
  };

  useEffect(() => {
    if (message && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [message]);

  return (
    <Stack gap={0.5}>
      <Typography color="text.primary" variant="h6">
        {currentType === ORDERTYPES.PROMOCODE ? "Promo Code" : "Redeem Gift"}
      </Typography>
      <Stack direction="row" gap={1} sx={{ mb: 1 }}>
        <InputFieldStyled
          type={"string"}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          sx={{ width: { sm: "100%", xs: "100%" } }}
        />
        <Button
          onClick={() => {
            if (currentType === ORDERTYPES.PROMOCODE) {
              handlePrmoCode();
            } else {
              handleGiftCard();
            }
          }}
          variantType={"success"}
          disabled={!promoCode || loadingPromo}
        >
          Submit
        </Button>
      </Stack>
      <HCaptcha onVerify={setToken} forceNormal />
      {message && (
        <Typography
          sx={{
            color: isError
              ? theme.palette.error.main
              : theme.palette.success.main,
          }}
        >
          {message}
        </Typography>
      )}
    </Stack>
  );
};
