import { Stack, IconButton, Typography, useMediaQuery } from "@mui/material";
import {
  FormEvent,
  useContext,
  useRef,
  useState,
  memo,
  useEffect,
} from "react";
import ChatContext from "../../contexts/chat/context";
import { InputFieldStyled } from "../inputField";
import {
  selectCurrentUser,
  selectPlayer,
  selectToken,
  updateIsTyping,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import Picker, { Theme } from "emoji-picker-react";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import { theme } from "../theme";
import { CHARACTER_LIMIT, CHATTYPES } from "../../constants/chat";
import { ADMIN_READ_AT_UPDATE } from "../../constants/socket";
import { selectAdminChat } from "../slices/chatSlice";
import { IMessage } from "../../types";
import { useDispatch } from "react-redux";
import GifPicker, { Theme as GIFTheme, ContentFilter } from "gif-picker-react";
import GifIcon from "@mui/icons-material/Gif";
import { customEmojisData } from "../../constants/messaages";

export const MessageInput = ({
  chatType,
  orderId,
  selectedChat,
}: {
  chatType: CHATTYPES;
  orderId?: string;
  selectedChat: string;
}) => {
  const [newMessage, setNewMessage] = useState("");
  const accessToken = useAppSelector(selectToken);
  const user = useAppSelector(selectCurrentUser);
  const { socket } = useContext(ChatContext);
  const [showPicker, setShowPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const timerRef = useRef<number>(0);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const contentEditableRef = useRef<HTMLDivElement>(null);

  const onEmojiClick = (emojiObject: any) => {
    setNewMessage((prevInput: string) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };
  const dispatch = useDispatch();
  const chatData = useAppSelector(selectAdminChat);
  const tagRemovalRegex: RegExp = /<\/?(meta|link|span|font|div)\b[^>]*>/gi;

  const handleSendMessage = (
    event?: FormEvent,
    message = newMessage
      .replaceAll("&nbsp;", " ")
      .replaceAll("&lt;", "")
      .replaceAll("&gt;", "")
      .replaceAll("<br>", "")
  ) => {
    if (event) {
      event.preventDefault();
    }
    const currentTime = Date.now();
    const startTime = timerRef?.current;
    if (
      message &&
      (!startTime || currentTime - startTime > 1000) &&
      chatType === CHATTYPES.Community
    ) {
      message = message.replaceAll(tagRemovalRegex, "");
      socket?.emit("new-message", {
        text: message,
        accessToken,
      });
      timerRef.current = currentTime;
      setNewMessage("");
      return;
    }

    if (
      message &&
      (!startTime || currentTime - startTime > 1000) &&
      (chatType === CHATTYPES.WITHDRAWAL || chatType === CHATTYPES.DEPOSIT)
    ) {
      message = message.replaceAll(tagRemovalRegex, "");

      socket?.emit("new-message-admin", {
        text: message,
        accessToken,
        orderId,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }

    if (message && chatType === CHATTYPES.ADMIN) {
      message = message.replaceAll(tagRemovalRegex, "");
      socket?.emit("new-message-admin", {
        text: message,
        accessToken,
        orderId: selectedChat,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }
  };

  const sendSocketReadAt = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      const selected = chatData.find((chat) => chat.order._id === selectedChat);
      const unReadMessages =
        selected?.messages?.filter(
          (data: IMessage) => !data?.readAt && !data.isAdmin
        ) || [];

      if (unReadMessages?.length) {
        socket?.emit(ADMIN_READ_AT_UPDATE, {
          accessToken,
          orderId: selectedChat,
        });
      }
    }
  };

  // const setCursorToEnd = () => {
  //   const el = contentEditableRef.current;

  //   if (el) {
  //     const range = document.createRange();
  //     const selection = window.getSelection();

  //     if (selection?.getRangeAt && el) {
  //       const currentRange = selection.getRangeAt(0);
  //       const lastTypedPosition = currentRange ? currentRange.startOffset : 0;
  //       const contentLength = el?.textContent?.length || 0;

  //       // Check if the last typed position is at the end
  //       if (lastTypedPosition === contentLength) {
  //         // Set cursor to the end
  //         range.selectNodeContents(el);
  //         range.collapse(false);
  //       } else {
  //         // Move the cursor one position forward from the last typed position
  //         if (el.firstChild) {
  //           range.setStart(el?.firstChild, lastTypedPosition + 1);
  //           range.collapse(true);
  //         }
  //       }

  //       selection.removeAllRanges();
  //       selection.addRange(range);
  //     }
  //   }
  // };
  const setCursorToEnd = () => {
    const el = contentEditableRef.current;

    // Save cursor position
    // const selection = document.getSelection();

    if (el) {
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(el);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  const handleOnFocus = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      sendSocketReadAt();
    }
    if (showPicker) {
      setShowPicker((val) => !val);
    }
    if (showGifPicker) {
      setShowGifPicker((val) => !val);
    }
    dispatch(updateIsTyping(true));
  };

  const loseFocus = () => {
    dispatch(updateIsTyping(false));
  };

  const handleGifClick = (data: any) => {
    if (data?.preview) {
      setShowGifPicker((val) => !val);
      handleSendMessage(undefined, JSON.stringify(data?.preview));
    }
  };

  const displayWithEmojis = (text: string) => {
    let updatedText = text;
    customEmojisData.forEach((emoji) => {
      const regex = new RegExp(emoji.id, "g");
      updatedText = updatedText.replace(
        regex,
        `<img src="${emoji.imgUrl}" alt="${emoji.id}" style="width:15px;height:15px; display:inline-block;" />`
      );
    });

    return updatedText;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    let rawText = e.clipboardData.getData("text");
    const imgRegex = /<img[^>]+alt=["']?([^"'>]*)["']?[^>]*>/g;
    const newMessage = rawText.replace(imgRegex, (match: any, altText: any) => {
      return altText;
    });
    setNewMessage(newMessage);
  };

  const handleInput = (e: any) => {
    let rawText = e.currentTarget.innerHTML || "";
    const imgRegex = /<img[^>]+alt=["']?([^"'>]*)["']?[^>]*>/g;
    const newMessage = rawText.replace(imgRegex, (match: any, altText: any) => {
      return altText;
    });

    // console.log("Last typed position:", lastTypedPosition);
    if (handleLength() < CHARACTER_LIMIT) {
      setNewMessage(newMessage);
    } else {
      // e.target.textContent = newMessage.substring(0, CHARACTER_LIMIT);
      e.preventDefault();
    }
  };

  // const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
  //   const contentEditable = e.currentTarget;
  //   let rawText = contentEditable.innerHTML || "";
  //   const imgRegex = /<img[^>]+alt=["']?([^"'>]*)["']?[^>]*>/g;
  //   const newMessage = rawText.replace(imgRegex, (match, altText) => altText);

  //   // Save the current cursor position
  //   const selection = document.getSelection();
  //   const range =
  //     selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  //   const lastTypedPosition = range ? range.startOffset : 0;

  //   if (handleLength() < CHARACTER_LIMIT) {
  //     const prevLength = rawText.length;

  //     // Update the state with newMessage
  //     setNewMessage(newMessage);

  //     // Wait for the state to update in the DOM, then adjust the cursor
  //     setTimeout(() => {
  //       const newLength = newMessage.length;
  //       let newPosition = lastTypedPosition;

  //       // Adjust cursor position based on content change
  //       if (newLength > prevLength) {
  //         newPosition = Math.min(lastTypedPosition + 1, newLength); // Typing, so +1
  //       } else if (newLength < prevLength) {
  //         newPosition = Math.max(lastTypedPosition - 1, 0); // Deletion, so -1
  //       }

  //       // Set the cursor to the new position
  //       if (selection && contentEditable.firstChild) {
  //         const newRange = document.createRange();
  //         try {
  //           newRange.setStart(contentEditable.firstChild, newPosition);
  //           newRange.collapse(true);

  //           selection.removeAllRanges();
  //           selection.addRange(newRange);
  //         } catch (error) {
  //           console.error("Failed to set cursor position:", error);
  //         }
  //       }
  //     }, 0);
  //   } else {
  //     // Limit text to CHARACTER_LIMIT and prevent further input
  //     contentEditable.textContent = newMessage.substring(0, CHARACTER_LIMIT);
  //     e.preventDefault();
  //   }
  // };

  useEffect(() => {
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = displayWithEmojis(newMessage);
    }
    handleLength();
    setCursorToEnd();
  }, [newMessage]);

  const handleLength = () => {
    let text = newMessage.replaceAll("&nbsp;", " ");
    customEmojisData.forEach((emoji) => {
      const regex = new RegExp(emoji.id, "g"); // Match all occurrences
      text = text.replace(regex, ` `);
    });
    return text.length;
  };

  return (
    <form onSubmit={handleSendMessage}>
      <Stack direction="column">
        <Stack direction="row" gap={1} sx={{ position: "relative" }}>
          <Stack sx={{ position: "relative", width: "89%", maxWidth: "80%" }}>
            <div
              suppressContentEditableWarning={true}
              ref={contentEditableRef}
              onKeyDown={handleKeyDown}
              onPaste={(e: any) => handlePaste(e)}
              style={{
                textWrap: "wrap",
                border: "1.2px solid #213240",
                background: "#213240",
                padding: "10px 12px",
                outline: "none",
              }}
              contentEditable={
                (!selectedChat && chatType === CHATTYPES.ADMIN) ||
                (chatType === CHATTYPES.Community && user.isMuted)
                  ? false
                  : true
              }
              onFocus={() => handleOnFocus()}
              onBlur={(e: any) => {
                handleInput(e);
                loseFocus();
              }}
              onInput={(e: any) => {
                handleInput(e);
                e.stopPropagation();
              }}
            >
              {displayWithEmojis(newMessage)}
            </div>
          </Stack>
          <Stack direction="row">
            <IconButton
              onClick={() => {
                setShowPicker((val) => !val);
                if (showGifPicker) {
                  setShowGifPicker((val) => !val);
                }
              }}
              sx={{ p: 0.5 }}
            >
              <EmojiEmotionsOutlinedIcon
                sx={{
                  color: "#9897A9",
                  fontSize: "0.78em",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setShowGifPicker((val) => !val);
                if (showPicker) {
                  setShowPicker((val) => !val);
                }
              }}
              sx={{ p: 0 }}
            >
              <GifIcon
                sx={{
                  color: "#9897A9",
                  fontSize: "1.2em",
                }}
              />
            </IconButton>
          </Stack>

          {showPicker && (
            <EmojiPicker onEmojiClick={onEmojiClick} matchesMD={matchesMD} />
          )}
          {showGifPicker && (
            <GifPickerReact onGifClick={handleGifClick} matchesMD={matchesMD} />
          )}
        </Stack>
        <Typography
          sx={{
            fontSize: ".65em",
            zIndex: 1,
            textAlign: "end",
            marginRight: "15%",
          }}
          variant="subtitle1"
        >{`${handleLength()}/${CHARACTER_LIMIT}`}</Typography>
      </Stack>
    </form>
  );
};
const EmojiPicker = memo(
  ({
    onEmojiClick,
    matchesMD,
  }: {
    onEmojiClick: (emojiObject: any) => void;
    matchesMD: boolean;
  }) => (
    <Picker
      lazyLoadEmojis
      style={{
        position: "absolute",
        width: matchesMD ? "290px" : "96vw",
        height: matchesMD ? "350px" : "350px",
        transform: "translate(-1%,-102%)",
      }}
      customEmojis={customEmojisData}
      searchDisabled={false}
      autoFocusSearch={false}
      skinTonesDisabled={true}
      theme={Theme.DARK}
      onEmojiClick={onEmojiClick}
    />
  )
);

const GifPickerReact = memo(
  ({
    onGifClick,
    matchesMD,
  }: {
    onGifClick: (data: any) => void;
    matchesMD: boolean;
  }) => (
    <Stack
      sx={{
        position: "absolute",
        width: matchesMD ? "290px" : "96vw",
        height: matchesMD ? "360px" : "450px",
        transform: "translate(-1%,-102%)",
      }}
    >
      <GifPicker
        theme={GIFTheme.DARK}
        width={matchesMD ? "290px" : "96vw"}
        height={matchesMD ? "360px" : "450px"}
        categoryHeight={100}
        contentFilter={ContentFilter.LOW}
        autoFocusSearch={false}
        tenorApiKey="AIzaSyDpRp_pYFl2tFPhDlhbCPRaICOViuNmZj0"
        onGifClick={(data: any) => {
          onGifClick(data);
        }}
      />
    </Stack>
  )
);
