import { GAMESTATUS, SELECTIONSTATUS } from "../../constants";
import { Button } from "../button";
import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { TokenIcon } from "../tokenIcon";
import { DICEMODE } from "../../types/index.d";
import { GameTypeButtonReset, IAutoPlayStates } from "../dice/diceButtons";
import { InputAdornment, Stack, Typography } from "@mui/material";
import { InputFieldStyled } from "../inputField";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import PercentIcon from "@mui/icons-material/Percent";

interface IBetButton {
  gameStatus: GAMESTATUS;
  revealedCells: string[];
  handlePayout: () => void;
  disabled: boolean;
  payout: number;
  isFunCash: boolean;
}

interface ISliderWithLabel {
  playType: DICEMODE;
  autoPlayStates: IAutoPlayStates;
  setAutoPlayStates: (states: IAutoPlayStates) => void;
  isAutoPlaying: boolean;
  currentNumberOfGames: number;
}

export default function MinesAutoButtons({
  playType,
  autoPlayStates,
  setAutoPlayStates,
  isAutoPlaying,
  currentNumberOfGames,
}: ISliderWithLabel) {
  const [winStatus, setWinStatus] = useState(SELECTIONSTATUS.RESET);
  const [loseStatus, setLoseStatus] = useState(SELECTIONSTATUS.RESET);

  const updateField = (key: string, value: number | any) => {
    let currentValue = value;
    if (currentValue !== null && currentValue < 0) {
      return;
    }
    if (key === "numberOfGames" && currentValue && currentValue > 999999999) {
      currentValue = 999999999;
    }
    setAutoPlayStates({
      ...autoPlayStates,
      [key]: currentValue,
    });
  };

  const handleWinStatus = (type: SELECTIONSTATUS) => {
    if (type === SELECTIONSTATUS.RESET) {
      updateField("onWin", 0);
    }
    setWinStatus(type);
    updateField("winStatus", type);
  };

  const handleLossStatus = (type: SELECTIONSTATUS) => {
    if (type === SELECTIONSTATUS.RESET) {
      updateField("onLoss", 0);
    }
    setLoseStatus(type);
    updateField("lossStatus", type);
  };

  const numberOfGames = !isAutoPlaying
    ? autoPlayStates.numberOfGames
    : currentNumberOfGames;
  return (
    <Stack
      gap={0.5}
      sx={{ display: playType === DICEMODE.AUTO ? "flex" : "none" }}
    >
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Number of Games</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          endAdornment={
            <InputAdornment
              position="start"
              sx={{
                display: autoPlayStates.numberOfGames && "none",
                color: "white",
                ml: 0.5,
              }}
            >
              <AllInclusiveIcon sx={{ width: { md: "auto", xs: "18px" } }} />
            </InputAdornment>
          }
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={!numberOfGames ? null : numberOfGames}
          onChange={(e) => updateField("numberOfGames", Number(e.target.value))}
        />
      </Stack>

      <Stack alignItems="flex-start">
        <Typography color="text.primary">On Win</Typography>
        <Stack direction="row" gap={0.5}>
          <GameTypeButtonReset
            status={winStatus}
            setStatus={handleWinStatus}
            isAutoPlaying={isAutoPlaying}
          />
          <InputFieldStyled
            inputProps={{
              min: 0,
            }}
            disabled={winStatus === SELECTIONSTATUS.RESET || isAutoPlaying}
            endAdornment={
              <InputAdornment position="start" sx={{ color: "white" }}>
                <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
              </InputAdornment>
            }
            type="number"
            value={autoPlayStates.onWin || ""}
            onChange={(e) => updateField("onWin", Number(e.target.value))}
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">On Loss</Typography>
        <Stack direction="row" gap={0.5}>
          <GameTypeButtonReset
            status={loseStatus}
            setStatus={handleLossStatus}
            isAutoPlaying={isAutoPlaying}
          />
          <InputFieldStyled
            inputProps={{
              min: 0,
            }}
            disabled={loseStatus === SELECTIONSTATUS.RESET || isAutoPlaying}
            endAdornment={
              <InputAdornment position="start">
                <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
              </InputAdornment>
            }
            value={autoPlayStates.onLoss || ""}
            onChange={(e) => updateField("onLoss", Number(e.target.value))}
            type="number"
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Stop on Net Gain</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={
            !autoPlayStates.stopNetGain ? null : autoPlayStates.stopNetGain
          }
          onChange={(e) => updateField("stopNetGain", Number(e.target.value))}
        />
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Stop on Net Loss</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={
            !autoPlayStates.stopNetLoss ? null : autoPlayStates.stopNetLoss
          }
          onChange={(e) => updateField("stopNetLoss", Number(e.target.value))}
        />
      </Stack>
    </Stack>
  );
}

export const BetButton = (props: IBetButton) => {
  const {
    gameStatus,
    revealedCells,
    handlePayout,
    payout,
    disabled,
    isFunCash,
  } = props;

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => Number(value.toFixed(4)));

  useEffect(() => {
    const animation = animate(count, payout, {
      duration: 2,
      ease: "easeInOut",
    });

    return animation.stop;
    // eslint-disable-next-line
  }, [payout]);

  const isRevealed = revealedCells.length > 0;
  return (
    <Button
      variantType="success"
      fullWidth
      disabled={
        (gameStatus === GAMESTATUS.INGAME && !revealedCells.length) || disabled
      }
      onClick={() => handlePayout()}
    >
      {isRevealed ? `Payout` : "Payout"}
      {isRevealed && <TokenIcon freeCash={isFunCash} />}
      {isRevealed && <motion.div>{rounded}</motion.div>}
    </Button>
  );
};
