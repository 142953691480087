import { Container } from "@mui/material";
import Slots from "../../../components/slots";
import { useState } from "react";
import { GAMES } from "../../../constants/games";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import GameFeed from "../../../components/gameFeed";

const SlotsPage = () => {
  const [isTheater, setIsTheater] = useState<any>(false);
  const pathname = window.location.pathname;
  const slotName = pathname.split("/").pop();
  return (
    <Container maxWidth={isTheater ? "xl" : "md"} sx={{ p: isTheater ? 0 : 2 }}>
      <Slots setIsTheater={setIsTheater} isTheater={isTheater} />
      {slotName && <DescWrapper game={GAMES.SLOTS} secondaryName={slotName} />}
      <GameFeed />
    </Container>
  );
};

export default SlotsPage;
