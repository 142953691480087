import { Typography, Box, Stack } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { EXCHANGETYPE, ORDERSTATUS } from "../../types/index.d";
import { Button } from "../button";
import { openChatTo } from "../slices/chatSlice";
import { updateStatus } from "../slices/orderSlice";
import { updateChatDrawer } from "../slices/userSlice";
import { AppDispatch } from "../store";
import { theme } from "../theme";
import { useState } from "react";
import { ConfirmationDialog } from "../confirmationModal";
import { getOrderData } from "../../constants/exchange";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: "80%", md: 400 },
  zIndex: 10,
  py: 4,
  px: { sm: 10, xs: 3 },
  borderRadius: 4,
};

interface IOrderDetails {
  order: any;
  handleClose: () => void;
}

export const OrderDetails = (props: IOrderDetails) => {
  const { order, handleClose } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState(false);

  const handleCancelOrder = () => {
    if (order) {
      setOpenModal(false);

      dispatch(
        updateStatus({ id: order._id, newStatus: ORDERSTATUS.CANCELLED })
      );
    }
  };

  const handleOpenChat = () => {
    if (order) {
      dispatch(openChatTo(1));
      dispatch(updateChatDrawer(true));
      handleClose();
    }
  };

  const showCancel =
    order &&
    (order.status === ORDERSTATUS.INPROGRESS ||
      order.status === ORDERSTATUS.PENDING) &&
    (order.type === EXCHANGETYPE.DEPOSITRS3 ||
      order.type === EXCHANGETYPE.DEPOSITOSRS);

  const showOpenChat =
    order &&
    (order.status === ORDERSTATUS.INPROGRESS ||
      order.status === ORDERSTATUS.PENDING);

  return (
    <>
      <ConfirmationDialog
        handleConfirm={handleCancelOrder}
        open={openModal}
        handleCancel={() => setOpenModal(false)}
        text="Are you sure you want to cancel?"
        title={`${getOrderData(order?.type || "")?.label || ""} - ${
          order?.amount || ""
        }m - ${order?.tokens || ""} Tokens - ${order?.inGameUserName || ""}`}
      />
      <ModalComponent
        open={Boolean(order)}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <Box sx={style}>
          <Stack alignItems="center" gap={3}>
            <Typography variant="h5" color="text.primary">
              Order Details
            </Typography>
            {order && (
              <Stack gap={1} sx={{ width: "100%" }}>
                {order?.user?.userName && (
                  <OrderDetail label="Username" value={order?.user?.userName} />
                )}
                <OrderDetail label="Order ID" value={order._id} />
                <OrderDetail
                  label="Type"
                  value={
                    order.exchangeType === "DEPOSIT" ? "Purchase" : "Redeem"
                  }
                />
                {order.type !== EXCHANGETYPE.DEPOSITCRYPTO &&
                  order.type !== EXCHANGETYPE.WITHDRAWCRYPTO && (
                    <OrderDetail
                      label="Currency"
                      value={order.currency?.replace("($/m)", "")}
                    />
                  )}

                {(order.type === EXCHANGETYPE.DEPOSITCRYPTO ||
                  order.type === EXCHANGETYPE.WITHDRAWCRYPTO) && (
                  <OrderDetail label="Crypto" value={order.cryptoType} />
                )}

                {order.type === EXCHANGETYPE.DEPOSITCRYPTO && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      fontWeight={500}
                      variant="h6"
                      color="text.primary"
                    >
                      Address:
                    </Typography>
                    <Typography
                      fontWeight={500}
                      variant="h6"
                      fontSize={14}
                      color="text.primary"
                    >
                      {order?.pay_address}
                    </Typography>
                  </Stack>
                )}

                <OrderDetail
                  label="Amount"
                  value={
                    order.type !== EXCHANGETYPE.DEPOSITCRYPTO &&
                    order.type !== EXCHANGETYPE.WITHDRAWCRYPTO
                      ? `${order.amount}m`
                      : `${order.amount}$`
                  }
                />

                <OrderDetail label="Sweep Cash" value={order.tokens} />
                {order.freeToken ? (
                  <OrderDetail label="Fun Cash" value={order.freeToken} />
                ) : null}

                <OrderDetail label="Status" value={order.status} />
                {order?.cashierUserName && (
                  <OrderDetail
                    label="Updated By"
                    value={order?.cashierUserName}
                  />
                )}
                <OrderDetail
                  label="Created At"
                  value={handleDate(order?.createdAt)}
                />

                <OrderDetail
                  label="Updated At"
                  value={handleDate(order?.updatedAt)}
                />
              </Stack>
            )}
            <Stack gap={1} direction="row" sx={{ width: "100%" }}>
              {showOpenChat && (
                <Button
                  fullWidth
                  variantType="success"
                  onClick={() => handleOpenChat()}
                >
                  Open Chat
                </Button>
              )}
              {showCancel && (
                <Button
                  fullWidth
                  variantType="error"
                  onClick={() => setOpenModal(true)}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </ModalComponent>
    </>
  );
};

const handleDate = (value: string) => {
  const checkDate = new Date(value);
  if (checkDate instanceof Date && !isNaN(checkDate.getTime())) {
    return format(checkDate, "HH:mm, M/d/yy");
  }
  return value;
};

const OrderDetail = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography fontWeight={500} variant="h6" color="text.primary">
        {label}:
      </Typography>
      <Typography fontWeight={500} variant="h6" color="text.primary">
        {value}
      </Typography>
    </Stack>
  );
};
