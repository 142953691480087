import { kinguinData } from "../../constants/kinguin";

import { Grid } from "@mui/material";

export const Kinguin = () => {
  return (
    <div style={{ overflow: "auto" }}>
      <Grid container spacing={1} sx={{ p: { xs: 0, md: 1 } }}>
        {kinguinData.map((item, index) => (
          <Grid item md={4} xs={6} key={index} sx={{ p: { xs: 0, md: 2 } }}>
            <img
              src={item.image}
              alt={`kinguin-${index}`}
              onClick={() => window.open(item.url, "_blank")}
              style={{ cursor: "pointer", width: "100%" }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
