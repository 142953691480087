export const customEmojisData = [
   {
    names: ["rw"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/rw.png",
    id: "{{{custom-emoji-rw}}}",
  },
  {
    names: ["pepechickenbounce"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepechickenbounce.gif",
    id: "{{{custom-emoji-custom-pepechickenbounce}}}",
  },
  {
    names: ["pepepartyhat"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepepartyhat.png",
    id: "{{{custom-emoji-custom-pepepartyhat}}}",
  },
  {
    names: ["pepemine"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepemine.png",
    id: "{{{custom-emoji-custom-pepemine}}}",
  },
  {
    names: ["pepebin"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepebin.png",
    id: "{{{custom-emoji-custom-pepebin}}}",
  },
  {
    names: ["pepelove"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepelove.png",
    id: "{{{custom-emoji-custom-pepelove}}}",
  },
  {
    names: ["pepehmm"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepehmm.png",
    id: "{{{custom-emoji-custom-pepehmm}}}",
  },
  {
    names: ["pepebribe"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepebribe.png",
    id: "{{{custom-emoji-custom-pepebribe}}}",
  },
 /* {
    names: ["HalloweenCats"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/HalloweenCats.gif",
    id: "{{{custom-emoji-halloweencats}}}",
  },
  {
    names: ["HalloweenPepe"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/HalloweenPepe.png",
    id: "{{{custom-emoji-custom-halloweenpepe}}}",
  },
  {
    names: ["HalloweenPumpkin"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/HalloweenPumpkin.png",
    id: "{{{custom-emoji-custom-halloweenpumpkin}}}",
  },
  {
    names: ["WitchyGhost"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/WitchyGhost.png",
    id: "{{{custom-emoji-custom-witchyghost}}}",
  },*/
  {
    names: ["alert"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/Alert.gif",
    id: "{{{custom-emoji-alert}}}",
  },
  {
    names: ["peepoBlankie"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/peepoBlankie.gif",
    id: "{{{custom-emoji-custom-peepoblankie}}}",
  },
  {
    names: ["peepoPet"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/peepoPet.gif",
    id: "{{{custom-emoji-custom-peepopet}}}",
  },
  {
    names: ["peepoUwu"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/peepoUwu.gif",
    id: "{{{custom-emoji-custom-peepouwu}}}",
  },
  {
    names: ["peepoBongo"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeBongo.gif",
    id: "{{{custom-emoji-custom-peepobongo}}}",
  },
  {
    names: ["peepoBusinessOkay"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeBusinessOkay.png",
    id: "{{{custom-emoji-custom-peepobusinessokay}}}",
  },
  {
    names: ["PepeCheer"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeCheer.gif",
    id: "{{{custom-emoji-custom-pepecheer}}}",
  },
  {
    names: ["pepeChunky"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeChunky.gif",
    id: "{{{custom-emoji-custom-pepechunky}}}",
  },
  {
    names: ["PepeCool"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeCool.png",
    id: "{{{custom-emoji-custom-pepecool}}}",
  },
  {
    names: ["pepeDj"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeDj.gif",
    id: "{{{custom-emoji-custom-pepedj}}}",
  },
  {
    names: ["pepeFeet"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeFeet.gif",
    id: "{{{custom-emoji-custom-pepefeet}}}",
  },
  {
    names: ["PepeHappy"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeHappy.png",
    id: "{{{custom-emoji-custom-pepehappy}}}",
  },
  {
    names: ["pepeHi"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeHi.png",
    id: "{{{custom-emoji-custom-pepehi}}}",
  },
  {
    names: ["pepeKitty"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeKitty.gif",
    id: "{{{custom-emoji-custom-pepekitty}}}",
  },
  {
    names: ["pepeLightsticks"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeLightsticks.gif",
    id: "{{{custom-emoji-custom-pepelightsticks}}}",
  },
  {
    names: ["PepeNerdCheck"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeNerdCheck.gif",
    id: "{{{custom-emoji-custom-pepenerdcheck}}}",
  },
  {
    names: ["pepeNote"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeNote.gif",
    id: "{{{custom-emoji-custom-pepenote}}}",
  },
  {
    names: ["pepePizza"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepePizza.gif",
    id: "{{{custom-emoji-custom-pepepizza}}}",
  },
  {
    names: ["pepeRace"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeRace.gif",
    id: "{{{custom-emoji-custom-peperace}}}",
  },
  {
    names: ["PepeSad"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeSad.png",
    id: "{{{custom-emoji-custom-pepesad}}}",
  },
  {
    names: ["PepeSadge"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeSadge.gif",
    id: "{{{custom-emoji-custom-pepesadge}}}",
  },
  {
    names: ["pepeSnoge"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeSnoge.png",
    id: "{{{custom-emoji-custom-pepesnoge}}}",
  },
  {
    names: ["pepeSwing"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeSwing.gif",
    id: "{{{custom-emoji-custom-pepeswing}}}",
  },
  {
    names: ["pepeTea"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeTea.png",
    id: "{{{custom-emoji-custom-pepetea}}}",
  },
  {
    names: ["pepeWheelchair"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeWheelchair.png",
    id: "{{{custom-emoji-custom-pepewheelchair}}}",
  },
  {
    names: ["pepeYes"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeYes.png",
    id: "{{{custom-emoji-custom-pepeyes}}}",
  },
  {
    names: ["Prayge"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/Prayge.png",
    id: "{{{custom-emoji-custom-prayge}}}",
  },
  {
    names: ["sadge"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/sadge.png",
    id: "{{{custom-emoji-custom-sadge}}}",
  },
  {
    names: ["kekw"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/KEKW.png",
    id: "{{{custom-emoji-custom-kekw}}}",
  },
 {
    names: ["PepeHands"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/PepeHands.png",
    id: "{{{custom-emoji-custom-pepehands}}}",
  },
 {
    names: ["ezPepe"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/ezPepe.png",
    id: "{{{custom-emoji-custom-ezpepe}}}",
  }, 
  {
    names: ["kekleo"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/kekleo.png",
    id: "{{{custom-emoji-custom-kekleo}}}",
  },
  {
    names: ["catKiss"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/catKiss.gif",
    id: "{{{custom-emoji-custom-catkiss}}}",
  },
  {
    names: ["catCryThumbsUp"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/catCryThumbsUp.png",
    id: "{{{custom-emoji-custom-catcrythumbsup}}}",
  },
  {
    names: ["catJAM"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/catJAM.gif",
    id: "{{{custom-emoji-custom-catjam}}}",
  },
  {
    names: ["pepeMoney"],
    imgUrl:
      "https://runewager-static-assets.s3.us-east-2.amazonaws.com/emojis/pepeMoney.gif",
    id: "{{{custom-emoji-custom-pepemoney}}}",
  },
];
