import { ISlotGame } from "../components/slices/slotsSlice";
import SnoopDoggDollars from "../assets/SnoopDoggDollars.webp";

export const DEFAULT_BG_IMAGE_S2 = "https://cdn.softswiss.net/i/s2/softswiss/";
export const DEFAULT_BG_IMAGE_S3 = "https://cdn.softswiss.net/i/s3/softswiss/";
export const DEFAULT_BG_IMAGE_S6 = "https://cdn.softswiss.net/i/s6/softswiss/";

export const getImageEndpoint = (game: ISlotGame | undefined, size: string) => {
  if (!game) return "";
  let identifier = game.identifier;
  if (game.newImgIdentifier) {
    identifier = game.newImgIdentifier;
  }

  switch (size) {
    case "s2":
      return `${DEFAULT_BG_IMAGE_S2}${identifier}.webp`;
      break;
    case "s3":
      return `${DEFAULT_BG_IMAGE_S3}${identifier}.webp`;
      break;
    case "s6":
      if (identifier === "SnoopDoggDollars") {
        return SnoopDoggDollars;
      }
      return `${DEFAULT_BG_IMAGE_S6}${identifier}.webp`;
      break;
  }
};
const key = "recentlyPlayedSlotsGames";

export const addSlotsToRecentlyPlayed = (game: string) => {
  let games = JSON.parse(localStorage.getItem(key) || "[]");
  games = games.filter((g: string) => g !== game);
  games.unshift(game);
  localStorage.setItem(key, JSON.stringify(games));
};

export const getSlotsToRecentlyPlayed = () => {
  return JSON.parse(localStorage.getItem(key) || "[]");
};

export const bgamingSlotsReleaseDates = [
  { identifier: "SnoopDoggDollars", ReleaseDate: "10/30/2024" },
  { identifier: "Forgotten", ReleaseDate: "10/29/2024" },
  { identifier: "HauntedReels", ReleaseDate: "10/15/2024" },
  { identifier: "CarnivalBonanza", ReleaseDate: "10/8/2024" },
  { identifier: "AztecsClawWildDice", ReleaseDate: "9/26/2024" },
  { identifier: "FortunaTrueways", ReleaseDate: "9/23/2024" },
  { identifier: "VoodooPeople", ReleaseDate: "9/18/2024" },
  { identifier: "TopEagle", ReleaseDate: "9/13/2024" },
  { identifier: "RoyalFruitsMultiLines", ReleaseDate: "9/11/2024" },
  { identifier: "Robospin", ReleaseDate: "9/3/2024" },
  { identifier: "TrainToRioGrande", ReleaseDate: "8/28/2024" },
  { identifier: "KrakensHunger", ReleaseDate: "8/22/2024" },
  { identifier: "MineGems", ReleaseDate: "8/19/2024" },
  { identifier: "CatchTheGoldHoldAndWin", ReleaseDate: "8/13/2024" },
  { identifier: "StreetPower", ReleaseDate: "8/6/2024" },
  { identifier: "WildWestTrueways", ReleaseDate: "7/30/2024" },
  { identifier: "SecretBarMultiDiceX", ReleaseDate: "7/23/2024" },
  { identifier: "GoldMagnate", ReleaseDate: "7/16/2024" },
  { identifier: "GrandPatron7rst", ReleaseDate: "7/8/2024" },
  { identifier: "Aviamasters", ReleaseDate: "7/2/2024" },
  { identifier: "AlienFruits2", ReleaseDate: "6/25/2024" },
  { identifier: "DragonAge", ReleaseDate: "6/18/2024" },
  { identifier: "FishingClub", ReleaseDate: "6/11/2024" },
  { identifier: "GoldOfMinos", ReleaseDate: "6/6/2024" },
  { identifier: "PandaLuck", ReleaseDate: "5/21/2024" },
  { identifier: "ChickenRush", ReleaseDate: "5/15/2024" },
  { identifier: "LuckAndMagicScratch", ReleaseDate: "5/8/2024" },
  { identifier: "Oof", ReleaseDate: "5/2/2024" },
  { identifier: "LuckyDragonMultiDiceX", ReleaseDate: "4/25/2024" },
  { identifier: "ThreeKingsScratch", ReleaseDate: "3/28/2024" },
  { identifier: "DiamondOfJungle", ReleaseDate: "3/21/2024" },
  { identifier: "DragonsCrash", ReleaseDate: "3/7/2024" },
  { identifier: "Lucky8MergeUp", ReleaseDate: "2/15/2024" },
  { identifier: "PopZen", ReleaseDate: "2/8/2024" },
  { identifier: "AztecClusters", ReleaseDate: "1/31/2024" },
  { identifier: "WildHeart", ReleaseDate: "1/25/2024" },
  { identifier: "GodOfWealthHoldAndWin", ReleaseDate: "1/18/2024" },
  { identifier: "KeepersOfTheSecret7rst", ReleaseDate: "1/11/2024" },
  { identifier: "WildTiger", ReleaseDate: "1/4/2024" },
  { identifier: "CapymaniaGreen", ReleaseDate: "1/2/2024" },
  { identifier: "CapymaniaOrange", ReleaseDate: "1/2/2024" },
  { identifier: "CapymaniaYellow", ReleaseDate: "1/2/2024" },
  { identifier: "Hottest666", ReleaseDate: "12/20/2023" },
  { identifier: "Gemza", ReleaseDate: "12/15/2023" },
  { identifier: "IceScratchBronze", ReleaseDate: "12/14/2023" },
  { identifier: "IceScratchGold", ReleaseDate: "12/14/2023" },
  { identifier: "IceScratchSilver", ReleaseDate: "12/14/2023" },
  { identifier: "SlotMachine", ReleaseDate: "12/7/2023" },
  { identifier: "GiftX", ReleaseDate: "12/1/2023" },
  { identifier: "TrampDay", ReleaseDate: "11/16/2023" },
  { identifier: "BookOfPanda", ReleaseDate: "11/9/2023" },
  { identifier: "MummysGold", ReleaseDate: "11/2/2023" },
  { identifier: "MergeUp", ReleaseDate: "10/26/2023" },
  { identifier: "ScratchAlpacaBronze", ReleaseDate: "10/19/2023" },
  { identifier: "ScratchAlpacaGold", ReleaseDate: "10/19/2023" },
  { identifier: "ScratchAlpacaSilver", ReleaseDate: "10/19/2023" },
  { identifier: "MonsterHunt", ReleaseDate: "10/12/2023" },
  { identifier: "MiceAndMagic", ReleaseDate: "9/21/2023" },
  { identifier: "BoneBonanza", ReleaseDate: "9/14/2023" },
  { identifier: "Maneki88Fortunes", ReleaseDate: "9/7/2023" },
  { identifier: "WildCashDice", ReleaseDate: "9/1/2023" },
  { identifier: "SavageBuffaloSpiritMegaways", ReleaseDate: "8/24/2023" },
  { identifier: "DiceMillion", ReleaseDate: "8/18/2023" },
  { identifier: "LuckAndMagic", ReleaseDate: "8/3/2023" },
  { identifier: "DiceBonanza", ReleaseDate: "7/27/2023" },
  { identifier: "BeastBand", ReleaseDate: "7/20/2023" },
  { identifier: "WildChicago", ReleaseDate: "6/29/2023" },
  { identifier: "Gemhalla", ReleaseDate: "6/15/2023" },
  { identifier: "Limbo", ReleaseDate: "6/8/2023" },
  { identifier: "SavageBuffaloSpirit", ReleaseDate: "5/25/2023" },
  { identifier: "LuckyCrew", ReleaseDate: "5/18/2023" },
  { identifier: "RoyalHighRoad", ReleaseDate: "5/11/2023" },
  { identifier: "AlienFruits", ReleaseDate: "4/13/2023" },
  { identifier: "EasterHeist", ReleaseDate: "3/23/2023" },
  { identifier: "EasterPlinko", ReleaseDate: "3/16/2023" },
  { identifier: "BookOfKemet", ReleaseDate: "3/9/2023" },
  { identifier: "LuckyOak", ReleaseDate: "2/23/2023" },
  { identifier: "SweetRushMegaways", ReleaseDate: "2/16/2023" },
  { identifier: "PotionSpells", ReleaseDate: "1/27/2023" },
  { identifier: "DomnitorsTreasure", ReleaseDate: "1/12/2023" },
  { identifier: "Gangsterz", ReleaseDate: "11/24/2022" },
  { identifier: "BurningChilliX", ReleaseDate: "11/17/2022" },
  { identifier: "Soccermania", ReleaseDate: "11/15/2022" },
  { identifier: "BookOfCatsMegaways", ReleaseDate: "11/3/2022" },
  { identifier: "ElvisFrogTrueways", ReleaseDate: "10/20/2022" },
  { identifier: "HalloweenBonanza", ReleaseDate: "10/7/2022" },
  { identifier: "BeerBonanza", ReleaseDate: "9/8/2022" },
  { identifier: "FortyFruityMillion", ReleaseDate: "9/2/2022" },
  { identifier: "WildCashX9990", ReleaseDate: "8/31/2022" },
  { identifier: "Maneki88Gold", ReleaseDate: "8/11/2022" },
  { identifier: "MissCherryFruitsJackpotparty", ReleaseDate: "7/28/2022" },
  { identifier: "LuckyLadyMoonMegaways", ReleaseDate: "7/7/2022" },
  { identifier: "GoldRushWithJohnny", ReleaseDate: "6/16/2022" },
  { identifier: "BigAtlantisFrenzy", ReleaseDate: "6/9/2022" },
  { identifier: "PennyPelican", ReleaseDate: "5/18/2022" },
  { identifier: "WildCash", ReleaseDate: "4/14/2022" },
  { identifier: "LuckyFarmBonanza", ReleaseDate: "4/1/2022" },
  { identifier: "JokerQueen", ReleaseDate: "3/28/2022" },
  { identifier: "HeadsTailsXY", ReleaseDate: "3/24/2022" },
  { identifier: "MinesweeperXY", ReleaseDate: "3/24/2022" },
  { identifier: "PlinkoXY", ReleaseDate: "3/24/2022" },
  { identifier: "RocketDiceXY", ReleaseDate: "3/24/2022" },
  { identifier: "CloverBonanza", ReleaseDate: "3/3/2022" },
  { identifier: "AztecMagicBonanza", ReleaseDate: "2/17/2022" },
  { identifier: "AztecMagicMegaways", ReleaseDate: "1/27/2022" },
  { identifier: "SpaceXY", ReleaseDate: "1/13/2022" },
  { identifier: "GiftRush", ReleaseDate: "1/12/2022" },
  { identifier: "BonanzaBillion", ReleaseDate: "12/2/2021" },
  { identifier: "Road2Riches", ReleaseDate: "11/11/2021" },
  { identifier: "LuckyDamaMuerta", ReleaseDate: "10/18/2021" },
  { identifier: "CandyMonsta", ReleaseDate: "10/12/2021" },
  { identifier: "MissCherryFruits", ReleaseDate: "9/30/2021" },
  { identifier: "DragonsGold100", ReleaseDate: "9/9/2021" },
  { identifier: "AlohaKingElvis", ReleaseDate: "7/22/2021" },
  { identifier: "DigDigDigger", ReleaseDate: "6/17/2021" },
  { identifier: "WbcRingOfRiches", ReleaseDate: "5/27/2021" },
  { identifier: "MechanicalClover", ReleaseDate: "5/4/2021" },
  { identifier: "FourLuckyDiamonds", ReleaseDate: "2/25/2021" },
  { identifier: "LuckyLadyMoon", ReleaseDate: "12/21/2020" },
  { identifier: "FruitMillion", ReleaseDate: "12/16/2020" },
  { identifier: "HitTheRoute", ReleaseDate: "12/1/2020" },
  { identifier: "JohnnyCash", ReleaseDate: "10/30/2020" },
  {
    identifier: "AllLuckyClover",
    ReleaseDate: "9/17/2020",
  },
  {
    identifier: "AllLuckyClover5",
    ReleaseDate: "9/17/2020",
  },
  {
    identifier: "AllLuckyClover20",
    ReleaseDate: "9/17/2020",
  },
  {
    identifier: "AllLuckyClover40",
    ReleaseDate: "9/17/2020",
  },
  {
    identifier: "AllLuckyClover100",
    ReleaseDate: "9/17/2020",
  },

  { identifier: "BookOfCats", ReleaseDate: "7/8/2020" },
  { identifier: "ElvisFrog", ReleaseDate: "5/13/2020" },
  { identifier: "FourLuckyClover", ReleaseDate: "3/19/2020" },
  { identifier: "Avalon", ReleaseDate: "2/27/2020" },
  { identifier: "DeepSea", ReleaseDate: "12/20/2019" },
  { identifier: "SpinAndSpell", ReleaseDate: "10/17/2019" },
  { identifier: "JourneyFlirt", ReleaseDate: "10/4/2019" },
  { identifier: "MechanicalOrange", ReleaseDate: "5/16/2019" },
  { identifier: "JogoDoBicho", ReleaseDate: "4/30/2019" },
  { identifier: "JogoDoBichoSimple", ReleaseDate: "4/30/2019" },
  { identifier: "HelloEaster", ReleaseDate: "4/18/2019" },
  { identifier: "Plinko", ReleaseDate: "1/28/2019" },
  { identifier: "DomnitorsDeluxe", ReleaseDate: "12/27/2018" },
  { identifier: "BobsCoffeeShop", ReleaseDate: "11/16/2018" },
  { identifier: "RocketDice", ReleaseDate: "10/22/2018" },
  { identifier: "FireLightning", ReleaseDate: "7/24/2018" },
  { identifier: "AztecMagicDeluxe", ReleaseDate: "2/16/2018" },
  { identifier: "PlatinumLightningDeluxe", ReleaseDate: "2/16/2018" },
  { identifier: "BookOfPyramids", ReleaseDate: "4/2/2016" },
  { identifier: "BraveViking", ReleaseDate: "4/2/2016" },
  { identifier: "CherryFiesta", ReleaseDate: "4/2/2016" },
  { identifier: "CrazyStarter", ReleaseDate: "4/2/2016" },
  { identifier: "Domnitors", ReleaseDate: "4/2/2016" },
  { identifier: "FantasyPark", ReleaseDate: "4/2/2016" },
  { identifier: "FrenchRoulette", ReleaseDate: "4/2/2016" },
  { identifier: "LuckyLadyClover", ReleaseDate: "4/2/2016" },
  { identifier: "MultihandBlackjack", ReleaseDate: "4/2/2016" },
  { identifier: "PlatinumLightning", ReleaseDate: "4/2/2016" },
  { identifier: "PrincessRoyal", ReleaseDate: "4/2/2016" },
  { identifier: "WestTown", ReleaseDate: "4/2/2016" },
  { identifier: "AmericanRoulette", ReleaseDate: "4/2/2016" },
  { identifier: "DesertTreasure", ReleaseDate: "4/2/2016" },
  { identifier: "EuropeanRoulette", ReleaseDate: "4/2/2016" },
  { identifier: "HawaiiCocktails", ReleaseDate: "4/2/2016" },
  { identifier: "HeadsTails", ReleaseDate: "4/2/2016" },
  { identifier: "LuckyBlue", ReleaseDate: "4/2/2016" },
  { identifier: "Minesweeper", ReleaseDate: "4/2/2016" },
  { identifier: "BlackjackPro", ReleaseDate: "4/2/2016" },
  { identifier: "PrincessOfSky", ReleaseDate: "4/2/2016" },
  { identifier: "ScratchDice", ReleaseDate: "4/2/2016" },
  { identifier: "ScrollOfAdventure", ReleaseDate: "2/4/2016" },
  { identifier: "SlotomonGo", ReleaseDate: "2/4/2016" },
  { identifier: "LuckySweets", ReleaseDate: "2/4/2016" },
  { identifier: "AztecMagic", ReleaseDate: "7/12/2015" },
];
