import { ReactNode, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Socket, io } from "socket.io-client";
import {
  AdminChat,
  IRain,
  addAdminMessage,
  addMessage,
  delMessage,
  newOrder,
  selectAllChat,
  updateAllAdminMessages,
  updateAllMessages,
  updateOrderById,
  updateRain,
  updateReadAtAdmin,
} from "../../components/slices/chatSlice";
import {
  updateCoinFlipErrorMessage,
  updateCoinFlipGame,
  updateCoinFlipPastGame,
} from "../../components/slices/coinFlipSlice";
import {
  updateErrorMessage,
  updateGame,
  updatePastGame,
} from "../../components/slices/diceSlice";
import {
  CombineGameData,
  updateAllDataGameFeed,
  updateAllGameFeed,
  updateAllGames,
  updateAllHighRollerFeed,
  updateCombineGameFeed,
  updateMyBets,
} from "../../components/slices/gameFeeds";
import {
  updateKenoErrorMessage,
  updateKenoGame,
} from "../../components/slices/kenoSlice";
import {
  updateLimboErrorMessage,
  updateLimboGame,
  updateLimboPastGame,
} from "../../components/slices/limboSlice";
import { showNotification } from "../../components/slices/notificationSlice";
import {
  addOrderMessage,
  getAllOrder,
  handleOrder,
  handleOrderUpdateState,
  Order,
} from "../../components/slices/orderSlice";
import {
  updatePlinkoErrorMessage,
  updatePlinkoGame,
  updatePlinkoPastGame,
} from "../../components/slices/plinkoSlice";
import {
  addBalance,
  Constants,
  selectId,
  selectToken,
  updateBalance,
  updateConstants,
  updateFreeCash,
  updateLevelUpModal,
  updatetokensEarned,
  updateUser,
  User,
} from "../../components/slices/userSlice";
import { AppDispatch } from "../../components/store";
import { useAppSelector } from "../../components/store/hooks";
import configuration from "../../config";
import {
  ADMIN_READ_AT_UPDATE_TO_ADMIN,
  ALL_USER_COUNT,
  EMIT_COIN_FLIP_GAME,
  EMIT_DICE_GAME,
  EMIT_KENO_GAME,
  EMIT_LIMBO_GAME,
  EMIT_PLINKO_GAME,
  MESSAGE_ALL_CHAT,
  NEW_MESSAGE_TO_ADMIN,
  ORDER_CREATED_TO_ADMIN,
  ORDER_UPDATE_BY_ADMIN,
  UPDATE_BALANCE,
  UPDATE_CONSTANTS,
  UPDATE_ALL_GAME_FEED,
  UPDATE_LEVEL,
  UPDATE_ORDER_STATUS_TO_ADMIN,
  UPDATE_USER,
  USER_MESSAGE_ON_ADMIN_CHAT,
  UPDATE_HIGH_ROLLER_FEED,
  UPDATE_MY_BETS_FEED,
  EMIT_MINES_GAME,
  EMIT_MINES_PAYOUT_GAME,
  EMIT_CHECK_MINES_GAME,
  EMIT_DELETED_MESSAGE,
  RECONNECT_GET_UPDATES,
  RECONNECT_EMIT_UPDATES,
  EMIT_BLACKJACK_GAME,
  EMIT_CHECK_BLACKJACK_GAME,
  CHAT_RAIN_UPDATE,
  EMIT_HILO_GAME,
  EMIT_CHECK_HILO_GAME,
  UPDATE_ALL_GAME_HIGH_ROLLER_FEED,
  NOTIFICATION_EMIT,
  CHALLENGES_UPDATE,
} from "../../constants/socket";
import {
  EXCHANGETYPE,
  IGameFeed,
  IMessage,
  ORDERSTATUS,
  TOKENTYPE,
} from "../../types/index.d";
import { ChatContext } from "./context";
import useSound from "use-sound";
import newOrderSFX from "../../assets/sfx/exchangeRequest.mp3";
import {
  handleCheckCell,
  handleMinesPayout,
  handleNewGame,
  setErrorMesage,
} from "../../components/slices/mineSlice";
import {
  DuelDocument,
  updateAllDuelResults,
} from "../../components/slices/duelSlice";
import {
  updateBlackjackErrorMessage,
  updateBlackjackGame,
} from "../../components/slices/blackJackslice";
import {
  updateHiloErrorMessage,
  updateHiloGame,
} from "../../components/slices/hiloslice";
import { updateChallenges } from "../../components/slices/challengesSlice";

interface ChatProviderProps {
  children: ReactNode;
}

export function ChatProvider({ children }: ChatProviderProps) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [chatUserCount, setChatUserCount] = useState(0);
  const [adminUserCount, setAdminUserCount] = useState(0);
  const accessToken = useAppSelector(selectToken);
  const userId = useAppSelector(selectId);
  const dispatch = useDispatch<AppDispatch>();
  const [play] = useSound(newOrderSFX);
  const diceBalanceRef = useRef<NodeJS.Timeout | null>(null);
  const coinFlipBalanceRef = useRef<NodeJS.Timeout | null>(null);
  const connectionRef = useRef(false);
  const allChat = useAppSelector(selectAllChat);
  const timeStampRef = useRef<Date>();

  const handleReceieveMessage = (message: any) => {
    dispatch(addMessage(message));
  };

  const handleUpdateConstants = (data: Constants) => {
    if (data) {
      dispatch(updateConstants(data));
    }
  };

  const handleUserCount = (count: {
    users: number;
    admins: number;
    constantValues: Constants;
    allGamesFeed: IGameFeed[];
    allHighRollerFeed: IGameFeed[];
    highRollerAllDataFeed: IGameFeed[];
    combinedGamesFeed: CombineGameData;
    rain: IRain;
  }) => {
    if (count.highRollerAllDataFeed) {
      dispatch(updateAllDataGameFeed(count.highRollerAllDataFeed));
    }
    if (count.constantValues) {
      dispatch(updateConstants(count.constantValues));
    }
    if (count.combinedGamesFeed) {
      dispatch(updateCombineGameFeed(count.combinedGamesFeed));
    }
    if (count.allGamesFeed) {
      dispatch(updateAllGames(count.allGamesFeed));
    }
    if (count.allHighRollerFeed) {
      dispatch(updateAllHighRollerFeed(count.allHighRollerFeed));
    }
    if (count?.rain) {
      dispatch(updateRain(count.rain));
    }
    setChatUserCount(count.users);
    setAdminUserCount(count.admins);
  };

  // Admin

  const handleOrderUpdate = (data: { order: Order; balance: number }) => {
    dispatch(handleOrderUpdateState(data.order));
    if (data.balance !== undefined) {
      dispatch(updateBalance(data.balance));
    }
    if (data.order.status === ORDERSTATUS.CANCELLED) {
      dispatch(showNotification("Your order has been cancelled", "error"));
    }
    if (data.order.status === ORDERSTATUS.COMPLETED) {
      if (
        data.order.type === EXCHANGETYPE.DEPOSITCRYPTO ||
        data.order.type === EXCHANGETYPE.DEPOSITOSRS ||
        data.order.type === EXCHANGETYPE.DEPOSITRS3
      ) {
        dispatch(showNotification("Your deposit was completed"));
      } else {
        dispatch(showNotification("Your withdraw was completed"));
      }
    }
  };

  const handleUserMessageOnAdminChat = (data: any) => {
    dispatch(addOrderMessage(data.message));
  };

  const handleNewMessageAdmin = (message: any) => {
    dispatch(addAdminMessage(message));
  };

  const handleNewOrder = (data: any) => {
    play();
    dispatch(showNotification("New order has been created"));
    dispatch(newOrder(data));
    dispatch(getAllOrder());
  };

  const handleOrderStatusUpdate = (data: Order) => {
    dispatch(updateOrderById(data));
  };

  const handleAdminReadAt = (data: {
    messages: IMessage[];
    orderId: string;
  }) => {
    dispatch(updateReadAtAdmin(data));
  };

  const handleDeleteMessage = (data: { id: string }) => {
    if (data) {
      dispatch(delMessage(data.id));
    }
  };

  const handleLevelUpdate = (data: { user: User; showLevelModal: number }) => {
    if (data.user) {
      dispatch(updateUser(data.user));
    }
    if (data.showLevelModal) {
      dispatch(updateLevelUpModal(true));
      dispatch(updatetokensEarned(data.showLevelModal));
    }
  };

  const handleUserUpdate = (data: { user: User }) => {
    if (data.user) {
      dispatch(updateUser(data.user));
    }
  };

  const handleDiceGame = (data: any) => {
    if (data.error) {
      dispatch(updateErrorMessage(data.error));
      return;
    }

    if (data.dice) {
      dispatch(updateGame(data.dice));

      if (data.tokenType === TOKENTYPE.FREE) {
        if (data.intialBalance !== undefined) {
          dispatch(updateFreeCash(data.intialBalance));
        }

        diceBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateFreeCash(data.finalBalance));
          }
        }, 750);
      } else {
        if (data.intialBalance !== undefined) {
          dispatch(updateBalance(data.intialBalance));
        }

        diceBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateBalance(data.finalBalance));
          }
        }, 750);
      }

      diceBalanceRef.current = setTimeout(() => {
        dispatch(updatePastGame(data.dice));
      }, 850);
    }
    return () => {
      if (diceBalanceRef.current) {
        clearTimeout(diceBalanceRef.current);
      }
    };
  };

  const handleLimboGame = (data: any) => {
    if (data.error) {
      dispatch(updateLimboErrorMessage(data.error));
      return;
    }

    if (data.limbo) {
      dispatch(updateLimboGame(data.limbo));
      if (data.tokenType === TOKENTYPE.FREE) {
        if (data.intialBalance !== undefined) {
          dispatch(updateFreeCash(data.intialBalance));
        }

        diceBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateFreeCash(data.finalBalance));
          }
        }, 750);
      } else {
        if (data.intialBalance !== undefined) {
          dispatch(updateBalance(data.intialBalance));
        }

        diceBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateBalance(data.finalBalance));
          }
        }, 750);
      }

      coinFlipBalanceRef.current = setTimeout(() => {
        dispatch(updateLimboPastGame(data.limbo));
      }, 950);
    }
    return () => {
      if (diceBalanceRef.current) {
        clearTimeout(diceBalanceRef.current);
      }
      if (coinFlipBalanceRef.current) {
        clearTimeout(coinFlipBalanceRef.current);
      }
    };
  };

  const handleCoinFlipGame = (data: any) => {
    if (data.error) {
      dispatch(updateCoinFlipErrorMessage(data.error));
      return;
    }

    if (data.coinFlip) {
      dispatch(updateCoinFlipGame(data.coinFlip));
      if (data.tokenType === TOKENTYPE.FREE) {
        if (data.intialBalance !== undefined) {
          dispatch(updateFreeCash(data.intialBalance));
        }

        diceBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateFreeCash(data.finalBalance));
          }
          dispatch(updateCoinFlipPastGame(data.coinFlip));
        }, 3100);
      } else {
        if (data.intialBalance !== undefined) {
          dispatch(updateBalance(data.intialBalance));
        }

        coinFlipBalanceRef.current = setTimeout(() => {
          if (data.finalBalance !== undefined) {
            dispatch(updateBalance(data.finalBalance));
          }
          dispatch(updateCoinFlipPastGame(data.coinFlip));
        }, 3100);
      }
    }
    return () => {
      if (coinFlipBalanceRef.current) {
        clearTimeout(coinFlipBalanceRef.current);
      }
    };
  };

  const handleKeno = (data: any) => {
    if (data.error) {
      dispatch(updateKenoErrorMessage(data.error));
      return;
    }

    if (data.keno) {
      dispatch(updateKenoGame(data.keno));

      coinFlipBalanceRef.current = setTimeout(
        () => {
          if (data.tokenType === TOKENTYPE.FREE) {
            if (data.intialBalance !== undefined) {
              dispatch(updateFreeCash(data.intialBalance));
            }
          } else {
            if (data.intialBalance !== undefined) {
              dispatch(updateBalance(data.intialBalance));
            }
          }
        },
        data.isTurbo ? 500 : 1400
      );

      // if(data.isTurbo) {
      //   if (data.tokenType === TOKENTYPE.FREE) {
      //     if (data.intialBalance !== undefined) {
      //       dispatch(updateFreeCash(data.intialBalance));
      //     }
      //   } else {
      //     if (data.intialBalance !== undefined) {
      //       dispatch(updateBalance(data.intialBalance));
      //     }
      //   }
      // }
    }
    return () => {
      if (coinFlipBalanceRef.current) {
        clearTimeout(coinFlipBalanceRef.current);
      }
    };
  };

  const handlePlinkoGame = (data: any) => {
    if (data?.error) {
      dispatch(updatePlinkoErrorMessage(data.error.toString()));
      return;
    }
    if (data?.plinkoGame) {
      dispatch(updatePlinkoGame(data.plinkoGame));
    }
  };

  const handleMinesCreateGame = (data: any) => {
    if (data.game) {
      dispatch(handleNewGame(data));
    }
    if (data.error) {
      dispatch(setErrorMesage(data.error));
    }
  };

  const handlePayout = (data: any) => {
    if (data.mines) {
      dispatch(handleMinesPayout(data));
    }
    if (data.error) {
      dispatch(setErrorMesage(data.error));
    }
  };

  const handleMinesCheck = (data: any) => {
    if (data?.error) {
      dispatch(setErrorMesage(data.error));
    } else {
      dispatch(handleCheckCell(data));
    }
  };

  const handleUpdateBalance = (data: { toDecrement?: number }) => {
    if (data.toDecrement) {
      dispatch(addBalance(-data.toDecrement));
    }
  };

  const handleGameFeed = (data: IGameFeed) => {
    if (data) {
      dispatch(updateAllGameFeed(data));
    }
    if (data.user === userId) {
      dispatch(updateMyBets(data));
    }
  };

  const handleHighRollerFeed = (data: any) => {
    if (data) {
      dispatch(updateAllDataGameFeed(data));
    }
  };
  const handleRain = (data: { rain: IRain }) => {
    if (data?.rain) {
      dispatch(updateRain(data.rain));
    }
  };

  const handleReconnectUpdates = (data: {
    messages: IMessage[];
    adminChat?: AdminChat[];
    balance: number;
    matches: DuelDocument[];
    orderMessages: { messages: IMessage[]; order: Order };
  }) => {
    if (data.messages) {
      dispatch(updateAllMessages(data.messages));
    }
    if (data.orderMessages) {
      dispatch(handleOrder(data.orderMessages));
    }
    if (data.adminChat) {
      dispatch(updateAllAdminMessages(data.adminChat));
    }

    if (data.balance) {
      dispatch(updateBalance(data.balance));
    }

    if (data.matches) {
      dispatch(updateAllDuelResults(data.matches));
    }
  };

  const handleGetUpdates = () => {
    if (socket) {
      socket.emit(RECONNECT_GET_UPDATES, { accessToken });
    }
  };

  const handleBlackjackCreateGame = (data: any) => {
    if (data?.error) {
      dispatch(updateBlackjackErrorMessage(data.error));
      return;
    }
    dispatch(updateBlackjackGame(data.game));
  };
  const handleBlackjackCheck = (data: any) => {
    if (data?.error) {
      dispatch(updateBlackjackErrorMessage(data.error));
      return;
    }
    dispatch(updateBlackjackGame(data));
  };

  const handleHiloCreateGame = (data: any) => {
    if (data?.error) {
      dispatch(updateHiloErrorMessage(data.error));
      return;
    }
    dispatch(updateHiloGame(data.game));
  };
  const handleHiloCheck = (data: any) => {
    if (data?.error) {
      dispatch(updateHiloErrorMessage(data.error));
      return;
    }
    dispatch(updateHiloGame(data));
  };

  const handleNotification = (data: any) => {
    if (data?.message) {
      dispatch(showNotification(data?.message));
      return;
    }
  };

  const handleChallengesUpdate = (data: any) => {
    if (data?.challenges) {
      dispatch(updateChallenges(data?.challenges));
      return;
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("disconnect", () => {
        connectionRef.current = true;
        timeStampRef.current = new Date();
        console.log("Disconnected from the server");
        setIsConnected(false);
      });

      socket.on("connect", () => {
        setTimeout(() => {
          handleGetUpdates();
        }, 1000);
        // if (connectionRef.current && timeStampRef.current) {
        //   const currentTime = new Date();
        //   if (
        //     timeStampRef.current &&
        //     currentTime.getTime() - timeStampRef.current.getTime() > 4000
        //   ) {
        //     console.log(socket);
        //     socket.emit(RECONNECT_GET_UPDATES, { data: "fsdfsd" });
        //     console.log("Reconnecting after more than 4 seconds, reloading...");
        //     // window.location.reload();
        //   }
        // }
      });

      socket.on(RECONNECT_EMIT_UPDATES, handleReconnectUpdates);

      socket.on(MESSAGE_ALL_CHAT, handleReceieveMessage);

      socket.on(ALL_USER_COUNT, handleUserCount);
      socket.on(NEW_MESSAGE_TO_ADMIN, handleNewMessageAdmin);
      socket.on(ORDER_CREATED_TO_ADMIN, handleNewOrder);
      socket.on(UPDATE_ORDER_STATUS_TO_ADMIN, handleOrderStatusUpdate);
      socket.on(ORDER_UPDATE_BY_ADMIN, handleOrderUpdate);
      socket.on(USER_MESSAGE_ON_ADMIN_CHAT, handleUserMessageOnAdminChat);
      socket.on(ADMIN_READ_AT_UPDATE_TO_ADMIN, handleAdminReadAt);
      socket.on(EMIT_DELETED_MESSAGE, handleDeleteMessage);

      //feed
      socket.on(UPDATE_ALL_GAME_FEED, handleGameFeed);

      //feed
      socket.on(UPDATE_ALL_GAME_HIGH_ROLLER_FEED, handleHighRollerFeed);

      //Level
      socket.on(UPDATE_LEVEL, handleLevelUpdate);

      //Decrement
      socket.on(UPDATE_BALANCE, handleUpdateBalance);

      //User
      socket.on(UPDATE_USER, handleUserUpdate);

      // Dice
      socket.on(EMIT_DICE_GAME, handleDiceGame);

      // LIMBO
      socket.on(EMIT_LIMBO_GAME, handleLimboGame);

      // COINFLIP
      socket.on(EMIT_COIN_FLIP_GAME, handleCoinFlipGame);

      // KENO
      socket.on(EMIT_KENO_GAME, handleKeno);

      // PLINKO
      socket.on(EMIT_PLINKO_GAME, handlePlinkoGame);

      // MINES
      socket.on(EMIT_MINES_GAME, handleMinesCreateGame);
      socket.on(EMIT_MINES_PAYOUT_GAME, handlePayout);
      socket.on(EMIT_CHECK_MINES_GAME, handleMinesCheck);

      // BlackJack
      socket.on(EMIT_BLACKJACK_GAME, handleBlackjackCreateGame);
      socket.on(EMIT_CHECK_BLACKJACK_GAME, handleBlackjackCheck);

      // BlackJack
      socket.on(EMIT_HILO_GAME, handleHiloCreateGame);
      socket.on(EMIT_CHECK_HILO_GAME, handleHiloCheck);
      //Constants
      socket.on(UPDATE_CONSTANTS, handleUpdateConstants);

      //Rain
      socket.on(CHAT_RAIN_UPDATE, handleRain);

      socket.on(NOTIFICATION_EMIT, handleNotification);

      socket.on(CHALLENGES_UPDATE, handleChallengesUpdate);

      return () => {
        socket.off("disconnect");
        socket.off("connect");
        // socket.off("reconnect");
        socket.off(UPDATE_ALL_GAME_FEED, handleGameFeed);
        socket.off(MESSAGE_ALL_CHAT, handleReceieveMessage);
        socket.off(ALL_USER_COUNT, handleUserCount);
        socket.off(NEW_MESSAGE_TO_ADMIN, handleNewMessageAdmin);
        socket.off(ORDER_CREATED_TO_ADMIN, handleNewOrder);
        socket.off(UPDATE_ORDER_STATUS_TO_ADMIN, handleOrderStatusUpdate);
        socket.off(ORDER_UPDATE_BY_ADMIN, handleOrderUpdate);
        socket.off(UPDATE_LEVEL, handleLevelUpdate);
        socket.off(UPDATE_USER, handleUserUpdate);
        socket.off(EMIT_BLACKJACK_GAME, handleBlackjackCreateGame);
        socket.off(EMIT_CHECK_BLACKJACK_GAME, handleBlackjackCheck);
        socket.off(USER_MESSAGE_ON_ADMIN_CHAT, handleUserMessageOnAdminChat);
        socket.off(ADMIN_READ_AT_UPDATE_TO_ADMIN, handleAdminReadAt);
        socket.off(EMIT_DICE_GAME, handleDiceGame);
        socket.off(EMIT_LIMBO_GAME, handleLimboGame);
        socket.off(EMIT_COIN_FLIP_GAME, handleCoinFlipGame);
        socket.off(EMIT_PLINKO_GAME, handlePlinkoGame);
        socket.off(UPDATE_BALANCE, handleUpdateBalance);
        socket.off(EMIT_KENO_GAME, handleKeno);
        socket.off(UPDATE_CONSTANTS, handleUpdateConstants);
        socket.off(EMIT_MINES_GAME, handleMinesCreateGame);
        socket.off(EMIT_MINES_PAYOUT_GAME, handlePayout);
        socket.off(EMIT_CHECK_MINES_GAME, handleMinesCheck);
        socket.off(EMIT_DELETED_MESSAGE, handleDeleteMessage);
        socket.off(CHAT_RAIN_UPDATE, handleRain);
        socket.off(UPDATE_ALL_GAME_HIGH_ROLLER_FEED, handleHighRollerFeed);
        socket.off(NOTIFICATION_EMIT, handleNotification);
        socket.off(CHALLENGES_UPDATE, handleChallengesUpdate);

        // BlackJack
        socket.off(EMIT_HILO_GAME, handleHiloCreateGame);
        socket.off(EMIT_CHECK_HILO_GAME, handleHiloCheck);
        // socket.off("slide-timer", handleSlideTimer);
        // socket.off("slide-result", handleSlideResult);
      };
    }
  }, [socket]);

  useEffect(() => {
    const lastMessageID = allChat[allChat.length - 1]?._id;
    const options = {
      transports: ["websocket"],
      query: accessToken ? { accessToken, lastMessageID } : { lastMessageID },
    };
    const newSocket = io(configuration.api.url, options);

    setSocket(newSocket);
    setIsConnected(newSocket.connected);

    return () => {
      newSocket.disconnect();
    };
  }, [accessToken]);

  const contextValue = useMemo(
    () => ({
      socket,
      isConnected,
      chatUserCount,
      adminUserCount,
    }),
    [socket, isConnected, chatUserCount, adminUserCount]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
}

export default ChatProvider;
