import { Container, Stack, Typography, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  claimBonus,
  selectCurrentUser,
} from "../../components/slices/userSlice";
import { AppDispatch } from "../../components/store";
import { useEffect } from "react";
import styles from "./index.module.scss";
import { TokenIcon } from "../../components/tokenIcon";

import {
  BONUSES,
  ClaimButton,
  ClaimButtonFreePlay,
  CountDownNextFriday,
} from "./countdowns";
import { ProgressBar } from "./progressBar";
import SimpleTable from "../../components/simpleTable";

const data = [
  ["1", "Start", "10%"],
  ["2", "Dragon", "12.5%"],
  ["3", "Barrows", "15%"],
  ["4", "Torva", "20%"],
  ["5", "Elite", "25%"],
  ["6", "Maxed", "30%"],
];

const BONUSESTOINCREMENT = [
  BONUSES.INSTANT,
  BONUSES.DAILY,
  BONUSES.WEEKLY,
  BONUSES.MONTHLY,
];
const Bonuses = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectCurrentUser);

  const handleClaim = (bonusType: string) => {
    dispatch(claimBonus({ bonusType }));
  };

  // Total Bonus
  const bonusesArr = user?.bonuses?.filter((bonus) =>
    BONUSESTOINCREMENT.includes(bonus.type as BONUSES)
  );

  let totalBonus = 0;
  user?.bonuses.forEach((bonus) => {
    if (
      bonus.type !== BONUSES.FIRSTDEPOSIT &&
      bonus.type !== BONUSES.SECONDDEPOSIT &&
      bonus.type !== BONUSES.THIRDDEPOSIT
    ) {
      totalBonus += bonus.total;
    }
  });

  const pendingReward = bonusesArr
    ?.filter((bonus) => bonus.available > 0)
    .reduce((acc, current) => acc + current.available, 0);

  const totalSumBonuses =
    bonusesArr?.reduce((acc, current) => acc + current.total, 0) || 0;
  const bonusesPercentage = (totalSumBonuses / totalBonus) * 100;

  // Total Level Up
  const levelUpBonus =
    user?.bonuses?.find((bonus) => bonus.type === BONUSES.LEVELUPS)?.total || 0;
  const levelUpBonusPercentage = (levelUpBonus / totalBonus) * 100;

  // Total Promo Code
  const promoCodeBonus =
    user?.bonuses?.find((bonus) => bonus.type === BONUSES.PROMOCODES)?.total ||
    0;
  const promoCodeBonusPercentage = (promoCodeBonus / totalBonus) * 100;

  // Total Wager
  const wagerRacesBonus =
    user?.bonuses?.find((bonus) => bonus.type === BONUSES.WAGERRACES)?.total ||
    0;
  const wagerRacesBonusPercentage = (wagerRacesBonus / totalBonus) * 100;

  // Total Wager
  const rainBonus =
    user?.bonuses?.find((bonus) => bonus.type === BONUSES.RAIN)?.total || 0;
  const rainBonusPercentage = (rainBonus / totalBonus) * 100;

  // Total Giveaways
  const giveawayBonus =
    user?.bonuses?.find((bonus) => bonus.type === BONUSES.GIVEAWAY)?.total || 0;
  const giveawayBonusPercentage = (giveawayBonus / totalBonus) * 100;

  return (
    <Container maxWidth="md">
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Reward System
        </Typography>
        <Stack
          gap={2}
          direction={{ md: "row", sm: "column" }}
          sx={{ width: "100%" }}
        >
          <Stack
            gap={2}
            direction="column"
            sx={{ width: { md: "50%", sm: "100%" } }}
          >
            <Card sx={{ p: 2 }}>
              <SimpleTable data={data} />
            </Card>
            <Card sx={{ p: 2 }}>
              <Stack
                justifyContent="space-between"
                direction="column"
                gap={2}
                sx={{ height: "100%" }}
              >
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Freeplay:
                  </Typography>
                  <ClaimButtonFreePlay
                    handleClick={handleClaim}
                    bonusType={BONUSES.FREEPLAY}
                  />
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Instant:
                  </Typography>
                  <ClaimButton
                    handleClick={handleClaim}
                    bonusType={BONUSES.INSTANT}
                  />
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Daily:
                  </Typography>
                  <ClaimButton
                    handleClick={handleClaim}
                    bonusType={BONUSES.DAILY}
                  />
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Weekly:
                  </Typography>
                  <CountDownNextFriday handleClick={handleClaim} />
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Monthly:
                  </Typography>
                  <CountDownNextFriday
                    isMonth={true}
                    handleClick={handleClaim}
                  />
                </Stack>
              </Stack>
            </Card>
          </Stack>
          <Stack
            gap={2}
            direction="column"
            sx={{ width: { md: "50%", sm: "100%" } }}
          >
            <Card sx={{ p: 2 }}>
              <Stack
                justifyContent="space-between"
                direction="column"
                gap={2}
                sx={{ height: "100%" }}
              >
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Level:
                  </Typography>
                  <Typography color="text.primary" variant="h6">
                    {user?.bonusLevel || 1}
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Pending Rakeback:
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <TokenIcon />
                    <Typography color="text.primary" variant="h6">
                      {pendingReward?.toFixed(5) || 0}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Total Rakeback:
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <TokenIcon />
                    <Typography color="text.primary" variant="h6">
                      {totalSumBonuses?.toFixed(5) || 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>

            <Card sx={{ p: 2 }}>
              <Stack
                justifyContent="space-between"
                direction="column"
                gap={2}
                sx={{ height: "100%" }}
              >
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="flex-start"
                >
                  <Typography color="text.primary" variant="h6">
                    Rewards Breakdown
                  </Typography>
                  {/* <Stack alignItems="center" gap={1}>
                    <Typography color="text.primary" variant="h6">
                      All Rewards
                    </Typography>
                  </Stack> */}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  justifyContent="center"
                >
                  <TokenIcon width={30} />
                  <Typography
                    color="text.primary"
                    variant="h4"
                    fontWeight={600}
                  >
                    {totalBonus?.toFixed(2) || 0}
                  </Typography>
                </Stack>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Rakeback
                  </Typography>
                  <ProgressBar percents={100 - bonusesPercentage} />
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Level Ups
                  </Typography>
                  <ProgressBar percents={100 - levelUpBonusPercentage} />
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Wager Races
                  </Typography>
                  <ProgressBar percents={100 - wagerRacesBonusPercentage} />
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Promo Codes
                  </Typography>
                  <ProgressBar percents={100 - promoCodeBonusPercentage} />
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Giveaway
                  </Typography>
                  <ProgressBar percents={100 - giveawayBonusPercentage} />
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography color="text.primary" variant="h6">
                    Rain
                  </Typography>
                  <ProgressBar percents={100 - rainBonusPercentage} />
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Bonuses;
