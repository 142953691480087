import configuration from "../config";
import { EXCHANGE_ROUTE } from "../constants";
import { EXCHANGETYPE } from "../types";

const getBalance = async (
  type: EXCHANGETYPE,
  amount: number,
  isToken: boolean,
  token: string
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${EXCHANGE_ROUTE}/token?type=${type}&amount=${amount}&isToken=${isToken}`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }

  const json = await response.json();
  return json;
};

const post = async (
  type: string,
  amount: number,
  token: string,
  inGameUsername: string,
  crypto?: string,
  cryptoAddress?: string
) => {
  const raw = JSON.stringify({
    type,
    amount,
    inGameUsername,
    crypto,
    cryptoAddress,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${EXCHANGE_ROUTE}/create`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "POST",
      body: raw,
    }
  );

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }

  const json = await response.json();
  return json;
};

const updateStatus = async (id: string, type: string, token: string) => {
  const raw = JSON.stringify({
    type,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${EXCHANGE_ROUTE}/order/${id}`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "PUT",
      body: raw,
    }
  );

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }

  const json = await response.json();
  return json;
};

const getAllOrders = async (token: string, allOrders = true) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${EXCHANGE_ROUTE}/orders?getAll=${allOrders}`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );
  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }

  const json = await response.json();
  return json;
};

const ExchangeService = {
  getBalance,
  post,
  getAllOrders,
  updateStatus,
};

export default ExchangeService;
