import { FC, ChangeEvent, useState } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  Stack,
} from "@mui/material";
import { format } from "date-fns";
import { TokenIcon } from "../../components/tokenIcon";

interface ReferralTableProps {
  className?: string;
  data: any[] | undefined;
}

const applyPagination = (
  promoCodes: any[],
  page: number,
  limit: number
): any[] => {
  return promoCodes.slice(page * limit, page * limit + limit);
};

const ReferralTable: FC<ReferralTableProps> = ({ data }: any) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedCryptoOrders = applyPagination(data, page, limit);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#273F55" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderColor: "#273F55",
                  backgroundColor: "transparent",
                }}
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderColor: "#273F55",
                  backgroundColor: "transparent",
                }}
              >
                Join Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderColor: "#273F55",
                  backgroundColor: "transparent",
                }}
              >
                Profit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*  */}
            {paginatedCryptoOrders.map((promo, index) => {
              return (
                <TableRow
                  key={promo._id}
                  sx={{
                    backgroundColor: index % 2 ? "#1C2B39" : "#213240",
                  }}
                >
                  <TableCell sx={{ borderColor: "#273F55" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {promo.userName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderColor: "#273F55" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(
                        promo.joinDate ? new Date(promo.joinDate) : new Date(),
                        "PP"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderColor: "#273F55" }}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <TokenIcon />
                      <Typography variant="body1" color="text.primary" noWrap>
                        {promo.profit.toFixed(5)}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default ReferralTable;
