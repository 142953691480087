import KingUin40 from "../assets/kinguin/1.png";
import KingUin80 from "../assets/kinguin/2.png";
import KingUin200 from "../assets/kinguin/3.png";
import KingUin300 from "../assets/kinguin/4.png";
import KingUin400 from "../assets/kinguin/5.png";
import KingUin600 from "../assets/kinguin/6.png";
import KingUin800 from "../assets/kinguin/7.png";
import KingUin1000 from "../assets/kinguin/8.png";
import KingUin2000 from "../assets/kinguin/9.png";

export const kinguinData = [
  {
    image: KingUin40,
    url: "https://www.kinguin.net/category/278756/runewager-40-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin80,
    url: "https://www.kinguin.net/category/281044/runewager-80-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin200,
    url: "https://www.kinguin.net/category/281047/runewager-200-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin300,
    url: "https://www.kinguin.net/category/281049/runewager-300-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin400,
    url: "https://www.kinguin.net/category/278756/runewager-400-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin600,
    url: "https://www.kinguin.net/category/281051/runewager-600-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin800,
    url: "https://www.kinguin.net/category/281055/runewager-800-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin1000,
    url: "https://www.kinguin.net/category/281060/runewager-1000-sweep-cash?referrer=runewager.com",
  },
  {
    image: KingUin2000,
    url: "https://www.kinguin.net/category/281062/runewager-2000-sweep-cash?referrer=runewager.com",
  },
];

// https://www.kinguin.net/category/278756/runewager-40-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281044/runewager-80-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281047/runewager-200-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281049/runewager-300-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281050/runewager-400-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281051/runewager-600-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281055/runewager-800-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281060/runewager-1000-sweep-cash?referrer=runewager.com
// https://www.kinguin.net/category/281062/runewager-2000-sweep-cash?referrer=runewager.com
