import { Stack, Typography } from "@mui/material";
import styles from "./index.module.scss";
import { useState, useRef, useEffect, useContext } from "react";
import {
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundNumberFourDecimalPoints,
} from "../../utils";
import {
  selectCurrentUser,
  selectSlideToggle,
  selectToken,
  selectTokenSelection,
  selectVolume,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import Slider from "./slider";
import {
  selecGameState,
  selectError,
  selectJackpot,
  selectSlideResult,
  selectSlideTimer,
} from "../slices/slideSlice";
import { SLIDESTATE } from "../../constants/slide";
import { PastResults } from "./pastResults";
import { BetButton } from "./betButton";
import ChatContext from "../../contexts/chat/context";
import { PLAY_SLIDE_AUTO_GAME, PLAY_SLIDE_GAME } from "../../constants/socket";
import { sliderImagesNumber } from "./constants";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { theme } from "../theme";
import { TokenIcon } from "../tokenIcon";
import JackpotWinners from "./jackpotWinners";
import { handleDoubleBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import AnimationSound from "../../assets/audio/slide/animation.mp3";
import { DICEMODE, TOKENTYPE } from "../../types/index.d";
import { boolean } from "yup";

export const Slide = () => {
  const player = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectToken);
  const [currentBet, setCurrentBet] = useState(0);
  const { socket } = useContext(ChatContext);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [resultIndex, setResultIndex] = useState(7);
  const [disabled, setDisabled] = useState(false);
  const slideTimer = useAppSelector(selectSlideTimer);
  const gameState = useAppSelector(selecGameState);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const slideResult = useAppSelector(selectSlideResult);
  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const jackpot = useAppSelector(selectJackpot);
  const selectionRef = useRef<null | number>(null);

  const [placedBet, setPlacedBet] = useState<number[]>([]);
  const [jackpotModal, setJackpotModal] = useState(false);
  const [disableDebounce, setDisableDebounce] = useState(false);
  const [title, setTitle] = useState("");
  const toggle = useAppSelector(selectSlideToggle);
  const lastBetRef = useRef<number | null>(null);
  const errorMessage = useAppSelector(selectError);
  const volume = useAppSelector(selectVolume);
  const tokenType = useAppSelector(selectTokenSelection);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });

  const [playType, setPlayType] = useState(DICEMODE.MANUAL);
  const [autoBetSelection, setAutoBetSelection] = useState<
    { selection: number; currentBet: number; tokenType: TOKENTYPE }[]
  >([]);
  const [isPlayingAuto, setIsPlayingAuto] = useState(false);
  const tokenRef = useRef(tokenType);
  const autoBetRef = useRef(0);

  useEffect(() => {
    if (gameState === SLIDESTATE.RESULT && slideResult) {
      setResultIndex(slideResult + 6 * 15);
    }
  });

  useEffect(() => {
    if (errorMessage && lastBetRef.current !== null) {
      setPlacedBet(placedBet.filter((x) => x !== lastBetRef.current));
      lastBetRef.current = null;
    }
  }, [errorMessage, placedBet]);

  const handleUpdate = (result = 0) => {
    setResultIndex(result + 6 * 15); // Set the index of the result image
    setTriggerAnimation(true);
    setDisabled(true);
  };

  const resetSlider = () => {
    setTriggerAnimation(false);
    setResultIndex(7);
  };

  useEffect(() => {
    if (gameState === SLIDESTATE.ANIMATING) {
      setPlacedBet([]);
      selectionRef.current = null;
      if (confirmation) {
        setDontShowConfirmation(false);
        setConfirmation(false);
      }
      handleUpdate(slideResult || 0);
      setDisabled(true);
    }

    if (gameState === SLIDESTATE.COUNTDOWN) {
      resetSlider();
      setDisabled(false);
    }
  }, [slideTimer, slideResult]);

  const handleBet = (
    selection: number,
    askConfirmation = true,
    betAmount = currentBet
  ) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (selection < 2 && betAmount > 25000 && tokenType === TOKENTYPE.SWEEP) {
      setTitle(`Max bet on red & black is 25000`);
      betFieldRef.current?.focus();
      return;
    }
    if (selection === 2 && betAmount > 3500 && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      setTitle(`Max bet on green is 3500`);
      return;
    }
    if (betAmount > player.balance && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      return;
    }
    if (betAmount > player.freeCash && tokenType === TOKENTYPE.FREE) {
      betFieldRef.current?.focus();
      return;
    }
    if (
      tokenType === TOKENTYPE.SWEEP &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          betAmount,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(betAmount, player.balance, 95))
    ) {
      selectionRef.current = selection;
      setConfirmation(true);
      return;
    }

    if (betAmount && !disabled) {
      setDisableDebounce(true);
      lastBetRef.current = selection;
      setPlacedBet([...placedBet, selection]);
      const gameData = {
        currentBet: betAmount,
        selection,
        tokenType,
      };
      playBetSound();
      tokenRef.current = tokenType;
      if (playType === DICEMODE.AUTO) {
        setAutoBetSelection((prev) => {
          const exists = prev.find((pr) => pr.selection === selection);
          if (exists !== undefined) {
            return prev;
          }
          return [...prev, { selection, currentBet: betAmount, tokenType }];
        });
        setIsPlayingAuto(true);
        autoBetRef.current = betAmount;
      }
      socket?.emit(PLAY_SLIDE_GAME, {
        gameData,
        accessToken,
      });
      setInterval(() => {
        setDisableDebounce(false);
      }, 500);
      return;
    }
    betFieldRef.current?.focus();
  };

  const handleUpdatePlayType = (value: DICEMODE) => {
    setPlayType(value);
    setAutoBetSelection([]);
    if (value === DICEMODE.AUTO) {
      setIsPlayingAuto(false);
    }
  };

  const handleStopAuto = () => {
    setIsPlayingAuto(false);
    setAutoBetSelection([]);
  };

  const handleBetAuto = () => {
    if (!disabled && autoBetSelection.length) {
      setDisableDebounce(true);
      // lastBetRef.current = selection;
      // setPlacedBet([...placedBet, selection]);
      const gameData = autoBetSelection;
      playBetSound();
      tokenRef.current = tokenType;
      socket?.emit(PLAY_SLIDE_AUTO_GAME, {
        gameData,
        accessToken,
      });
      setInterval(() => {
        setDisableDebounce(false);
      }, 500);
      return;
    }
    betFieldRef.current?.focus();
  };

  useEffect(() => {
    if (tokenType !== tokenRef.current || !toggle) {
      setAutoBetSelection([]);
    }
  }, [toggle, tokenType]);
  useEffect(() => {
    if (gameState === SLIDESTATE.COUNTDOWN && isPlayingAuto && !disabled) {
      handleBetAuto();
    }
  }, [gameState, disabled]);

  return (
    <>
      <JackpotWinners
        open={jackpotModal}
        handleClose={() => {
          setJackpotModal(false);
        }}
      />
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          handleBet(selectionRef.current || 0, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      <Stack gap={4} sx={{ width: "100%" }}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Slide
        </Typography>

        <Stack gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="flex-end"
          >
            <Typography
              className={styles.glow}
              sx={{ cursor: "pointer" }}
              color={theme.palette.text.primary}
              fontWeight={400}
              variant="h5"
              onClick={() => {
                setJackpotModal(true);
              }}
            >
              Jackpot: <TokenIcon width={40} />{" "}
              {RoundNumberFourDecimalPoints(jackpot?.amount || 0)}
            </Typography>
            <Typography
              color="text.primary"
              fontWeight={600}
              variant="h4"
            ></Typography>
          </Stack>
          <Slider
            triggerAnimation={triggerAnimation}
            targetIndex={resultIndex}
            resetAnimation={resetSlider}
            setDisabled={setDisabled}
            timer={slideTimer}
            gameState={gameState}
          />
        </Stack>
        <Stack
          direction={{ lg: "row", md: "column", sm: "column", xs: "column" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          sx={{ width: "100%" }}
        >
          <PastResults
            handleDoubleBet={() =>
              setCurrentBet(
                handleDoubleBet(
                  tokenType === TOKENTYPE.FREE
                    ? player?.freeCash
                    : player?.balance || 0,
                  currentBet
                )
              )
            }
            playType={playType}
            setPlayType={setPlayType}
            handleUpdatePlayType={handleUpdatePlayType}
            currentBet={currentBet}
            setCurrentBet={setCurrentBet}
            betFieldRef={betFieldRef}
            balance={
              tokenType === TOKENTYPE.FREE
                ? player?.freeCash
                : player?.balance || 0
            }
            gameState={gameState}
            disabled={disabled || placedBet.length === 3 || !toggle}
            toggle={toggle}
            slideResult={sliderImagesNumber[slideResult || 0]}
            setTitle={setTitle}
            title={title}
            showStop={autoBetSelection.length}
            handleStopAuto={handleStopAuto}
          />
        </Stack>

        <BetButton
          disabled={disabled || disableDebounce || !toggle}
          placedBet={placedBet}
          handleBet={handleBet}
          gameState={gameState}
          slideResult={sliderImagesNumber[slideResult || 0]}
          playType={playType}
        />
      </Stack>
    </>
  );
};
