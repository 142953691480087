import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { RoundNumber } from "../../utils";
import { KenoDocument } from "../slices/kenoSlice";
import { theme } from "../theme";
import { TokenIcon } from "../tokenIcon";
import { kenoMultipliers, KENORISK, kenoWinChances } from "./constants";

import styles from "./index.module.scss";

interface IPayouts {
  selectedNumbers: number[];
  riskType: KENORISK;
  currentBet: number;
  keno: KenoDocument | null;
  kenoResult: number[];
}

const getWinChances = (selectedNumbersLength: number, selection: number) => {
  const chances = kenoWinChances[selectedNumbersLength][selection];
  return `${chances?.toFixed(8)}%`;
};

export const Payouts = ({
  selectedNumbers,
  riskType,
  currentBet,
  keno,
  kenoResult,
}: IPayouts) => {
  const [selection, setSelection] = useState<number | null>(null);

  useEffect(() => {
    if (selection && selectedNumbers.length < selection) {
      setSelection(null);
    }
  }, [selectedNumbers]);

  const multiplier = kenoMultipliers[riskType][selectedNumbers.length - 1];

  const handleSelectionUpdate = (index: number) => {
    if (index === selection) {
      setSelection(null);
      return;
    }
    setSelection(index);
  };
  let selectionMultiplier = 0;
  if (selection !== null) {
    selectionMultiplier =
      kenoMultipliers[riskType][selectedNumbers.length - 1][selection];
  }
  if (!selectedNumbers.length) {
    return (
      <Stack justifyContent="center">
        <Typography
          color="text.primary"
          variant="h6"
          sx={{ textAlign: "center" }}
        >
          Select 1 - 10 numbers to begin
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={{ md: 2, xs: 1 }}
    >
      <Stack
        alignItems="flex-start"
        justifyContent="space-between"
        direction="row"
        sx={{ width: "100%" }}
        gap={1}
      >
        {[0, ...selectedNumbers].map((number, index) => {
          return (
            <Stack
              direction="column"
              key={`${index}-${number}`}
              gap={1}
              sx={{ width: "100%" }}
            >
              <Stack
                sx={{
                  py: 1,
                  minWidth: { lg: 53, md: 40 },
                  backgroundColor:
                    keno?.matches === index && kenoResult.length === 10
                      ? theme.palette.success.main
                      : "",
                  boxShadow:
                    keno?.matches === index && kenoResult.length === 10
                      ? "0px 4px rgba(50, 201, 50,0.75)"
                      : "",
                }}
                className={styles.multiplier}
              >
                <Typography
                  color="text.primary"
                  sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
                >
                  {`${
                    multiplier[index] >= 1000
                      ? `${multiplier[index] / 1000}k`
                      : multiplier[index]
                  }x`}
                </Typography>
              </Stack>
              <Stack
                onClick={() => handleSelectionUpdate(index)}
                sx={{
                  py: 1,
                  minWidth: { lg: 53, md: 40 },
                  // width: "100%",
                  borderRadius: "5px",
                  cursor: "pointer",
                  backgroundColor: selection === index ? "#2F5272" : "none",
                }}
                key={number}
                alignItems="center"
              >
                <Typography
                  color="text.primary"
                  sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
                >
                  {index}x
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      {/* <Stack
        className={styles.number}
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ width: "100%" }}
        direction="row"
        gap={1}
      >
        {[0, ...selectedNumbers].map((number, index) => {
          return (
            <Stack
              onClick={() => handleSelectionUpdate(index)}
              sx={{
                py: 1,
                minWidth: { lg: 53, md: 40 },
                // width: "100%",
                cursor: "pointer",
                backgroundColor:
                  selection === index ? theme.palette.primary.main : "none",
              }}
              key={number}
              alignItems="center"
            >
              <Typography color="text.primary">{index}x</Typography>
            </Stack>
          );
        })}
      </Stack> */}
      {selection !== null ? (
        <Stack
          alignItems="flex-start"
          justifyContent="space-around"
          sx={{ width: "100%" }}
          direction="row"
          gap={1}
        >
          <Stack
            className={styles.multiplier}
            sx={{ py: 1, width: "100%" }}
            direction="row"
            gap={1}
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
              color="text.primary"
              textAlign="center"
            >
              Payout:
            </Typography>
            <TokenIcon sx={{ width: { md: "20px", sm: "20px", xs: "15px" } }} />
            <Typography
              sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
              color="text.primary"
              textAlign="center"
            >
              {RoundNumber(currentBet * selectionMultiplier)}
            </Typography>
          </Stack>
          <Stack
            className={styles.multiplier}
            sx={{ py: 1, width: "100%" }}
            direction="row"
            gap={1}
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
              color="text.primary"
              textAlign="center"
            >
              Profit:
            </Typography>
            <TokenIcon sx={{ width: { md: "20px", sm: "20px", xs: "15px" } }} />
            <Typography
              sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
              color="text.primary"
              textAlign="center"
            >
              {RoundNumber(currentBet * selectionMultiplier - currentBet)}
            </Typography>
          </Stack>
          <Stack
            className={styles.multiplier}
            sx={{ py: 1, width: "100%" }}
            direction="row"
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: { xs: "0.5em", sm: "1em" } }}
              color="text.primary"
            >
              Chance: {getWinChances(selectedNumbers.length - 1, selection)}
              {/* {`${kenoWinChances[selectedNumbers.length - 1][selection]}%`} */}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};
