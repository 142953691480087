export enum MODALTYPE {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  EXCHANGE = "EXCHANGE",
  LIVESUPPORT = "LIVESUPPORT",
  SELFEXCLUDING = "SELFEXCLUDING",
  VERIFYEMAIL = "VERIFYEMAIL",
  VERIFYDISCORD = "VERIFYDISCORD",
  CRYPTODEPOSIT = "CRYPTODEPOSIT",
}

export enum TOKENTYPE {
  FREE = "FREE",
  SWEEP = "SWEEP",
}

export enum USERTYPE {
  STREAMER = "STREAMER",
  HIDDEN = "HIDDEN",
  MOD = "MOD",
  ADMIN = "ADMIN",
  DEV = "DEV",
  OWNER = "OWNER",
  USER = "USER",
  CASHIER = "CASHIER",
}

export enum TILETYPE {
  MINE = "mine",
  DIAMOND = "diamond",
}

export enum DICETYPE {
  ROLLOVER = "rollOver",
  ROLLUNDER = "rollUnder",
}

export enum DICEMODE {
  AUTO = "auto",
  MANUAL = "manual",
}

export interface tile {
  key: string;
  type: TILETYPE;
}

export enum COINFLIP {
  HEADS = "HEADS",
  TAILS = "TAILS",
}

export enum MESSAGETYPE {
  Community = "Community",
  LEVELUP = "LEVEL_UP",
  GAMES = "GAMES",
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum ORDERSTATUS {
  PENDING = "Pending",
  INPROGRESS = "InProgress",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}

export enum EXCHANGETYPE {
  DEPOSITOSRS = "DEPOSITOSRS",
  DEPOSITRS3 = "DEPOSITRS3",
  DEPOSITCRYPTO = "DEPOSITCRYPTO",
  WITHDRAWOSRS = "WITHDRAWOSRS",
  WITHDRAWRS3 = "WITHDRAWRS3",
  WITHDRAWCRYPTO = "WITHDRAWCRYPTO",
}

export enum BLACKJACKACTION {
  INIT = "INIT",
  START = "START",
  HIT = "HIT",
  STAND = "STAND",
  SPLIT = "SPLIT",
  DOUBLE = "DOUBLE",
  CHECK = "CHECK",
  TAKE_INSURANCE = "TAKE_INSURANCE",
  NO_INSURANCE = "NO_INSURANCE",
}

export enum ORDERTYPES {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAW",
  PROMOCODE = "PROMOCODE",
  REDEEMGIFT = "REDEEMGIFT",
  KINGUIN = "KINGUIN",
  TIP = "TIP",
}

export enum JACKPOTOPTIONSTYPES {
  WINNERS = "WINNERS",
  JACKPOTS = "JACKPOTS",
}

export interface IExchangeDataType {
  label: string;
  type: EXCHANGETYPE;
  currency: string;
  exchangeType: ORDERTYPES;
  buttonLabel: string;
}
export interface IMessage {
  _id: string;
  userName: string;
  user: string;
  text: string;
  createdAt: string;
  avatar: string;
  type: MESSAGETYPE;
  readAt: string;
  rank: number;
  userType: string;
  isAdmin: boolean;
  totalWagered?: number;
}

export interface IGameFeed {
  user: string;
  _id: string;
  bet: number;
  payout: number;
  multiplier: number;
  userName: string;
  game: string;
  createdAt: string;
  isWin: boolean;
  isAnonymity: boolean;
  isHighRoller: boolean;
  isLuckyWin: boolean;
  tokenType?: TOKENTYPE;
  secondaryName?: string;
}

export enum BONUSES {
  INSTANT = "INSTANT",
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  LEVELUPS = "LEVELUPS",
  PROMOCODES = "PROMOCODES",
  GIVEAWAY = "GIVEAWAY",
  WAGERRACES = "WAGERRACES",
  FIRSTDEPOSIT = "FIRSTDEPOSIT",
  SECONDDEPOSIT = "SECONDDEPOSIT",
  THIRDDEPOSIT = "THIRDDEPOSIT",
}
