import {
  Container,
  Typography,
  Stack,
  Card,
  InputAdornment,
  MenuItem,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  InputFieldStyled,
  InputFieldWithStartAdornment,
} from "../../components/inputField";
import { theme } from "../../components/theme";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../components/store/hooks";
import { ISlotGame, selectSlotGames } from "../../components/slices/slotsSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  bgamingSlotsReleaseDates,
  getImageEndpoint,
  getSlotsToRecentlyPlayed,
} from "../../constants/slots";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { selectFavouriteGames } from "../../components/slices/userSlice";
import { useSelector } from "react-redux";
import { games } from "../../constants/games";
const sortByList = [
  "All",
  "Alphabetical (A-Z)",
  "Alphabetical (Z-A)",
  "Release Date",
];

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const Favourites = () => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("All");
  const slotGames = useAppSelector(selectSlotGames);
  const [allGames, setAllGames] = useState<any[]>([]);
  const [shownGames, setShownGames] = useState<any[]>([]);
  const navigate = useNavigate();
  const favouriteGames = useSelector(selectFavouriteGames);

  useEffect(() => {
    // Filter games by search query
    const filteredGames = allGames.filter((game) =>
      game.title.toLowerCase().includes(search.toLowerCase())
    );

    if (sortBy === "Release Date") {
      const sortedGames = [...filteredGames].sort((a, b) => {
        const releaseDateA = bgamingSlotsReleaseDates.find(
          (game) => game.identifier === a.identifier
        )?.ReleaseDate;
        const releaseDateB = bgamingSlotsReleaseDates.find(
          (game) => game.identifier === b.identifier
        )?.ReleaseDate;

        const dateA = releaseDateA ? new Date(releaseDateA) : new Date(0);
        const dateB = releaseDateB ? new Date(releaseDateB) : new Date(0);

        return dateB.getTime() - dateA.getTime();
      });

      setAllGames(sortedGames);
      return;
    }

    // Apply sorting after filtering
    if (sortBy === "Alphabetical (A-Z)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setShownGames(sortedGames);
      return;
    }

    if (sortBy === "Alphabetical (Z-A)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        b.title.localeCompare(a.title)
      );
      setShownGames(sortedGames);
      return;
    }

    // If no sorting, just set filtered games
    setShownGames(filteredGames);
  }, [sortBy, search, slotGames]);

  // console.log(slotGames);
  useEffect(() => {
    const result = favouriteGames
      .map((id: string) => {
        // Check in slots
        let slotMatch = slotGames.find(
          (slot) => `Bgaming_${slot.identifier}` === id
        );
        if (slotMatch)
          return {
            identifier: slotMatch.identifier,
            title: slotMatch.title,
            isSlots: true,
            imgSrc: getImageEndpoint(slotMatch, "s6"),
          };

        // Check in games
        let gameMatch = games.find(
          (game) =>
            game.name.toLowerCase() === id.toLowerCase() ||
            (id === "DUEL" && game.name === "Duel Arena")
        );
        if (gameMatch)
          return {
            identifier: gameMatch.url,
            title: gameMatch.name,
            isSlots: false,
            imgSrc: gameMatch.gif,
          };

        return null; // if not found in either
      })
      .filter((data) => data);
    console.log(result);
    setAllGames(result);
    setShownGames(result);
  }, [slotGames]);
  return (
    <Container maxWidth={"md"}>
      <Stack gap={1} justifyContent="flex-start" sx={{ minHeight: "60vh" }}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton
            sx={{
              bgcolor: "#1C2B39",
              height: "1.5em",
              maxWidth: "1.5em",
              fontSize: "1em",
              p: 2,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosIcon
              fontSize="inherit"
              sx={{ ml: 1, color: theme.palette.text.primary }}
            />
          </IconButton>
          <Typography
            sx={{ textAlign: "center" }}
            variant="h5"
            fontWeight={600}
            color="text.primary"
          >
            Favourites
          </Typography>
        </Stack>

        <Card sx={{ p: 2 }}>
          <Stack direction="row" gap={2}>
            <InputFieldStyled
              value={!search ? null : search}
              type="string"
              placeholder="Search"
              fullWidth
              // onBlur={handleBlur}
              // error={Boolean(title) && !disabled}
              // disabled={disabled}
              name="searchField"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              sx={{
                width: "50%",
                backgroundColor: theme.palette.primary.main,
                "& .MuiInputBase-input": {
                  paddingLeft: "calc(1em + 20px)",
                },
              }}
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ position: "absolute", zIndex: 2, marginLeft: "0.5em" }}
                >
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Stack
              sx={{ width: "50%" }}
              direction="row"
              gap={1}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography color="text.primary">Sort By:</Typography>
              <Select
                sx={{ width: "50%" }}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                input={
                  <InputFieldStyled
                    // disabled={disableButtons}
                    sx={{
                      "& .MuiInputBase-input": {
                        "&.Mui-disabled": {
                          backgroundColor: "#2F5272",
                          WebkitTextFillColor: "black",
                        },
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
              >
                {sortByList.map((mine: string) => (
                  <MenuItem value={mine}>{mine}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Card>
        <Grid
          container
          spacing={{ md: 1, sm: 5, xs: 1 }}
          columns={12}
          sx={{
            gridTemplateColumns: {
              md: "repeat(4, 1fr)", // 4 items per row for md and larger
              sm: "repeat(2, 1fr)", // 2 items per row for sm screens
              xs: "repeat(2, 1fr)", // 1 item per row for xs screens
            },
            justifyContent: {
              md: "normal",
              sm: "normal",
              xs: "normal",
            },
          }}
        >
          {shownGames.map((item, i) => {
            return (
              <Grid
                item
                key={item.identifier}
                className={styles.card}
                // minHeight={160}
                // size={{
                xs={4}
                sm={3}
                md={2}
                lg={1.7}
                // }}
              >
                <Link
                  to={
                    item.isSlots
                      ? `/slots/${item.identifier}`
                      : `/game/${item.identifier}`
                  }
                >
                  <Stack>
                    <img
                      src={item.imgSrc}
                      alt={item.title}
                      style={{ borderRadius: "8px" }}
                    />
                  </Stack>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};

export default Favourites;
