import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import MineServices from "../../services/mine"; // Assuming this is correct
import { RootState } from "../store";
import { GAMESTATUS } from "../../constants";
import { TOKENTYPE, tile } from "../../types";

export interface Mine {
  bet: number;
  mines: number;
  payout: number;
  gameStatus: GAMESTATUS | null;
  clientSeed: string;
  gameNonce: string;
  openTiles: tile[];
  mineLocations?: string[];
  isWin: boolean;
  multiplier: number;
  tokenType: TOKENTYPE | null;
}

export interface MineState {
  game: Mine;
  pastResult: Mine[];
  errorMessage: string;
  loading: boolean;
}

const initialState: MineState = {
  game: {
    bet: 0,
    mines: 0,
    payout: 0,
    gameStatus: null,
    clientSeed: "",
    gameNonce: "",
    openTiles: [] as tile[],
    mineLocations: [] as string[],
    isWin: false,
    multiplier: 0,
    tokenType: null,
  },
  pastResult: [],
  loading: false,
  errorMessage: "",
};

export const getMinesPayout = createAsyncThunk(
  "mine/getPayout",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    try {
      const mine = await MineServices.getPayout(accessToken);

      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const getCurrentGame = createAsyncThunk(
  "mine/currentGame",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    try {
      const mine = await MineServices.getGame(accessToken);

      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export interface MineInputData {
  accessToken: string;
  bet: number;
  numOfMines: number;
}

// export const newGame = createAsyncThunk(
//   "mine/newGame",
//   async (mineData: MineInputData, thunkAPI) => {
//     try {
//       const mine = await MineServices.post(
//         mineData.bet,
//         mineData.numOfMines,
//         mineData.accessToken
//       );

//       return mine;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(
//         error instanceof Error ? error.message : "An unknown error occurred"
//       );
//     }
//   }
// );

interface CheckCell {
  key: string;
  accessToken: string;
}
export const checkCell = createAsyncThunk(
  "mine/checkCell",
  async (mineData: CheckCell, thunkAPI) => {
    try {
      const openTiles = await MineServices.getTile(
        mineData.key,
        mineData.accessToken
      );

      return openTiles;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const mineReducer = createSlice({
  name: "mine",
  initialState,
  reducers: {
    resetMineState: (state) => initialState,
    updateLoading: (state) => {
      state.loading = true;
    },
    setErrorMesage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.loading = false;
    },
    handleNewGame: (state, action: PayloadAction<any>) => {
      if (action.payload.game) {
        state.game = action.payload.game;
      } else {
        state.game = action.payload;
      }
      state.errorMessage = "";
      state.loading = false;
    },
    handleMinesPayout: (state, action: PayloadAction<any>) => {
      state.game = action.payload.mines;
      state.game.isWin = action.payload?.isWin || false;
      state.loading = false;
    },
    updatePastGame(state, action: PayloadAction<Mine>) {
      state.pastResult.push(action.payload);
    },
    updateIsWin(state, action: PayloadAction<boolean>) {
      state.game.isWin = action.payload;
    },
    handleCheckCell: (state, action: PayloadAction<any>) => {
      state.game = action.payload.mines;
      state.errorMessage = "";
      if (action.payload?.isWin) {
        state.game = action.payload.mines;
        state.game.isWin = action.payload?.isWin || false;
      } else {
        state.game = action.payload;
        state.game.isWin = action.payload?.isWin || false;
      }
      state.loading = false;
    },
    resetGame: (state) => {
      state.game.isWin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentGame.fulfilled, (state, action) => {
        state.game = action.payload;
        state.loading = false;
      })
      .addCase(getCurrentGame.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCurrentGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkCell.fulfilled, (state, action) => {
        if (action.payload?.isWin) {
          state.game = action.payload.mines;
          state.game.isWin = action.payload?.isWin || false;
        } else {
          state.game = action.payload;
          state.game.isWin = action.payload?.isWin || false;
        }
        state.loading = false;
      })
      .addCase(checkCell.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(checkCell.pending, (state) => {
        state.loading = true;
      })

      .addCase(getMinesPayout.fulfilled, (state, action) => {
        state.game = action.payload.mines;
        state.game.isWin = action.payload?.isWin || false;
        state.loading = false;
      })
      .addCase(getMinesPayout.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getMinesPayout.pending, (state) => {
        state.loading = true;
      });
  },
});

export const selectGame = (state: RootState) => state.mine.game;
export const selectPastGames = (state: RootState) => state.mine.pastResult;
export const getMineErrorMessage = (state: RootState) =>
  state.mine.errorMessage;

export const getMineLoading = (state: RootState) => state.mine.loading;

export const {
  updateLoading,
  resetMineState,
  setErrorMesage,
  resetGame,
  handleNewGame,
  handleMinesPayout,
  handleCheckCell,
  updatePastGame,
  updateIsWin,
} = mineReducer.actions;

export default mineReducer.reducer;
