import "./App.css";
import Home from "./pages/home";
import { useRef, ReactNode, useEffect } from "react";
import {
  selectIsPlayingSlots,
  selectNavDrawer,
  selectToken,
} from "./components/slices/userSlice";
import { Route, Routes, useLocation } from "react-router-dom";
import { styled } from "@mui/material";
import NotFoundPage from "./pages/notFound";
import DicePage from "./pages/game/Dice";
import MinesPage from "./pages/game/Mines";
import { useAppSelector } from "./components/store/hooks";
import { chatDrawerWidth, chatDrawerWidthMin } from "./constants";
import Account from "./pages/account";
import { SnackbarProvider } from "notistack";
import Notifications from "./components/notifications";
import { LevelUpModal } from "./components/levelUpModal";
import LimboPage from "./pages/game/Limbo";
import SlidePage from "./pages/game/Slide";
import CoinFlipPage from "./pages/game/CoinFlip";
import PlinkoPage from "./pages/game/Plinko";
import DuelWagerPage from "./pages/game/DuelWager";
import KenoPage from "./pages/game/Keno";
import StatusMaintenance from "./pages/maintenance";

import Referral from "./pages/referral";
import { HomeReferralWrapper } from "./pages/home/homeReferralWrapper";
import Bonuses from "./pages/bonuses";
import WagerRace from "./pages/wagerRace";
import Fairness from "./pages/fairness";
import { Footer } from "./components/footer";
import CheckVerification from "./components/verificationModal/checkVerification";
import CheckPasswordReset from "./components/resetPasswordModal/checkVerification";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GeoBlock from "./components/geoBlock";
import FAQPage from "./pages/faq";
import PrivacyPolicy from "./pages/privacyPolicy";
import AMLPolicyPage from "./pages/amlPolicy";
import TermsServicePage from "./pages/terms&Service";
import CookieConsent from "react-cookie-consent";
import { access } from "fs";
import BlackJackPage from "./pages/game/BlackJack";
import DepositBonuses from "./pages/depositBonuses";
import HiLoPage from "./pages/game/HiLo";
import SlotsPage from "./pages/game/Slots";
import Slots from "./pages/slots";
import Favourites from "./pages/favourite";
import Originals from "./pages/game";
import Challenges from "./pages/challenges";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
  navBar?: boolean;
}>(({ theme, open, navBar }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: `min(-${chatDrawerWidth}, -${chatDrawerWidthMin}px)`,
  marginLeft: navBar ? `200px` : `calc(${theme.spacing(7)} + 1px)`,
  // marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  position: "relative",

  [theme.breakpoints.down("md")]: {
    marginRight: 0,
    position: "absolute",
    width: "-webkit-fill-available",
    marginLeft: 0,
  },
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

interface PageProps {
  title: string;
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export const AllRoutes = ({
  open,
  isMaintenance,
  buildRef,
}: {
  open: boolean;
  isMaintenance: boolean;
  buildRef: number;
}) => {
  const snackbarRootRef = useRef(null);
  const drawerOpen = useAppSelector(selectNavDrawer);
  const accessToken = useAppSelector(selectToken);
  const isPlayingSlots = useAppSelector(selectIsPlayingSlots);
  // const isBlocked = useAppSelector();

  const { pathname } = useLocation();

  useEffect(() => {
    const scrollableElement = document.getElementById("main-container");
    if (scrollableElement) {
      scrollableElement.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <>
      <Main
        open={open}
        navBar={drawerOpen}
        ref={snackbarRootRef}
        sx={{
          // p: { xs: isPlayingSlots ? 0 : "" },
          // overflow: { xs: isPlayingSlots ? "none" : "" },
          display: "flex",
          height: "95vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <LevelUpModal />
        <CheckVerification />
        <CheckPasswordReset />
        <DrawerHeader />
        <GeoBlock />
        {isMaintenance ? (
          <StatusMaintenance buildRef={buildRef} />
        ) : (
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            className="notificationContainer"
            hideIconVariant
          >
            <Notifications />
            <HelmetProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Page title="Runewager">
                      <Home />
                    </Page>
                  }
                />
                <Route
                  path="/verify"
                  element={
                    <Page title="Runewager - Verify">
                      <Home />
                    </Page>
                  }
                />
                <Route
                  path="/r/*"
                  element={
                    <Page title="Runewager - Referral">
                      <HomeReferralWrapper />
                    </Page>
                  }
                />
                <Route
                  path="/games"
                  element={
                    <Page title="Runewager - Originals">
                      <Originals />
                    </Page>
                  }
                />
                <Route
                  path="/game/dice"
                  element={
                    <Page title="Runewager - Dice Game">
                      <DicePage />
                    </Page>
                  }
                />
                <Route
                  path="/game/mines"
                  element={
                    <Page title="Runewager - Mines Game">
                      <MinesPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/limbo"
                  element={
                    <Page title="Runewager - Limbo Game">
                      <LimboPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/slide"
                  element={
                    <Page title="Runewager - Slide Game">
                      <SlidePage />
                    </Page>
                  }
                />
                <Route
                  path="/game/coinflip"
                  element={
                    <Page title="Runewager - Coin Flip Game">
                      <CoinFlipPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/plinko"
                  element={
                    <Page title="Runewager - Plinko Game">
                      <PlinkoPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/hilo"
                  element={
                    <Page title="Runewager - HiLo Game">
                      <HiLoPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/duel-arena"
                  element={
                    <Page title="Runewager - Duel Arena">
                      <DuelWagerPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/keno"
                  element={
                    <Page title="Runewager - Keno Game">
                      <KenoPage />
                    </Page>
                  }
                />
                <Route
                  path="/game/blackjack"
                  element={
                    <Page title="Runewager - BlackJack Game">
                      <BlackJackPage />
                    </Page>
                  }
                />
                <Route
                  path="/slots"
                  element={
                    <Page title="Runewager - Slots">
                      <Slots />
                    </Page>
                  }
                />
                <Route
                  path="/favourites"
                  element={
                    <Page title="Runewager - Favourites">
                      <Favourites />
                    </Page>
                  }
                />
                <Route
                  path="/challenges"
                  element={
                    <Page title="Runewager - Challenges">
                      <Challenges />
                    </Page>
                  }
                />

                <Route
                  path="/slots/:slotName"
                  element={
                    <Page title="Runewager - Slots">
                      <SlotsPage />
                    </Page>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Page title="Runewager - Profile">
                      <Account />
                    </Page>
                  }
                />
                <Route
                  path="/profile/:tab"
                  element={
                    <Page title="Runewager - Profile">
                      <Account />
                    </Page>
                  }
                />
                <Route
                  path="/affiliate"
                  element={
                    <Page title="Runewager - Affiliate">
                      <Referral />
                    </Page>
                  }
                />
                <Route
                  path="/rewards"
                  element={
                    <Page title="Runewager - Bonuses">
                      <Bonuses />
                    </Page>
                  }
                />
                <Route
                  path="/rakeback"
                  element={
                    <Page title="Runewager - Bonuses">
                      <Bonuses />
                    </Page>
                  }
                />
                {/* <Route
                  path="/deposit-bonuses"
                  element={
                    <Page title="Runewager - Bonuses">
                      <DepositBonuses />
                    </Page>
                  }
                /> */}
                <Route
                  path="/wager-race"
                  element={
                    <Page title="Runewager - Wager Race">
                      <WagerRace />
                    </Page>
                  }
                />
                <Route
                  path="/fairness"
                  element={
                    <Page title="Runewager - Fairness">
                      <Fairness />
                    </Page>
                  }
                />
                <Route
                  path="/fairness/:tab"
                  element={
                    <Page title="Runewager - Fairness">
                      <Fairness />
                    </Page>
                  }
                />
                <Route
                  path="/faq"
                  element={
                    <Page title="Runewager - FAQ">
                      <FAQPage />
                    </Page>
                  }
                />
                <Route
                  path="/aml-policy"
                  element={
                    <Page title="Runewager - AML Policy">
                      <AMLPolicyPage />
                    </Page>
                  }
                />
                <Route
                  path="/terms-&-service"
                  element={
                    <Page title="Runewager - Terms & Service">
                      <TermsServicePage />
                    </Page>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <Page title="Runewager - Privacy Policy">
                      <PrivacyPolicy />
                    </Page>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Page title="Runewager - Page Not Found">
                      <NotFoundPage />
                    </Page>
                  }
                />
              </Routes>
            </HelmetProvider>
            <CookieConsent
              location="bottom"
              visible={accessToken ? "hidden" : "byCookieValue"}
              buttonText="Agree"
              cookieName="isCookieAccepted"
              style={{
                background: "#1C2B39",
                zIndex: 10000,
                justifyContent: "center",
              }}
              contentStyle={{ flex: "" }}
              buttonStyle={{
                color: "white",
                fontSize: "13px",
                backgroundColor: "#213240",
              }}
              expires={150}
            >
              By using our site you agree to the use of cookies
            </CookieConsent>
          </SnackbarProvider>
        )}
        <DrawerHeader sx={{ display: { xs: "block", sm: "none" } }} />
        <Footer />
      </Main>
    </>
  );
};

export default AllRoutes;
