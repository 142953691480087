import { Stack, Typography, Switch, Slider } from "@mui/material";
import {
  selectCurrentUser,
  selectSettingsLoading,
  updateSettings,
  User,
} from "../../slices/userSlice";
import { useAppSelector } from "../../store/hooks";
import { styled } from "@mui/material/styles";
import { theme } from "../../theme";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

function valuetext(value: number) {
  return `${value}%`;
}

export const CustomSwitch = styled(Switch)(({ theme, checked }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#32C932",
    "&:hover": {
      backgroundColor: "rgba(50, 201, 50, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase": {
    color: "#D92324",
    "&:hover": {
      backgroundColor: "rgba(217, 35, 36, 0.08)",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "#32C932 !important" : "#D92324",
  },
  "& .MuiSwitch-thumb": {
    color: checked ? "#32C932" : "#D92324",
  },
}));

export const Settings = () => {
  const player = useAppSelector(selectCurrentUser);
  const loading = useAppSelector(selectSettingsLoading);
  const dispatch = useDispatch<AppDispatch>();

  const [sliderValue, setSliderValue] = useState(player.betConfirmationValue);

  const handleChange = <K extends keyof User>(label: K, value: User[K]) => {
    let payload: Partial<User> = { [label]: value };
    if (label === "isBetConfirmation") {
      if (value && player.betConfirmationValue === 0) {
        payload.betConfirmationValue = 1;
      }
      if (!value && player.betConfirmationValue !== 0) {
        payload.betConfirmationValue = 0;
      }
    }
    dispatch(updateSettings(payload));
  };

  const handleChangeSlider = <K extends keyof User>(
    label: K,
    value: User[K]
  ) => {
    let payload: Partial<User> = { [label]: value };
    if (value === 0) {
      payload.isBetConfirmation = false;
    }

    if (value && value > 0 && !player.isBetConfirmation) {
      payload.isBetConfirmation = true;
    }
    dispatch(updateSettings(payload));
  };

  useEffect(() => {
    setSliderValue(player.betConfirmationValue);
  }, [player]);
  return (
    <Stack
      gap={2}
      sx={{
        width: { sm: "50%", xs: "100%" },
        // filter: loading ? "blur(2px)" : "none",
      }}
    >
      <Stack
        gap={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>Bet confirmation (% of balance)</Typography>
        <CustomSwitch
          sx={{ pointerEvent: loading ? "none" : "auto" }}
          color="secondary"
          checked={player.isBetConfirmation}
          onChange={(_e, value) => handleChange("isBetConfirmation", value)}
        />
      </Stack>
      <Stack alignItems="center">
        <Slider
          aria-label="Always visible"
          sx={{
            my: 2,
            width: "90%",
            pointerEvent: loading ? "none" : "auto",
            color: "white",
          }}
          onChangeCommitted={(_e, value) =>
            handleChangeSlider("betConfirmationValue", value as number)
          }
          onChange={(_e, value) => setSliderValue(value as number)}
          value={sliderValue}
          max={95}
          getAriaValueText={valuetext}
          step={1}
          valueLabelDisplay="on"
        />
      </Stack>
      <Stack
        gap={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>Anonymity</Typography>
        <CustomSwitch
          color="secondary"
          checked={player.isAnonymity}
          sx={{ pointerEvent: loading ? "none" : "auto" }}
          onChange={(_e, value) => handleChange("isAnonymity", value)}
        />
      </Stack>

      <Stack
        gap={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>Opt in for Email Rewards</Typography>
        <CustomSwitch
          color="secondary"
          checked={player.optInEmail}
          sx={{ pointerEvent: loading ? "none" : "auto" }}
          onChange={(_e, value) => handleChange("optInEmail", value)}
        />
      </Stack>
    </Stack>
  );
};
