import { getAllAdminMessages, selectAdminChat } from "../slices/chatSlice";
import { useAppSelector } from "../store/hooks";
import { useEffect, useState, useContext, useRef } from "react";
import {
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { theme } from "../theme";
import { Order, updateStatus } from "../slices/orderSlice";
import { getOrderData } from "../../constants/exchange";
import { EXCHANGETYPE, IMessage, ORDERSTATUS } from "../../types/index.d";
import { Button } from "../button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { formatDistanceToNow, parseISO } from "date-fns";
import { ConfirmationDialog } from "../confirmationModal";
import { ADMIN_READ_AT_UPDATE } from "../../constants/socket";
import ChatContext from "../../contexts/chat/context";
import { selectToken } from "../slices/userSlice";
import { TokenIcon } from "../tokenIcon";
import { customEmojisData } from "../../constants/messaages";
import { checkContainsAllEmojis, containsOnlyCustomEmojis } from "./textBubble";

const initialState = {
  title: "",
  text: "",
  status: ORDERSTATUS.PENDING,
  isOpen: false,
};
export const AdminChat = ({
  selectedChat,
  setSelectedChat,
  userName,
}: {
  selectedChat: string;
  setSelectedChat: (type: string) => void;
  userName: string;
}) => {
  const chatData = useAppSelector(selectAdminChat);
  const dispatch = useDispatch<AppDispatch>();
  const { socket } = useContext(ChatContext);
  const accessToken = useAppSelector(selectToken);

  const [confirmationDetails, setConfirmationDetails] = useState(initialState);
  const orderChatRef = useRef<HTMLDivElement | null>(null);
  const chatLength = useRef(0);

  const selected = chatData.find((chat) => chat.order._id === selectedChat);

  const openedChat = selected?.messages;
  const selectedOrder = selected?.order;

  const handleUpdateStatus = (id: string, status: ORDERSTATUS) => {
    setConfirmationDetails(initialState);
    dispatch(updateStatus({ id, newStatus: status }));
  };

  const handleConfirmModal = (status: ORDERSTATUS) => {
    const orderInfo = getOrderData(selectedOrder?.type || "");
    setConfirmationDetails({
      title: `${orderInfo?.label || ""} - ${selected?.user?.userName || ""} - ${
        selectedOrder?.amount || ""
      }  - ${selectedOrder?.tokens || ""} Tokens - ${
        selectedOrder?.inGameUserName || ""
      }`,
      text: `Are you sure you want to ${
        status === ORDERSTATUS.CANCELLED ? "cancel" : "complete"
      }?`,
      status,
      isOpen: true,
    });
  };

  const renderTimeDifference = (timestamp: string) => {
    return formatDistanceToNow(parseISO(timestamp), { addSuffix: true });
  };

  const sendSocketReadAt = (id: string) => {
    const unReadMessages =
      selected?.messages?.filter(
        (data: IMessage) => !data?.readAt && !data.isAdmin
      ) || [];

    if (unReadMessages?.length) {
      socket?.emit(ADMIN_READ_AT_UPDATE, {
        accessToken,
        orderId: id,
      });
    }
  };

  const handleUpdateSelection = (id: string, update: boolean) => {
    setSelectedChat(id);
    if (update) {
      socket?.emit(ADMIN_READ_AT_UPDATE, {
        accessToken,
        orderId: id,
      });
    }
  };

  useEffect(() => {
    if (selectedChat && chatData) {
      const isSelected = chatData.find(
        (chat) => chat.order._id === selectedChat
      );
      if (!isSelected) {
        setSelectedChat("");
      }
    }
  }, [selectedChat, chatData]);

  useEffect(() => {
    if (openedChat && chatLength.current !== openedChat?.length) {
      chatLength.current = openedChat.length;
      if (orderChatRef.current) {
        orderChatRef.current.scrollTop = orderChatRef.current.scrollHeight;
      }
    }
  }, [openedChat]);

  const chatDataWithTimeStamp = chatData
    .map((data) => {
      let timeStamp = data?.order.createdAt;
      if (data?.messages?.length) {
        timeStamp = data?.messages[data?.messages?.length - 1]?.createdAt;
      }
      return { ...data, timeStamp };
    })
    .sort((a, b) => {
      let dateA = new Date(a.timeStamp).getTime();
      let dateB = new Date(b.timeStamp).getTime();
      return dateB - dateA;
    });

  const getBgColor = (chatId: string, undeReadMesages: number) => {
    if (chatId === selectedChat) {
      return "#1F68AA";
    }
    if (undeReadMesages) {
      return "rgba(201, 242, 155, 0.1)";
    }
    return "#213240";
  };
  const timeRef = useRef<any>();
  useEffect(() => {
    if (chatData.length) {
      timeRef.current = setInterval(() => {
        if (chatData.length) {
          dispatch(getAllAdminMessages());
        }
      }, 2000);
    }

    if (!chatData.length && timeRef.current) {
      clearInterval(timeRef.current);
    }

    return () => clearInterval(timeRef.current);
  }, [chatData]);

  const handleMessage = (message: any) => {
    if (message.text.includes('{"url":"https://')) {
      try {
        const previewData = JSON.parse(message.text);
        return (
          <img
            src={previewData.url}
            alt={previewData.url}
            style={{ width: "80%" }}
          />
        );
      } catch {
        return null;
      }
    }

    if (message.text.includes("{{{custom-")) {
      try {
        let text = message.text;
        const toIncrasesize =
          containsOnlyCustomEmojis(text) || checkContainsAllEmojis(text);

        customEmojisData.forEach((emoji) => {
          const regex = new RegExp(emoji.id, "g");
          text = text.replaceAll(
            regex,
            `<img src="${emoji.imgUrl}" alt="${emoji.id}" style="width:${
              toIncrasesize ? "40px" : "15px"
            };height:${
              toIncrasesize ? "40px" : "15px"
            }; display:inline-block;" />`
          );
        });

        if (text) {
          return (
            <Typography
              sx={{
                textWrap: "pretty",
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
                fontSize: toIncrasesize ? "2em" : "1em",
                display: "flex",
              }}
              color="#9897A9"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></Typography>
          );
        }
        return null;
      } catch {
        return null;
      }
    }

    return (
      <Typography
        sx={{
          textWrap: "pretty",
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        }}
        color="#9897A9"
      >
        {message.text}
      </Typography>
    );
  };

  return (
    <>
      <ConfirmationDialog
        handleConfirm={() =>
          handleUpdateStatus(
            selectedOrder?._id as string,
            confirmationDetails.status
          )
        }
        open={confirmationDetails.isOpen}
        handleCancel={() => setConfirmationDetails(initialState)}
        text={confirmationDetails.text}
        title={confirmationDetails.title}
      />
      <Stack direction="row" alignItems="flex-start">
        <List sx={{ minWidth: "40%", pt: 0, userSelect: "text" }}>
          {chatDataWithTimeStamp.map((chat) => {
            const unReadMessages =
              chat?.messages?.filter(
                (data) => !data?.readAt && !data.isAdmin
              ) || [];
            const lastMessage = chat?.messages?.length
              ? chat?.messages[chat?.messages?.length - 1]
              : { text: "New Chat", createdAt: null };

            const messageData = handleMessage(lastMessage);

            return (
              <ListItem
                key={chat.order._id}
                sx={{
                  alignItems: "flex-start",
                  backgroundColor: getBgColor(
                    chat.order._id,
                    unReadMessages?.length
                  ),
                  borderBottom: "1px solid #3f3f3f",
                  cursor: "pointer",
                  userSelect: "text",
                }}
                onClick={() =>
                  handleUpdateSelection(
                    chat.order._id,
                    unReadMessages.length > 0 || false
                  )
                }
              >
                <ListItemAvatar sx={{ position: "relative", mt: 1 }}>
                  <Avatar>
                    {chat?.user?.userName?.charAt(0).toUpperCase() || ""}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography>
                          {chat.user.userName || "Deleted User"}
                        </Typography>
                        {unReadMessages?.length > 0 && (
                          <Typography
                            sx={{
                              bgcolor: "green",
                              borderRadius: "50%",
                              width: 20,
                              height: 20,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {unReadMessages?.length}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  }
                  secondary={
                    <Stack sx={{ maxWidth: "120px" }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        color="#9897A9"
                      >
                        {messageData}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: 12,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        color="#9897A9"
                      >
                        {lastMessage?.createdAt
                          ? renderTimeDifference(lastMessage?.createdAt)
                          : ""}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        {selectedChat && (
          <Stack
            direction="column"
            sx={{ width: "60%", height: "73vh" }}
            onClick={() => sendSocketReadAt(selectedOrder?._id || "")}
          >
            {selectedOrder && (
              <OrderInfo
                handleUpdateStatus={handleConfirmModal}
                order={selectedOrder as Order}
              />
            )}
            <Stack
              gap={1}
              ref={orderChatRef}
              sx={{
                px: 2,
                py: 1,
                border: "1px solid #3f3f3f",
                overflowY: "scroll",
                // height: { xl: "55vh", lg: "45vh" },
              }}
            >
              {openedChat &&
                openedChat.map((message) => {
                  const messageData = handleMessage(message);
                  return (
                    <Stack>
                      <Stack
                        direction={
                          message.userName === userName ? "row" : "row"
                        }
                        justifyContent="space-between"
                      >
                        <Typography>{message.userName}</Typography>
                        <Typography>
                          {renderTimeDifference(message.createdAt)}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          bgcolor:
                            message.userName === userName
                              ? "#273F55" 
                              : "#213240",
                          alignSelf: "start",
                          userSelect: "text",
                          p: 1,
                          borderRadius: 2,
                          // width: "-webkit-fill-available",
                          width: "99%",
                          textWrap: "balance",
                          wordBreak: "break-word",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        {messageData}
                      </Stack>
                    </Stack>
                  );
                })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const OrderInfo = ({
  order,
  handleUpdateStatus,
}: {
  order: Order;
  handleUpdateStatus: (status: ORDERSTATUS) => void;
}) => {
  const isWithdraw =
    order?.type === EXCHANGETYPE.WITHDRAWCRYPTO ||
    order?.type === EXCHANGETYPE.WITHDRAWOSRS ||
    order?.type === EXCHANGETYPE.WITHDRAWRS3;
  const isCrypto =
    order?.type === EXCHANGETYPE.DEPOSITCRYPTO ||
    order?.type === EXCHANGETYPE.WITHDRAWCRYPTO;
  return (
    <Stack direction="column" sx={{ mx: 2, mb: 1, position: "sticky" }}>
      <Stack direction="column">
        <Typography>Order ID: {order._id}</Typography>

        <Typography>Type: {getOrderData(order.type)?.label || ""}</Typography>
        {isCrypto && <Typography>Crypto: {order.cryptoType}</Typography>}
        <Typography>In-Game Username: {order.inGameUserName}</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography>Sweep Cash: {order.tokens}</Typography>
          <TokenIcon />
        </Stack>
        {order.freeToken ? (
          <Stack direction="row" gap={0.2} alignItems="center">
            <Typography
              sx={{
                display: "flex",
                gap: 0.2,
                alignItems: "center",
              }}
            >
              Fun Cash: {order.freeToken} <TokenIcon freeCash />
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
        <Typography>
          {getOrderData(order.type)?.currency.replace("($/m)", "") || ""}:{" "}
          {!isCrypto ? `${order.amount}m` : order.amount}
        </Typography>
        {isWithdraw && order?.pastDeposits === 0 ? (
          <Typography color="error">USER HAS NOT DEPOSITED!</Typography>
        ) : null}
        {isWithdraw && order?.accounts && order?.accounts > 0 ? (
          <Typography color="error">
            USER HAS {order?.accounts + 1} ACCOUNTS
          </Typography>
        ) : null}
      </Stack>
      <Stack gap={2} direction="row" justifyContent="space-between">
        <Button
          fullWidth
          variantType="error"
          onClick={() => handleUpdateStatus(ORDERSTATUS.CANCELLED)}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          variantType="success"
          onClick={() => handleUpdateStatus(ORDERSTATUS.COMPLETED)}
        >
          Complete
        </Button>
      </Stack>
    </Stack>
  );
};
