import { Box, Stack, Typography } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { MODALTYPE } from "../../types/index.d";
import Register from "../register";
import Login from "../login";
import ExchangeModal from "../exchange";
import SelectionComponent from "../selectionComponent";
import { theme } from "../theme";
import { useNavigate } from "react-router-dom";
import { CryptoDepositModal } from "../accountTabs/cryptoDepositModal";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 300 },
  maxHeight: "82vh",
  overflowY: "auto",
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
};

interface IModal {
  handleClose: () => void;
  open: boolean;
  modalType?: MODALTYPE;
  setModalType: (type: MODALTYPE) => void;
}

const AuthenticationSelection = [
  {
    type: MODALTYPE.LOGIN,
    label: "Sign In",
  },
  {
    type: MODALTYPE.REGISTER,
    label: "Register",
  },
];

const getWidth = (modalType: MODALTYPE) => {
  if (modalType === MODALTYPE.CRYPTODEPOSIT) {
    return { xs: 320, md: 500 };
  }
  return modalType === MODALTYPE.EXCHANGE
    ? { xs: 250, md: 550 }
    : { xs: 320, md: 300 };
};

const getPadding = (modalType: MODALTYPE) => {
  if (modalType === MODALTYPE.CRYPTODEPOSIT) {
    return { md: 5, xs: 5 };
  }
  return { md: 10, xs: 5 };
};

const Modal = ({ handleClose, open, modalType, setModalType }: IModal) => {
  const changeModalType = (type: string) => {
    setModalType(type as MODALTYPE);
  };
  const navigate = useNavigate();
  return (
    <ModalComponent open={open} onClose={handleClose} disableScrollLock={true}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: theme.palette.primary.main,
          boxShadow: 24,
          width: modalType ? getWidth(modalType) : "auto",
          zIndex: 10,
          py: 4,
          px: modalType ? getPadding(modalType) : "auto",
          borderRadius: 4,
        }}
      >
        <Stack gap={3}>
          {(modalType === MODALTYPE.LOGIN ||
            modalType === MODALTYPE.REGISTER) && (
            <>
              <SelectionComponent
                changeModalType={changeModalType}
                buttonData={AuthenticationSelection}
                modalType={modalType}
              />
              {modalType === MODALTYPE.LOGIN && <Login />}
              {modalType === MODALTYPE.REGISTER && <Register />}
            </>
          )}

          {modalType === MODALTYPE.EXCHANGE && <ExchangeModal />}
          {modalType === MODALTYPE.LIVESUPPORT && (
            <Stack gap={2}>
              <Typography variant="h5" color="text.primary">
                Live Support
              </Typography>
              <Typography color="text.primary">
                If you have any questions or concerns please feel free to reach
                out on Discord and a member of our staff will be happy to
                assist. You can also Email our Support Team at:{" "}
                <a
                  href="mailto:support@runewager.com"
                  target="_blank"
                  style={{ color: "#33EC3D" }}
                >
                  support@runewager.com
                </a>{" "}
              </Typography>
            </Stack>
          )}
          {modalType === MODALTYPE.VERIFYEMAIL && (
            <Stack gap={2}>
              <Typography variant="h5" color="text.primary">
                Verify Email
              </Typography>
              <Typography color="text.primary">
                Please verify your{" "}
                <span
                  onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}
                  style={{ color: "#33EC3D", cursor: "pointer" }}
                >
                  email
                </span>{" "}
                in order to claim the Freeplay
              </Typography>
            </Stack>
          )}

          {modalType === MODALTYPE.CRYPTODEPOSIT && <CryptoDepositModal />}

          {modalType === MODALTYPE.VERIFYDISCORD && (
            <Stack gap={2}>
              <Typography variant="h5" color="text.primary">
                Verify Discord
              </Typography>
              <Typography color="text.primary">
                You must link your{" "}
                <span
                  onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}
                  style={{ color: "#33EC3D", cursor: "pointer" }}
                >
                  Discord account
                </span>{" "}
                to be able to claim Freeplay
              </Typography>
            </Stack>
          )}

          {modalType === MODALTYPE.SELFEXCLUDING && (
            <Stack gap={2}>
              <Typography variant="h5" color="text.primary">
                Self Excluding
              </Typography>
              <Typography color="text.primary">
                To Self Exclude, please Email us at:{" "}
                <a
                  href="mailto:support@runewager.com"
                  target="_blank"
                  style={{ color: "#33EC3D" }}
                >
                  support@runewager.com
                </a>{" "}
                with your Username and Email requesting for Self Exclusion. All
                requests will be considered and handled as soon as possible by a
                member of our support team.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default Modal;
