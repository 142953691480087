import { Stack, Typography, Card, IconButton } from "@mui/material";
import { InputFieldStyled } from "../../inputField";
import { selectPlayer } from "../../slices/userSlice";
import { useAppSelector } from "../../store/hooks";

import { Button } from "../../button";
import Users from "../../../services/user";
import { theme } from "../../theme";
import { useRef, useEffect, useState } from "react";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";

export const DiscordButton = () => {
  const { player, accessToken } = useAppSelector(selectPlayer);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const handleConnectDiscord = async () => {
    if (player.discordUUID) {
      try {
        setLoading(true);
        const res = await Users.unLinkUUID(accessToken);
        if (res) {
          setIsError(false);
        }
      } catch (e: any) {
        setMessage(e.message);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await Users.generateCode(accessToken);
        if (res) {
          setIsError(false);
          setToken(res.code);
          // setMessage(res.message);
        }
      } catch (e: any) {
        setMessage(e.message);
        setIsError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const copyLink = () => {
    navigator.clipboard
      .writeText(token)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  useEffect(() => {
    setToken("");
  }, [player.discordUUID]);

  return (
    <Stack gap={2} sx={{ py: 2, width: { sm: "50%", xs: "100%" } }}>
      <Stack>
        <Typography>
          Discord: {player.discordUUID ? player.discordUUID : "NOT VERIFIED"}
        </Typography>
        <Button
          onClick={handleConnectDiscord}
          disabled={Boolean(token) || loading}
          variantType={player.discordUUID ? "error" : "success"}
        >
          {player.discordUUID ? "UnLink" : "Generate Code"}
        </Button>
      </Stack>
      {token && (
        <Card sx={{ background: "#273F55", p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            gap={4}
            justifyContent="center"
          >
            <Typography>Code:</Typography>
            <InputFieldStyled
              sx={{ bgcolor: "#273F55" }}
              disabled
              value={token}
            />
            <IconButton onClick={copyLink} sx={{ color: "white" }}>
              <ContentCopyTwoToneIcon />
            </IconButton>
          </Stack>
        </Card>
      )}

      {message && (
        <Typography
          sx={{
            color: isError
              ? theme.palette.error.main
              : theme.palette.success.main,
          }}
        >
          {message}
        </Typography>
      )}
    </Stack>
  );
};
