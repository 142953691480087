import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Chip, Typography, Stack } from "@mui/material";
import Card from "./card";
import * as BlackJackUtilities from "./constants";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useSound from "use-sound";
import { theme } from "../theme";
import { selectVolume } from "../slices/userSlice";
import BetSound from "../../assets/audio/blackjack/deal.wav";
import { useAppSelector } from "../store/hooks";

const AnimatedGridItem = motion(Grid);
const getBlackJackColor = (text: string) => {
  switch (text) {
    case "WIN":
      return "#32c932 !important";
    case "BLACK JACK":
      return "#32c932 !important";
    case "LOSE":
      return "#D92324 !important";
    case "PUSH":
      return "#ffa000 !important";
    default:
      return "white !important";
  }
};

interface PlayAreaProps {
  dealersHand: BlackJackUtilities.Card[] | undefined;
  playersHands: any[][] | undefined;
  isTurnEnd: boolean | undefined;
  currentPlayerHandIndex: number | undefined;
  deckRef: any;
  isGameEnd: boolean | undefined;
  id?: string;
  setAnimationEnd?: (state: boolean) => void;
}
const PlayAreaWrapper: React.FC<PlayAreaProps> = (props) => {
  const {
    dealersHand: dealersHandOriginal,
    playersHands,
    isTurnEnd,
    currentPlayerHandIndex,
    isGameEnd,
    id,
    deckRef,
    setAnimationEnd,
  } = props;

  const [activeDealerHand, setActiveDealerHand] = useState<any>();
  const [activePlayerHand, setActivePlayerHand] = useState<any>(); // Assuming 2 players
  // const [intialDone, setInitialDone] = useState<boolean>(false); // Assuming 2 players
  const idRef = useRef<string>();
  const intialDone = useRef<boolean>();

  useEffect(() => {
    if (isGameEnd) {
      if (setAnimationEnd) {
        setAnimationEnd(false);
      }
    }
  }, [isGameEnd]);
  useEffect(() => {
    if (id) {
      intialDone.current = false;
      idRef.current = id;
      if (setAnimationEnd) {
        setAnimationEnd(false);
      }
      // Reset hands for a new game if there's a new ID
      setActiveDealerHand(undefined);
      setActivePlayerHand(undefined);
      dealInitialCards();
    }
  }, [id]);

  useEffect(() => {
    if (intialDone.current && idRef.current && idRef.current === id) {
      setActiveDealerHand(dealersHandOriginal);
      setActivePlayerHand(playersHands);
    }

    if (
      !intialDone.current &&
      playersHands &&
      playersHands?.length > 2 &&
      playersHands?.length !== activePlayerHand.length
    ) {
      setActivePlayerHand(playersHands);
      setActiveDealerHand(dealersHandOriginal);
    }

    //&& playersHands?.length !== activePlayerHand.length
  }, [dealersHandOriginal, playersHands, activePlayerHand]);

  const dealInitialCards = () => {
    const sequence = ["player", "dealer", "player", "dealer", "dealer"];
    sequence.forEach((type, index) => {
      setTimeout(() => {
        if (index === 4) {
          setActivePlayerHand(playersHands);
        } else {
          if (type === "player") {
            // Take a card from playersHands for the first player's hand (index 0)
            const playerCard = (playersHands as any)[0][Math.floor(index / 2)];
            setActivePlayerHand((prev: any) => {
              if (prev) {
                const newHands = [...prev];
                newHands[0] = [...newHands[0], playerCard];
                return newHands;
              } else {
                return [[playerCard]];
              }
            });
          } else {
            // Take a card from dealersHandOriginal
            const dealerCard = (dealersHandOriginal as any)[
              Math.floor(index / 2)
            ];

            setActiveDealerHand((prev: any) => {
              if (prev) {
                return [...prev, dealerCard];
              } else {
                return [dealerCard];
              }
            });
          }
          if (index === 3) {
            intialDone.current = true;
            if (setAnimationEnd) {
              setAnimationEnd(true);
            }
          }
        }
      }, index * 1000);
    });
  };

  return (
    <PlayArea
      dealersHand={activeDealerHand}
      playersHands={activePlayerHand}
      currentPlayerHandIndex={currentPlayerHandIndex}
      isTurnEnd={isTurnEnd}
      isGameEnd={isGameEnd}
      deckRef={deckRef}
    />
  );
};

const PlayArea: React.FC<PlayAreaProps> = (props) => {
  const playerCardsRef = useRef<any>();
  const volume = useAppSelector(selectVolume);
  const [animatedIndex, setAnimatedIndex] = useState(-1);
  const [animatedIndexSplit, setAnimatedIndexSplit] = useState(-1);
  const [animatedIndexDealer, setAnimatedIndexDealer] = useState(-1);
  const [isAnimatingDealer, setIsAnimatingDealer] = useState(false);
  const [playDealSound] = useSound(BetSound, { volume: volume / 100 });

  const {
    dealersHand: dealersHandOriginal,
    playersHands,
    isTurnEnd,
    currentPlayerHandIndex,
    deckRef,
    isGameEnd,
  } = props;
  const [deckX, setDeckX] = useState("100%");
  const [deckY, setDeckY] = useState("-100%");
  const [dealersHand, setDealerHand] = useState<
    BlackJackUtilities.Card[] | undefined
  >();

  const isFinishDealing = dealersHandOriginal?.length === dealersHand?.length;

  const updateDealerHand = () => {
    if (
      dealersHandOriginal &&
      dealersHand &&
      dealersHandOriginal.length &&
      dealersHandOriginal[1] &&
      dealersHand[1]
    ) {
      const isSecondCardOri = dealersHandOriginal[1].rank === "U";
      const isSecondCard = dealersHand[1].rank === "U";

      if (isSecondCardOri !== isSecondCard) {
        setIsAnimatingDealer(true);
        setDealerHand([dealersHandOriginal[0], dealersHandOriginal[1]]);
        return;
      }
    }
    if (
      dealersHandOriginal &&
      animatedIndexDealer < dealersHandOriginal.length - 1
    ) {
      const newEntry: any = dealersHandOriginal[animatedIndexDealer + 1];
      const isPresent = dealersHand?.find(
        (hand: any) => hand._id === newEntry._id
      );
      if (!isPresent) {
        setIsAnimatingDealer(true);
        setDealerHand((prevHand) => [
          ...(prevHand || []),
          dealersHandOriginal[animatedIndexDealer + 1],
        ]);
      }
    }
  };

  // useEffect(() => {
  //   if (JSON.stringify(dealersHandOriginal) === JSON.stringify(dealersHand)) {
  //     return;
  //   } else {
  //     updateDealerHand();
  //   }
  //   if (dealersHandOriginal === undefined || dealersHand === undefined) {
  //     setDealerHand(dealersHandOriginal);
  //   }
  // }, [dealersHandOriginal, animatedIndexDealer, isTurnEnd, isAnimatingDealer]);

  useEffect(() => {
    if (
      dealersHandOriginal &&
      dealersHandOriginal[0] &&
      dealersHandOriginal[1]
    ) {
      updateDealerHand();
    } else {
      setDealerHand(dealersHandOriginal);
    }
  }, [dealersHandOriginal, animatedIndexDealer, isTurnEnd, isAnimatingDealer]);

  useEffect(() => {
    if (deckRef.current && playerCardsRef.current) {
      const rect1 = deckRef.current.getBoundingClientRect();
      const rect2 = playerCardsRef.current.getBoundingClientRect();

      const diffX = rect2.left - rect1.left;
      const diffY = rect2.top - rect1.top;

      setDeckX(`${diffX * -1 - 100}px`);
      setDeckY(`${diffY * -1}px`);
    }
  }, [deckRef.current]);

  const [showColor, setShowColor] = useState(isGameEnd);
  useEffect(() => {
    if (!showColor && isGameEnd) {
      setTimeout(
        () => {
          setShowColor(isGameEnd);
        },
        dealersHand?.length ? dealersHand?.length - 2 * 600 : 500
      );
    } else {
      setShowColor(isGameEnd);
    }
  }, [isGameEnd]);
  return (
    <>
      <Box>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item style={{ height: "9em" }}>
            {dealersHand ? (
              <>
                <Stack alignItems="center" sx={{ pb: 1 }}>
                  {dealersHand && (
                    <Stack
                      sx={{
                        bgcolor: "#213240",
                        borderRadius: "5px",
                        minWidth: "2.5em",
                        p: 0.5,
                      }}
                    >
                      <Typography
                        color="text.primary"
                        textAlign="center"
                        fontWeight={600}
                      >
                        {dealersHand &&
                          dealersHand?.length !== 0 &&
                          (isTurnEnd
                            ? BlackJackUtilities.getScoreForDisplayDealer(
                                dealersHand
                              )
                            : BlackJackUtilities.getScoreForDisplayDealerBeforeEnd(
                                dealersHand
                              ))}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Grid container direction="row" flexWrap="nowrap">
                  {dealersHand.map((card, index) => {
                    return (
                      <AnimatingDealerCard
                        card={card}
                        index={index}
                        dealersHand={dealersHand}
                        deckX={deckX}
                        deckY={deckY}
                        playDealSound={playDealSound}
                        setIsAnimatingDealer={setIsAnimatingDealer}
                        setAnimatedIndexDealer={setAnimatedIndexDealer}
                        animatedIndexDealer={animatedIndexDealer}
                      />
                    );
                  })}
                </Grid>
              </>
            ) : (
              <div style={{ height: "8.5em" }}></div>
            )}
          </Grid>

          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ p: 1, mt: 2 }}
          >
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              BLACKJACK PAYS 3 TO 2
            </Typography>
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              INSURANCE PAYS 2 TO 1
            </Typography>
          </Stack>

          <Grid item sx={{ pt: 0 }}>
            <Stack direction="row" gap={3} ref={playerCardsRef}>
              {playersHands && playersHands.length ? (
                playersHands.map(
                  (
                    playersHand: BlackJackUtilities.Card[],
                    handIndex: number
                  ) => {
                    let bgColor = "#213240";
                    let color = theme.palette.text.primary;
                    if (
                      isGameEnd &&
                      isFinishDealing &&
                      dealersHand &&
                      showColor
                    ) {
                      bgColor = getBlackJackColor(
                        BlackJackUtilities.judge(dealersHand, playersHand)
                      );
                      color = "";
                    }
                    return (
                      <Stack direction="column" justifyContent="center">
                        <Stack alignItems="center" sx={{ pb: 1 }}>
                          <Stack
                            sx={{
                              bgcolor: bgColor,
                              borderRadius: "5px",
                              minWidth: "2.5em",
                              p: 0.5,
                            }}
                          >
                            <Typography
                              sx={{ color: color }}
                              textAlign="center"
                              fontWeight={600}
                            >
                              {playersHand.length !== 0 &&
                                BlackJackUtilities.getScoreForDisplay(
                                  playersHand
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Grid
                          container
                          direction="row"
                          flexWrap="nowrap"
                          sx={{
                            opacity:
                              handIndex === currentPlayerHandIndex ? 1 : 0.5,
                          }}
                        >
                          {playersHand &&
                            playersHand.map(
                              (
                                card: BlackJackUtilities.Card,
                                index: number
                              ) => (
                                <AnimatingPlayerCard
                                  card={card}
                                  index={index}
                                  dealersHand={dealersHand || []}
                                  playersHand={playersHand}
                                  animatedIndex={animatedIndex}
                                  animatedIndexSplit={animatedIndexSplit}
                                  handIndex={handIndex}
                                  deckX={deckX}
                                  deckY={deckY}
                                  playDealSound={playDealSound}
                                  setAnimatedIndexSplit={setAnimatedIndexSplit}
                                  setAnimatedIndex={setAnimatedIndex}
                                  isGameEnd={isGameEnd}
                                  isFinishDealing={isFinishDealing}
                                  showColor={showColor}
                                />
                              )
                            )}
                        </Grid>
                      </Stack>
                    );
                  }
                )
              ) : (
                <div style={{ minHeight: "8.5em" }}></div>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PlayAreaWrapper;

interface AnimatingPlayerCardProps {
  card: BlackJackUtilities.Card;
  index: number;
  dealersHand: BlackJackUtilities.Card[];
  playersHand: BlackJackUtilities.Card[];
  animatedIndex: number;
  animatedIndexSplit: number;
  handIndex: number;
  deckX: string;
  deckY: string;
  playDealSound: () => void;
  setAnimatedIndexSplit: (index: number) => void;
  setAnimatedIndex: (index: number) => void;
  isGameEnd?: boolean;
  isFinishDealing: boolean;
  showColor?: boolean;
}

const AnimatingPlayerCard: React.FC<AnimatingPlayerCardProps> = ({
  card,
  index,
  dealersHand,
  playersHand,
  animatedIndex,
  animatedIndexSplit,
  handIndex,
  deckX,
  deckY,
  playDealSound,
  setAnimatedIndexSplit,
  setAnimatedIndex,
  isGameEnd,
  isFinishDealing,
  showColor,
}) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const marginLeft = index === 0 ? "0px" : "-50px";
  const marginTop = index === 0 ? "0px" : `${index * 0}px`;
  const border = `3.5px solid ${getBlackJackColor(
    BlackJackUtilities.judge(dealersHand, playersHand)
  )?.replace("!important", "")}`;

  let isHide = animatedIndex >= index ? false : true;
  if (handIndex === 1) {
    isHide = animatedIndexSplit >= index ? false : true;
  }

  return (
    <AnimatedGridItem
      item
      key={index}
      initial={{
        x: deckX,
        y: deckY,
        // marginLeft: marginLeft,
        position: "fixed",
      }}
      onAnimationStart={() => {
        playDealSound();
      }}
      animate={{
        x: 0,
        y: 0,
        // marginLeft: marginLeft,
        transform: "",
        position: "relative",
      }}
      transition={{
        delay: playersHand.length === 2 ? 0 : 0,
        // duration: 0.5,
        duration: 0.5,
      }}
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop,
        zIndex: index + 1,
        // opacity: animatedIndex - 1 < index ? "0%" : "100%",
      }}
      onAnimationComplete={() => {
        if (handIndex === 1) {
          setAnimatedIndexSplit(index);
        } else {
          setAnimatedIndex(index);
        }
        setIsAnimating(false);
      }}
    >
      <Card
        card={card}
        hide={isAnimating ? true : isHide}
        border={isGameEnd && isFinishDealing && showColor ? border : ""}
      />
    </AnimatedGridItem>
  );
};

interface AnimatingDealerCard {
  card: BlackJackUtilities.Card;
  index: number;
  dealersHand: BlackJackUtilities.Card[];
  deckX: string;
  deckY: string;
  playDealSound: () => void;
  isTurnEnd?: boolean;
  setIsAnimatingDealer: (index: boolean) => void;
  setAnimatedIndexDealer: (index: number) => void;
  animatedIndexDealer: number;
}

const AnimatingDealerCard: React.FC<AnimatingDealerCard> = ({
  card,
  index,
  dealersHand,
  deckX,
  deckY,
  playDealSound,
  setIsAnimatingDealer,
  setAnimatedIndexDealer,
  isTurnEnd,
  animatedIndexDealer,
}) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const marginLeft = index === 0 ? "0px" : "-50px";
  const marginTop = index === 0 ? "0px" : `${index * 0}px`;
  const hide = index === 1 && !isTurnEnd;
  return (
    <AnimatedGridItem
      item
      key={index}
      initial={{
        opacity: 1,
        x: deckX,
        y: deckY,
      }}
      onAnimationStart={() => {
        playDealSound();
        setIsAnimatingDealer(true);
      }}
      animate={{ opacity: 1, x: 0, y: 0, position: "relative" }}
      transition={{
        delay: dealersHand.length === 2 ? 0 : 0,
        duration: 0.5,
      }}
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop,
        zIndex: index + 1,
        overflow: "visible",
      }}
      onAnimationComplete={() => {
        setIsAnimatingDealer(false);
        setAnimatedIndexDealer(index);
        setIsAnimating(false);
      }}
    >
      <Card
        card={card}
        hide={
          isAnimating
            ? isAnimating
            : animatedIndexDealer >= index && card.rank !== "U"
            ? false
            : hide
        }
      />
    </AnimatedGridItem>
  );
};
