import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import mineReducer from "../slices/mineSlice";
import chatReducer from "../slices/chatSlice";
import orderReducer from "../slices/orderSlice";
import notificationReducer from "../slices/notificationSlice";
import diceReducer from "../slices/diceSlice";
import transactionReducer from "../slices/transactionSlice";
import limboReducer from "../slices/limboSlice";
import slideReducer from "../slices/slideSlice";
import coinFlipReducer from "../slices/coinFlipSlice";
import plinkoReducer from "../slices/plinkoSlice";
import kenoReducer from "../slices/kenoSlice";
import duelReducer from "../slices/duelSlice";
import gameFeedReducer from "../slices/gameFeeds";
import wagerReducer from "../slices/wagerSlice";
// Import the Blackjack store
import blackJackReducer from "../slices/blackJackslice";
import hiloReducer from "../slices/hiloslice";
import slotReducer from "../slices/slotsSlice";
import challengesReducer from "../slices/challengesSlice";

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    user: userReducer,
    mine: mineReducer,
    chat: chatReducer,
    order: orderReducer,
    dice: diceReducer,
    transaction: transactionReducer,
    limbo: limboReducer,
    slide: slideReducer,
    coinFlip: coinFlipReducer,
    plinko: plinkoReducer,
    keno: kenoReducer,
    duel: duelReducer,
    gameFeed: gameFeedReducer,
    wager: wagerReducer,
    blackJack: blackJackReducer,
    hilo: hiloReducer,
    slots: slotReducer,
    challenges: challengesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
