import { Stack, IconButton, useMediaQuery, Typography } from "@mui/material";
import { theme } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ORDERTYPES } from "../../types/index.d";
import { Button } from "../button";

interface IModal {
  buttonData: any[];
  changeModalType: (type: any) => void;
  modalType: string;
  isBackButton?: boolean;
  handleBack?: () => void;
  orderType?: ORDERTYPES;
}

const OrderTitles: Partial<Record<ORDERTYPES, string>> = {
  [ORDERTYPES.PROMOCODE]: "Redeem Promo Code",
  [ORDERTYPES.REDEEMGIFT]: "Redeem Gift Card",
  [ORDERTYPES.KINGUIN]: "Purchase Giftcards on Kinguin",
};

const SelectionComponent = ({
  changeModalType,
  buttonData,
  modalType,
  isBackButton,
  handleBack,
  orderType,
}: IModal) => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  let title;
  if (orderType) {
    title = OrderTitles[orderType];
  }
  return (
    <>
      <Stack direction="row" gap={{ md: 2, xs: 0.5 }} sx={{ width: "100%" }}>
        {isBackButton && handleBack && (
          <IconButton
            sx={{
              position: "absolute",
              transform: matchesMD
                ? "translate(-120%,0%)"
                : "translate(-100%,0%)",
              color: theme.palette.text.primary,
            }}
            onClick={() => handleBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        {title && isBackButton ? (
          <Stack alignItems="center" sx={{ width: "100%" }}>
            <Typography
              color="text.primary"
              variant={matchesMD ? "h4" : "h6"}
              fontWeight={600}
            >
              {title}
            </Typography>
          </Stack>
        ) : null}
        {!isBackButton &&
          buttonData.map((button) => (
            <Button
              sx={{
                width: "100px",
                fontWeight: 400,
                p: 1,
                borderRadius: "5px",
              }}
              variantType={modalType === button.type ? "selected" : "selection"}
              onClick={() => changeModalType(button.type)}
            >
              {button.label}
            </Button>
          ))}
      </Stack>
    </>
  );
};

export default SelectionComponent;
