import React, { useRef, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./index.module.scss";
import LevelUpBlue from "../../assets/banner/Level_Up_Rewards_Blue.webm";
import RakeBackBanner from "../../assets/banner/rakeback.png";
import WagerRaceBanner from "../../assets/banner/wagerRace.png";
import SlotsBanner from "../../assets/banner/slots.png";
import AffiliateBanner from "../../assets/banner/affiliate.png";
// import LevelUpGold from "../../assets/banner/Level_Up_Rewards_Gold.webm";
// import LevelUpRed from "../../assets/banner/Level_Up_Rewards_Red.webm";
// import LevelUpSilver from "../../assets/banner/Level_Up_Rewards_Silver.webm";
// import AffiliateGreen from "../../assets/banner/Affiliate_Banner.webm";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { theme } from "../theme";
import ReactPlayer from "react-player";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
export const bannerImages = [
  // {
  //   video: LevelUpGold,
  //   url: "/rakeback",
  // },

  {
    img: RakeBackBanner,
    url: "/rakeback",
  },
  {
    img: WagerRaceBanner,
    url: "/wager-race",
  },
  {
    img: AffiliateBanner,
    url: "/affiliate",
  },
  {
    img: SlotsBanner,
    url: "/slots",
  },
];

const SwipperSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
        className={styles.swiper}
      >
        {bannerImages.map((each, index) => (
          <SwiperSlide>
            <Link to={each.url}>
              <img
                src={each.img}
                alt={`Banner-${index}`}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "4px",
                }}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export const Banner = () => {
  // const carouselRef = useRef<any>(null);
  // const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Stack direction="row" gap={2}>
      <SwipperSlider />
    </Stack>
    // <Carousel
    //   className={styles.carouselContainer}
    //   showStatus={false}
    //   showThumbs={false}
    //   ref={carouselRef}
    //   onChange={(i: number) => setCurrentIndex(i)}
    //   // dynamicHeight
    //   selectedItem={currentIndex}
    // >
    //   {bannerImages.map((each, index) => (
    //     <Link to={each.url}>
    //       <Stack
    //         sx={{
    //           pointerEvents: "none",
    //           // width: "1100px !important",
    //           // height: "350px !important",
    //         }}
    //       >
    //         <img
    //           src={each.img}
    //           alt={`Banner-${index}`}
    //           style={{
    //             width: "50%",
    //             // height: "350px",
    //             height: "auto",
    //             borderRadius: "4px",
    //           }}
    //         />
    //         {/* <VideoElement
    //           each={each.video}
    //           index={index}
    //           setCurrentIndex={setCurrentIndex}
    //           currentIndex={currentIndex}
    //         /> */}
    //       </Stack>
    //     </Link>
    //   ))}
    // </Carousel>
  );
};

const VideoElement = ({ each, index, setCurrentIndex, currentIndex }: any) => {
  const videoRef = useRef<any>(null);
  const intervalRef = useRef<any>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (currentIndex === index) {
      const videoElement = videoRef.current as any;
      if (videoElement) {
        videoElement.seekTo(0, "seconds");
        const vid = videoElement.getInternalPlayer();
        if (vid) {
          vid.play();
        }
        setPlaying(true);
      }
    }
  }, [currentIndex, index, videoRef]);

  const handleEnd = () => {
    if (currentIndex === index) {
      if (bannerImages.length > currentIndex + 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        intervalRef.current = setTimeout(() => {
          setCurrentIndex(0);
        }, 5000);
      }
    }
  };
  useEffect(() => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
  }, [currentIndex]);
  return (
    <ReactPlayer
      key={index}
      autoPlay
      playing={playing}
      muted
      style={{
        pointerEvents: "none",
        // width: "100%",
        // height: "auto",
        borderRadius: "4px",
      }}
      playsinline
      ref={videoRef}
      id={`banner-video-${index}`}
      // style={{ pointerEvents: "none" }}
      url={each}
      onEnded={() => {
        handleEnd();
      }}
    />
  );
};
