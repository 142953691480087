import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Transaction from "../../services/transaction";
import { RootState } from "../store";
import Slots from "../../services/slots";

export interface ISlotGame {
  title: string;
  identifier: string;
  provider: string;
  category: string;
  feature_group: string;
  freespins_available: boolean;
  devices: string[];
  bet_type: string;
  api_version: string;
  newImgIdentifier: string;
  replaceIdentifier?: boolean;
}

export interface IRecord {
  userId: string;
  totalWagered: number;
  userName: string;
}
export interface IWinner {
  userId: string;
  userName: string;
  level: number;
  rank: number;
  wagered: number;
}

interface WagerState {
  errorMessage: string;
  slotGames: ISlotGame[];
  bgamingOri: ISlotGame[];
  loading: boolean;
}

const initialState: WagerState = {
  slotGames: [],
  bgamingOri: [],
  errorMessage: "",
  loading: false,
};

export const orderForSlots = [
  "SnoopDoggDollars",
  "Forgotten",
  "AztecClusters",
  "AlienFruits2",
  "VoodooPeople",
  "Gemhalla",
  "DiceBonanza",
  "CatchTheGoldHoldAndWin",
  "SweetRushMegaways",
  "KrakensHunger",
  "HauntedReels",
  "HalloweenBonanza",
  "FruitMillion",
  "CandyMonsta",
  "CarnivalBonanza",
  "WildWestTrueways",
  "TrainToRioGrande",
  "Gangsterz",
  "DigDigDigger",
  "Lucky8MergeUp",
  "PotionSpells",
  "AlienFruits",
  "GoldOfMinos",
  "BeerBonanza",
  "LuckyCrew",
  "PennyPelican",
  "LuckyFarmBonanza",
  "PopZen",
  "BonanzaBillion",
  "MiceAndMagic",
  "Gemza",
  "GoldRushWithJohnny",
  "TrampDay",
  "ChickenRush",
  "CloverBonanza",
  "BookOfPanda",
  "MergeUp",
  "BookOfCatsMegaways",
  "BookOfCats",
  "FishingClub",
  "Oof",
  "AlohaKingElvis",
  "LuckyDamaMuerta",
  "DragonAge",
  "Road2Riches",
  "WbcRingOfRiches",
  "RoyalHighRoad",
  "AztecMagicBonanza",
  "JohnnyCash",
  "MissCherryFruitsJackpotparty",
  "JokerQueen",
  "BookOfKemet",
  "MonsterHunt",
  "Avalon",
  "DiamondOfJungle",
  "ElvisFrogTrueways",
  "WildHeart",
  "WildChicago",
  "BigAtlantisFrenzy",
  "BoneBonanza",
  "DeepSea",
  "Maneki88Gold",
  "SavageBuffaloSpiritMegaways",
  "SavageBuffaloSpirit",
  "Maneki88Fortunes",
  "MissCherryFruits",
  "EasterHeist",
  "LuckyLadyMoon",
  "LuckyLadyMoonMegaways",
  "LuckAndMagic",
  "HitTheRoute",
  "JourneyFlirt",
  "PandaLuck",
  "BeastBand",
  "SpinAndSpell",
  "GiftRush",
  "LuckyDragonMultiDiceX",
  "Hottest666",
  "Soccermania",
  "WildCash",
  "MummysGold",
  "Lucky Dragon",
  "CherryFiesta",
  "AllLuckyClover",
  "AllLuckyClover5",
  "AllLuckyClover20",
  "AllLuckyClover40",
  "AllLuckyClover100",
  "FourLuckyClover",
  "SlotMachine",
  "LuckyOak",
  "MechanicalClover",
  "MechanicalOrange",
  "FortyFruityMillion",
  "DragonsGold100",
  "WildCashX9990",
  "GoldMagnate",
  "FourLuckyDiamonds",
  "WildTiger",
  "GodOfWealthHoldAndWin",
  "BurningChilliX",
  "DomnitorsTreasure",
  "PrincessOfSky",
  "WestTown",
  "WildCashDice",
  "BobsCoffeeShop",
  "CrazyStarter",
  "LuckyLadyClover",
  "DomnitorsDeluxe",
  "LuckyBlue",
  "ScrollOfAdventure",
  "HawaiiCocktails",
  "SecretBarMultiDiceX",
  "FireLightning",
  "BookOfPyramids",
  "DiceMillion",
  "LuckySweets",
  "AztecMagic",
  "DesertTreasure",
  "BraveViking",
  "FantasyPark",
  // "PrincessOfSky",
  "AztecMagicDeluxe",
  "SlotomonGo",
  "PlatinumLightning",
  "PlatinumLightningDeluxe",
  "HelloEaster",
  "PrincessRoyal",
  "JogoDoBicho",
  // "DiceMillion",
  "LuckAndMagicScratch",
  "ThreeKingsScratch",
  "ScratchAlpacaBronze",
  "ScratchAlpacaSilver",
  "ScratchAlpacaGold",
  "IceScratchBronze",
  "IceScratchSilver",
  "IceScratchGold",
  "CapymaniaOrange",
  "CapymaniaGreen",
  "CapymaniaYellow",
  "ScratchDice",
];

const orderForBgamingOri = [
  "Aviamasters",
  "SpaceXY",
  "EuropeanRoulette",
  "FishingClub",
  "MineGems",
  "StreetPower",
  "MinesweeperXY",
];

const hardCodeIds = [
  {
    identifier: "ElvisFrog",
    newImgIdentifier: "ElvisFroginVegas",
    disabled: false,
    replaceIdentifier: false,
  },
  {
    identifier: "HeadsTailsXY",
    newImgIdentifier: "HeadsandTailsXY",
    disabled: false,
    replaceIdentifier: false,
  },
  {
    identifier: "ZorroWildHeart",
    disabled: true,
    replaceIdentifier: false,
  },
  {
    identifier: "Adventures",
    disabled: true,
    replaceIdentifier: false,
  },
  {
    identifier: "acceptance:test",
    disabled: true,
    replaceIdentifier: false,
  },
  {
    identifier: "DiceClash",
    disabled: true,
    replaceIdentifier: false,
  },
  {
    identifier: "CandyStarz",
    disabled: true,
    replaceIdentifier: false,
  },
];

export const getSlotGames = createAsyncThunk(
  "slot/getGames",
  async (_, thunkAPI) => {
    try {
      const games = await Slots.get();
      return games?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Slice definition
export const slotsReducer = createSlice({
  name: "slots",
  initialState,
  reducers: {
    // updateWager(state, action: PayloadAction<IWager>) {
    //   state.currentWager = action.payload;
    // },
    updateErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlotGames.fulfilled, (state, action) => {
        const updatedArr = [
          ...action.payload
            .map((game: ISlotGame) => {
              const needsHardCode = hardCodeIds.find(
                (item) => item.identifier === game.identifier
              );
              if (needsHardCode) {
                if (needsHardCode.disabled === true) {
                  return null;
                }

                {
                  return {
                    ...game,
                    newImgIdentifier: needsHardCode?.newImgIdentifier,
                    idetifier: needsHardCode?.replaceIdentifier
                      ? needsHardCode?.newImgIdentifier
                      : game.identifier,
                  };
                }
              }

              return game;
            })
            .filter((game: ISlotGame) => game !== null),
        ];
        state.bgamingOri = [
          ...orderForBgamingOri.map((identifier) =>
            updatedArr.find((game: ISlotGame) => game.identifier === identifier)
          ),
        ];
        state.slotGames = [
          ...orderForSlots
            .map((identifier) =>
              updatedArr.find(
                (game: ISlotGame) => game.identifier === identifier
              )
            )
            .filter(Boolean),
          ...updatedArr.filter(
            (game: ISlotGame) => !orderForSlots.includes(game.identifier)
          ),
        ];
        state.loading = false;
      })
      .addCase(getSlotGames.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(getSlotGames.pending, (state) => {
        state.loading = true;
      });
  },
});

// Expose the actions
export const { updateErrorMessage } = slotsReducer.actions;

// Selectors
export const selectSlotGames = (state: RootState) => state.slots.slotGames;
export const selectBgamingOri = (state: RootState) => state.slots.bgamingOri;
export const selectLoading = (state: RootState) => state.slots.loading;

// Reducer
export default slotsReducer.reducer;
