import { FC, ChangeEvent, useState, useRef, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
} from "@mui/material";
import { format } from "date-fns";
import { TokenIcon } from "../../components/tokenIcon";
import { IGameFeed, TOKENTYPE } from "../../types/index.d";
import {
  RoundNumberCondition,
  RoundNumberFourDecimalPoints,
} from "../../utils";
import { parseHiddenUsername } from "../../constants";
import { selectCurrentUser, selectUserType } from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { isModUser } from "../chat/textBubble";
import { GAMES } from "../../constants/games";

function checkStringLength(inputString: string) {
  if (inputString.length > 15) {
    return inputString.slice(0, 15) + "...";
  }
  return inputString;
}

const FeedTable = ({ feed, isEven }: { feed: IGameFeed[]; isEven: number }) => {
  const userType = useAppSelector(selectUserType);
  const isAdmin = isModUser(userType);
  return (
    <Card sx={{ my: 3 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#273F55" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                }}
              >
                Game
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                }}
                align="left"
              >
                User
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                  display: { md: "table-cell", xs: "none" },
                }}
              >
                Time
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                  display: { md: "table-cell", xs: "none" },
                }}
              >
                Bet
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                  display: { md: "table-cell", xs: "none" },
                }}
              >
                Multiplier
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  backgroundColor: "#213240",
                  borderColor: "#273F55",
                }}
              >
                Payout
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feed &&
              feed.map((promo, index) => {
                if (index >= 10) {
                  return null;
                }
                return (
                  <TableRow
                    key={promo._id}
                    style={{
                      backgroundColor:
                        isEven === index % 2 ? "#1C2B39" : "#213240",
                    }}
                  >
                    <TableCell sx={{ borderColor: "#273F55" }}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {promo.game === GAMES.SLOTS && promo?.secondaryName
                          ? checkStringLength(promo.secondaryName)
                          : promo.game.replaceAll("_", " ")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderColor: "#273F55" }} align="left">
                      <Typography
                        variant="body1"
                        color={promo.isAnonymity ? "gray" : "text.primary"}
                        gutterBottom
                        noWrap
                      >
                        {parseHiddenUsername(promo.userName, isAdmin)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "#273F55",
                        display: { md: "table-cell", xs: "none" },
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {format(
                          promo.createdAt
                            ? new Date(promo.createdAt)
                            : new Date(),
                          "pp"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "#273F55",
                        display: { md: "table-cell", xs: "none" },
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <TokenIcon
                          freeCash={promo?.tokenType === TOKENTYPE.FREE}
                        />
                        <Typography variant="body1" color="text.primary" noWrap>
                          {promo.bet.toFixed(2)}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "#273F55",
                        display: { md: "table-cell", xs: "none" },
                      }}
                    >
                      <Typography variant="body1" color="text.primary" noWrap>
                        {promo.multiplier}x
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderColor: "#273F55",
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <TokenIcon
                          freeCash={promo?.tokenType === TOKENTYPE.FREE}
                        />
                        <Typography
                          variant="body1"
                          color={
                            promo.payout > promo.bet
                              ? "#39e639"
                              : "text.primary"
                          }
                          noWrap
                        >
                          {promo.payout}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default FeedTable;
