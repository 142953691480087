import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Users from "../../services/user";

export interface ChallengesDocument {
  _id: string;
  game: string;
  multiplier: number;
  minBet: number;
  reward: number;
  tokenType: string;
  active: boolean;
  isSlot: boolean;
  winnerUserName?: string;
  createdAt: string;
}

interface ChallengesState {
  challenges: ChallengesDocument[] | null;
  loading: boolean;
  errorMessage: string;
}

const initialState: ChallengesState = {
  challenges: null,
  loading: false,
  errorMessage: "",
};

export const getAllChallenges = createAsyncThunk(
  "user/getChallenges",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Users.getChallenges(accessToken);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Slice definition
export const challengesReducer = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    updateChallenges(state, action: PayloadAction<ChallengesDocument[]>) {
      state.errorMessage = "";
      state.challenges = [...action.payload];
    },
    updateChallengesErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChallenges.fulfilled, (state, action) => {
        if (action.payload) {
          state.challenges = action.payload.challenges;
        }
        state.loading = false;
      })
      .addCase(getAllChallenges.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllChallenges.pending, (state) => {
        state.loading = true;
      });
  },
});

// Expose the actions
export const { updateChallenges, updateChallengesErrorMessage } =
  challengesReducer.actions;

// Selectors
export const selectChallenges = (state: RootState) =>
  state.challenges.challenges;

// Reducer
export default challengesReducer.reducer;
