import {
  Container,
  Stack,
  Typography,
  Card,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  calimReferralReward,
  selectClaimLoading,
  selectCurrentUser,
  selectToken,
} from "../../components/slices/userSlice";
import { AppDispatch } from "../../components/store";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Button } from "../../components/button";
import ReferralTable from "./referralTable";
import { InputFieldStyled } from "../../components/inputField";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { TokenIcon } from "../../components/tokenIcon";
import SimpleTable from "../../components/simpleTable";
import { theme } from "../../components/theme";
import HCaptcha from "../../components/HCaptcha";
import { useTurnstile } from "react-turnstile";
import Users from "../../services/user";

const Referral = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(selectToken);
  const user = useSelector(selectCurrentUser);
  const loading = useSelector(selectClaimLoading);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const [message, setMessage] = useState("");
  const [token, setToken] = useState<any>(null);
  const turnstile = useTurnstile();
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [isError, setIsError] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  useEffect(() => {
    // get all referrals
  }, [accessToken]);
  const currentURL = window.location.protocol + "//" + window.location.host;
  const referralEndpoint = `${currentURL}/r/${user.userName}`;

  const copyLink = () => {
    navigator.clipboard
      .writeText(referralEndpoint)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const handleClaim = () => {
    dispatch(calimReferralReward());
  };

  const handlePrmoCode = async () => {
    if (!token) {
      setIsError(true);
      setMessage("Please complete the HCaptcha");
      return;
    }

    try {
      setIsError(false);
      setLoadingPromo(true);
      const res = await Users.promoCode(accessToken, promoCode, token);
      if (res) {
        setIsError(false);
        setMessage(res.message);
      }
    } catch (e: any) {
      setMessage(e.message);
      setIsError(true);
    } finally {
      setLoadingPromo(false);
    }
  };

  useEffect(() => {
    if (message && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [message]);

  return (
    <Container maxWidth="md">
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Affiliate System
        </Typography>
        <Stack gap={3}>
          <Stack
            direction={{ md: "row", sm: "column" }}
            sx={{ width: "100%" }}
            gap={2}
          >
            <Stack
              sx={{ p: 2, pb: 0, pl: 0, width: { md: "50%", sm: "100%" } }}
              gap={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="text.primary" variant="h6" noWrap>
                  {matchesMD ? "Invite Link" : "URL"}
                </Typography>

                <Stack
                  direction="row"
                  sx={{ width: { md: "75%", sm: "80%", xs: "80%" } }}
                >
                  <InputFieldStyled
                    fullWidth
                    value={referralEndpoint}
                    disabled={true}
                  />
                  <IconButton onClick={copyLink} sx={{ color: "white" }}>
                    <ContentCopyTwoToneIcon />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                justifyContent="space-between"
                direction="column"
                gap={2}
                sx={{ height: "100%" }}
              >
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Level:
                  </Typography>
                  <Typography color="text.primary" variant="h6">
                    {user?.referredLevel || 1}
                  </Typography>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Claimable Rewards:
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <TokenIcon />
                    <Typography color="text.primary" variant="h6">
                      {user.availableReferralTokens}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Total Rewards:
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <TokenIcon />
                    <Typography color="text.primary" variant="h6">
                      {user.totalReferralTokens?.toFixed(2)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography color="text.primary" variant="h6">
                    Total Referred:
                  </Typography>
                  <Typography color="text.primary" variant="h6">
                    {user?.referred?.length || 0}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={2}>
                  <Button
                    variantType="success"
                    fullWidth
                    onClick={handleClaim}
                    disabled={user.availableReferralTokens === 0 || loading}
                  >
                    Claim
                  </Button>
                  <Button variantType="success" fullWidth onClick={copyLink}>
                    Copy link
                  </Button>
                </Stack>
                <Card sx={{ p: 2 }}>
                  <Stack gap={0.5}>
                    <Typography color="text.primary" variant="h6">
                      Promo Code
                    </Typography>
                    <Stack direction="row" gap={1} sx={{ mb: 1 }}>
                      <InputFieldStyled
                        type={"string"}
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        sx={{
                          width: { sm: "100%", xs: "100%" },
                        }}
                      />
                      <Button
                        onClick={() => handlePrmoCode()}
                        variantType={"success"}
                        disabled={!promoCode || loadingPromo}
                      >
                        Submit
                      </Button>
                    </Stack>
                    <HCaptcha onVerify={setToken} forceNormal />
                    {message && (
                      <Typography
                        sx={{
                          color: isError
                            ? theme.palette.error.main
                            : theme.palette.success.main,
                        }}
                      >
                        {message}
                      </Typography>
                    )}
                  </Stack>
                </Card>
              </Stack>
            </Stack>
            <Card sx={{ p: 2, width: { md: "50%", sm: "100%" } }}>
              <Stack>
                <SimpleTable data={data} />
              </Stack>
            </Card>
          </Stack>
          {user.referred && <ReferralTable data={user.referred} />}
        </Stack>
      </Stack>
    </Container>
  );
};

const data = [
  ["1", "Start", "10%"],
  ["2", "Earned between [TOKEN] 25,000 - 100,000", "12.5%"],
  ["3", "Earned between [TOKEN] 100,000 - 200,000", "15%"],
  ["4", "Earned at least [TOKEN] 200,000", "20%"],
  ["5", "Earned at least [TOKEN] 500,000", "25%"],
  ["6", "Earned at least [TOKEN] 1,000,000", "30%"],
];

export default Referral;
