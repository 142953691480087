import {
  Box,
  Typography,
  Container,
  Stack,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import MaintenanceSVG from "../../assets/runewager.svg";
import MaintenanceMobileSVG from "../../assets/runewagerMobile.svg";
import { Button } from "../../components/button";
import { selectBuildVersion } from "../../components/slices/userSlice";
import { useAppSelector } from "../../components/store/hooks";
import { theme } from "../../components/theme";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function StatusMaintenance({ buildRef }: { buildRef: number }) {
  const builVersion = useAppSelector(selectBuildVersion);
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <MainContent sx={{ overflow: "hidden", minHeight: "84vh" }}>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Container maxWidth="md">
              <Stack gap={4} alignItems="center">
                <Typography
                  variant={matchesSM ? "h3" : "h5"}
                  color="text.primary"
                >
                  {builVersion === buildRef
                    ? "Runewager is currently under maintenance. We are working hard to improve our systems."
                    : "Runewager has been updated, please refresh your page"}
                </Typography>
                {builVersion === buildRef ? (
                  <Typography
                    variant={matchesSM ? "h3" : "h5"}
                    fontWeight="normal"
                    color="text.primary"
                  >
                    Check the{" "}
                    <a
                      href="https://discord.gg/Runewagers"
                      style={{ color: "#33EC3D" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Discord
                    </a>{" "}
                    for the latest information
                  </Typography>
                ) : (
                  <Button
                    variantType="success"
                    sx={{ width: "25%" }}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh
                  </Button>
                )}

                <img
                  alt="Maintenance"
                  style={{ width: "50%" }}
                  src={matchesSM ? MaintenanceSVG : MaintenanceMobileSVG}
                />
              </Stack>
            </Container>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default StatusMaintenance;
