import { Card, IconButton, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../store/hooks";
import { selectOpenOrder, updateStatus } from "../slices/orderSlice";
import QRCode from "react-qr-code";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../slices/userSlice";
import { ORDERSTATUS } from "../../types/index.d";
import { AppDispatch } from "../store";
import { ConfirmationDialog } from "../confirmationModal";
import { Button } from "../button";
import { CryptoOptions, getOrderData } from "../../constants/exchange";
import { TokenIcon } from "../tokenIcon";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { theme } from "../theme";

interface CountdownTimerProps {
  createdAt: string;
  expirationEstimateDate: string;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  createdAt,
  expirationEstimateDate,
}) => {
  const calculateTimeLeft = (): TimeLeft | null => {
    const expirationTime = new Date(expirationEstimateDate).getTime();
    const createdTime = new Date(createdAt).getTime();
    const now = new Date().getTime();

    // Adjust the current time relative to the createdAt time.
    const elapsedTime = now - createdTime;
    const remainingTime = expirationTime - (createdTime + elapsedTime);

    if (remainingTime > 0) {
      return {
        days: Math.floor(remainingTime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((remainingTime / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((remainingTime / (1000 * 60)) % 60),
        seconds: Math.floor((remainingTime / 1000) % 60),
      };
    }

    return null;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(
    calculateTimeLeft()
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, createdAt, expirationEstimateDate]);

  return (
    <div>
      {timeLeft ? (
        <Stack direction="row">
          <Typography color="text.primary">
            {timeLeft.days > 0 ? `${timeLeft.days}d ` : ""}
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </Typography>
        </Stack>
      ) : (
        <Typography color="text.primary">Expired</Typography>
      )}
    </div>
  );
};

export const CryptoDepositModal = () => {
  const openOrder = useAppSelector(selectOpenOrder);
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState(false);

  const handleCancelOrder = () => {
    if (openOrder) {
      setOpenModal(false);

      dispatch(
        updateStatus({ id: openOrder._id, newStatus: ORDERSTATUS.CANCELLED })
      );
    }
  };

  const copyLink = () => {
    navigator.clipboard
      .writeText(openOrder?.pay_address || "")
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };
  const copyAmount = () => {
    navigator.clipboard
      .writeText(`${openOrder?.pay_amount}` || "")
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  useEffect(() => {
    if (!openOrder?.payment_status) {
      dispatch(closeModal());
    }
  }, [openOrder]);

  let getSelectionData = CryptoOptions.find((option) =>
    option?.selection?.find(
      (selection) => selection.symbol === openOrder.cryptoType
    )
  );

  let getSelectedSelectionData: any;

  if (getSelectionData) {
    getSelectedSelectionData = getSelectionData?.selection?.find(
      (selection) => selection.symbol === openOrder.cryptoType
    );
  }

  if (!getSelectionData) {
    getSelectedSelectionData = CryptoOptions?.find(
      (crypto) => crypto.text.toLocaleLowerCase() === openOrder.cryptoType
    );
  }

  return (
    <>
      <ConfirmationDialog
        handleConfirm={handleCancelOrder}
        open={openModal}
        handleCancel={() => setOpenModal(false)}
        text="Are you sure you want to cancel?"
        title={`${getOrderData(openOrder?.type || "")?.label || ""} - ${
          openOrder?.amount || ""
        }m - ${openOrder?.tokens || ""} Tokens - ${
          openOrder?.inGameUserName || ""
        }`}
      />
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" color="text.primary">
            Crypto Purchase
          </Typography>

          <Typography color="text.primary" sx={{ textTransform: "capitalize" }}>
            {openOrder.payment_status}
          </Typography>
        </Stack>

        <Stack gap={0.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="text.primary">
              Payment Currency: {openOrder.pay_currency?.toLocaleUpperCase()}
            </Typography>
            <Stack>
              {openOrder?.expiration_estimate_date &&
                openOrder.order_created_at && (
                  <CountdownTimer
                    createdAt={openOrder.order_created_at}
                    expirationEstimateDate={openOrder.expiration_estimate_date}
                  />
                )}
            </Stack>
          </Stack>

          <Typography color="text.primary" sx={{ mt: 1 }}>
            Estimate Receive:
          </Typography>

          <Card sx={{ p: 1, bgcolor: "#213240" }}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Stack sx={{ width: "50%" }}>
                <Typography
                  color="text.primary"
                  sx={{ fontSize: { xs: "10px", md: "14px" } }}
                >
                  Sweep Cash
                </Typography>
                <Stack direction="row" gap={0.25}>
                  <TokenIcon />
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: { xs: "10px", md: "14px" } }}
                  >
                    {openOrder.tokens}
                  </Typography>
                </Stack>
              </Stack>
              <Stack sx={{ width: "50%" }}>
                <Typography
                  color="text.primary"
                  sx={{ fontSize: { xs: "10px", md: "14px" } }}
                >
                  Fun Cash
                </Typography>
                <Stack direction="row" gap={0.25}>
                  <TokenIcon freeCash />
                  <Typography
                    color="text.primary"
                    sx={{ fontSize: { xs: "10px", md: "14px" } }}
                  >
                    {openOrder.freeToken}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Typography color="text.primary" sx={{ mt: 1 }}>
            Deposit Address:
          </Typography>

          <Card sx={{ p: 1, bgcolor: "#213240" }}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography
                color="text.primary"
                sx={{ fontSize: { xs: "10px", md: "14px" } }}
              >
                {openOrder.pay_address}
              </Typography>
              <IconButton onClick={copyLink} sx={{ color: "white" }}>
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Stack>
          </Card>

          <Typography color="text.primary" sx={{ mt: 1 }}>
            Payment Amount:
          </Typography>

          <Card sx={{ p: 1, bgcolor: "#213240" }}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography
                color="text.primary"
                sx={{ fontSize: { xs: "10px", md: "14px" } }}
              >
                {openOrder.pay_amount}
              </Typography>
              <IconButton onClick={copyAmount} sx={{ color: "white" }}>
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Stack>
          </Card>

          <Stack sx={{ p: 1 }} gap={1} alignItems="center">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "60%", width: "60%" }}
              value={openOrder.pay_address || ""}
              viewBox={`0 0 40 40`}
            />
            {getSelectedSelectionData && getSelectionData && (
              <Typography color="text.primary">
                Only send {getSelectionData.text} on{" "}
                {getSelectedSelectionData.network} to this address
              </Typography>
            )}
            {getSelectedSelectionData && !getSelectionData && (
              <Typography color="text.primary">
                Only send {getSelectedSelectionData.text} to this address,{" "}
                {(getSelectedSelectionData.text === "BTC" ||
                  getSelectedSelectionData.text === "LTC") &&
                  "shortly after 1 confirmation you will be credited"}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <WarningAmberIcon sx={{ color: theme.palette.text.primary }} />
            <Typography color="text.primary">
              The amount you receive is subject to real-time exchange rate and
              actual send amount at the time of arrival
            </Typography>
          </Stack>

          <Button
            fullWidth
            variantType="error"
            onClick={() => setOpenModal(true)}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
