import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { Button as MuiButton } from "@mui/material";
import { theme } from "../theme";
import { styled } from "@mui/material/styles";

const BootstrapButton = styled(MuiButton)({
  color: "white",
  fontWeight: 400,
  outline: "none",
  "&:hover": {
    backgroundColor: "#273F55",
    borderColor: "#0062cc",
    boxShadow: "none",
    outline: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
    outline: "none",
  },
  "&:focus": {
    backgroundColor: "#273F55",
    boxShadow: "none",
    outline: "none",
  },
});

const BootstrapButtonSec = styled(MuiButton)({
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#1F68AA",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#273F55",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const BootstrapButtonSuccess = styled(MuiButton)({
  color: theme.palette.primary.main,
  fontWeight: "600",
  boxShadow: "0 5px 15px rgba(145, 92, 182, .1)",
  outline: "none",
  "&:hover": {
    backgroundColor: "#39e639",
    borderColor: "#0062cc",
    boxShadow: "0 5px 15px #32c932",
    outline: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
    outline: "none",
  },
  "&:focus": {
    backgroundColor: "#39e639",
    boxShadow: "none",
    outline: "none",
  },
});

const BootstrapButtonError = styled(MuiButton)({
  color: theme.palette.text.primary,
  fontWeight: "600",
  boxShadow: "0 5px 15px rgba(145, 92, 182, .1)",
  "&:hover": {
    backgroundColor: "rgba(211, 47, 47,1)",
    boxShadow: "0 5px 15px rgba(235, 106, 106, .5)",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const BootstrapButtonDark = styled(MuiButton)({
  color: theme.palette.text.primary,
  fontWeight: "600",
  boxShadow: "0 5px 15px rgba(145, 92, 182, .1)",
  "&:hover": {
    borderColor: "#0062cc",
    boxShadow: "0 5px 15px rgba(92, 182, 92, .5)",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  "&:disabled": {
    color: "grey",
  },
});

const BootstrapButtonSelection = styled(MuiButton)({
  color: theme.palette.text.primary,
  boxShadow: "0 5px 15px rgba(41, 41, 41, .1)",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#213240",
    borderColor: "#0062cc",
    boxShadow: "0 5px 15px rgba(41, 41, 41, .2)",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf",
  },
  "&:disabled": {
    color: "grey",
  },
});

const BootstrapButtonSelected = styled(MuiButton)({
  color: "#33EC3D",
  boxShadow: "0 5px 15px rgba(41, 41, 41, .2)",
  p: 0,
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "#273F55",
    borderColor: "#0062cc",
    boxShadow: "0 5px 15px rgba(41, 41, 41, .1)",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf",
  },
  "&:disabled": {
    color: "grey",
  },
});

interface ButtonProps extends MuiButtonProps {
  variantType?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "dark"
    | "selection"
    | "selected";
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variantType = "primary",
  sx,
  ...rest
}) => {
  if (variantType === "primary") {
    return (
      <BootstrapButton sx={{ backgroundColor: "#213240", ...sx }} {...rest}>
        {children}
      </BootstrapButton>
    );
  }

  if (variantType === "success") {
    return (
      <BootstrapButtonSuccess
        sx={{ backgroundColor: "#32c932", ...sx }}
        {...rest}
      >
        {children}
      </BootstrapButtonSuccess>
    );
  }

  if (variantType === "dark") {
    return (
      <BootstrapButtonDark sx={{ backgroundColor: "black", ...sx }} {...rest}>
        {children}
      </BootstrapButtonDark>
    );
  }

  if (variantType === "error") {
    return (
      <BootstrapButtonError
        sx={{ backgroundColor: "rgba(211, 47, 47,1)", ...sx }}
        {...rest}
      >
        {children}
      </BootstrapButtonError>
    );
  }

  if (variantType === "selection") {
    return (
      <BootstrapButtonSelection
        sx={{ backgroundColor: "#213240", ...sx }}
        {...rest}
      >
        {children}
      </BootstrapButtonSelection>
    );
  }

  if (variantType === "selected") {
    return (
      <BootstrapButtonSelected
        sx={{ backgroundColor: "#273F55", ...sx }}
        {...rest}
      >
        {children}
      </BootstrapButtonSelected>
    );
  }

  return (
    <BootstrapButtonSec sx={{ backgroundColor: "#273F55", ...sx }} {...rest}>
      {children}
    </BootstrapButtonSec>
  );
};
