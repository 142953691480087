import redIcons from "../../assets/coinFlip/fire.svg";
import blackIcons from "../../assets/coinFlip/water.svg";
import { theme } from "../theme";
import { COINFLIP } from "../../types/index.d";

export const coinFlipImages = [
  {
    src: redIcons,
    selection: COINFLIP.HEADS,
    color: "#D92324",
    hover: "#D92324",
    boxShadow: "0 5px 15px #D92324",
    textColor: theme.palette.text.primary,
  },
  {
    src: blackIcons,
    selection: COINFLIP.TAILS,
    color: "#1F26FF",
    hover: "#1F26FF",
    boxShadow: "0 5px 15px #1F26FF",
    textColor: theme.palette.text.primary,
  },
];

export const DEFAULT_MULTIPLYER = 1.92;
