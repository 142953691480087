import { Banner } from "../../components/banner";
import {
  Container,
  Stack,
  Card,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GameFeed from "../../components/gameFeed";
import { games } from "../../constants/games";
import { useRef, useState, useEffect } from "react";
import { theme } from "../../components/theme";
import { useAppSelector } from "../../components/store/hooks";
import {
  selectBgamingOri,
  selectSlotGames,
} from "../../components/slices/slotsSlice";

import { getImageEndpoint } from "../../constants/slots";
import { Button } from "../../components/button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./index.module.scss";

import SlotsIcon from "../../assets/navIcons/slots-icon.svg";
import GamesIcon from "../../assets/navIcons/gamepad-icon.svg";
import RWIcon from "../../assets/navIcons/rw-originals-icon.svg";

type Props = {
  hoverShadow: number;
};

const options = {
  shouldForwardProp: (prop: string) => prop !== "hoverShadow",
};

const StyledCard = styled(
  Card,
  options
)<Props>(({ theme, hoverShadow = 1 }) => ({
  ":hover": {
    filter: "drop-shadow(2px 4px 6px black)",
    boxShadow: theme.shadows[hoverShadow],
  },
}));

const Home = () => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const slotGames = useAppSelector(selectSlotGames);
  const bgamingOriginals = useAppSelector(selectBgamingOri);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentPositionOri, setCurrentPositionOri] = useState(0);
  const [currentPositionBgaming, setCurrentPositionBgaming] = useState(0);

  return (
    <Container maxWidth="md" sx={{ p: { xs: 0 } }}>
      <Stack sx={{ mb: 3 }} gap={2}>
        <Stack>
          <Banner />
        </Stack>
        <Stack gap={0.5}>
          <Stack
            direction="row"
            sx={{ width: "100%", position: "relative", alignItems: "center" }}
            justifyContent="space-between"
          >
            <Link to={`/games`} style={{ textDecoration: "none" }}>
              <Stack direction="row" gap={0.5} alignItems="center">
                {/* <img src={RWIcon} style={{ width: "1.5em" }} /> */}
                <Typography
                  sx={{ textAlign: "center" }}
                  variant={matchesMD ? "h5" : "body1"}
                  fontWeight={600}
                  color="text.primary"
                >
                  Runewager Originals
                </Typography>
              </Stack>
            </Link>
            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                px: 1,
                py: 0.25,
                zIndex: 20,
                height: "1.6em",
              }}
            >
              <Button
                sx={{
                  width: { md: "100%", xs: "100%" },
                  backgroundColor: "#1C2B39",
                  fontSize: "10px",
                  minWidth: { xs: "auto", md: "auto" },
                  px: { xs: "1em", md: "1em" },
                }}
                onClick={() => {
                  navigate(`/games`);
                }}
              >
                View all
              </Button>
              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" },
                  padding: { xs: "0.5em", md: "0.5em" },
                }}
                disabled={currentPositionOri === 0}
                onClick={() => {
                  let pos = currentPositionOri - 7;
                  if (!matchesMD) {
                    pos = currentPositionOri - 4;
                  }
                  setCurrentPositionOri(pos < 0 ? 0 : pos);
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1em" }} />
              </Button>

              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" },
                  padding: { xs: "0.5em", md: "0.5em" },
                }}
                disabled={
                  currentPositionOri + (matchesMD ? 7 : 4) > games.length
                }
                onClick={() => {
                  if (!matchesMD) {
                    return setCurrentPositionOri(currentPositionOri + 4);
                  }
                  return setCurrentPositionOri(currentPositionOri + 7);
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1em" }} />
              </Button>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={{ lg: 1.25, md: 1, sm: 5, xs: 1 }}
            columns={12}
            zeroMinWidth
            sx={{
              pt: 0.5,
              width: "100%",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            {games.map((game, i) => {
              if (i > currentPositionOri + 6) return null;
              if (i > currentPositionOri + 3 && !matchesMD) return null;
              if (currentPositionOri !== 0 && i < currentPositionOri)
                return null;

              return (
                <Grid
                  className={styles.card}
                  item
                  xs={3}
                  sm={3}
                  md={1.7}
                  lg={1.7}
                  key={game.url}
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "9 / 12",
                    overflow: "hidden",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link to={`/game/${game.url}`}>
                    <img
                      src={game.gif}
                      alt={game.url}
                      style={{ borderRadius: "8px", width: "100%" }} // added width: 100% to ensure images fit
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <Stack gap={0.5}>
          <Stack
            direction="row"
            sx={{ width: "100%", position: "relative", alignItems: "center" }}
            justifyContent="space-between"
          >
            <Link to={`/slots`} style={{ textDecoration: "none" }}>
              <Stack direction="row" gap={0.5} alignItems="center">
                {/* <img src={SlotsIcon} style={{ width: "2em" }} /> */}
                <Typography
                  sx={{ textAlign: "center" }}
                  variant={matchesMD ? "h5" : "body1"}
                  fontWeight={600}
                  color="text.primary"
                >
                  Slots
                </Typography>
              </Stack>
            </Link>

            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                px: 1,
                py: 0.25,
                zIndex: 20,
                height: "1.6em",
                // width: "50%",
              }}
            >
              <Button
                sx={{
                  width: { md: "100%", xs: "100%" },
                  backgroundColor: "#1C2B39",
                  fontSize: "10px",
                  minWidth: { xs: "auto", md: "auto" },
                  px: { xs: "1em", md: "1em" },
                }}
                onClick={() => {
                  navigate(`/slots`);
                }}
              >
                View all
              </Button>
              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" },
                  padding: { xs: "0.5em", md: "0.5em" },
                }}
                disabled={currentPosition === 0}
                onClick={() => {
                  let pos = currentPosition - 7;
                  if (!matchesMD) {
                    pos = currentPosition - 4;
                  }
                  setCurrentPosition(pos < 0 ? 0 : pos);
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1em" }} />
              </Button>

              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" }, // Adjust minWidth for smaller screens
                  padding: { xs: "0.5em", md: "0.5em" }, // Adjust padding for smaller screens
                }}
                disabled={currentPosition + 7 > slotGames.length}
                onClick={() => {
                  if (!matchesMD) {
                    return setCurrentPosition(currentPosition + 4);
                  }
                  return setCurrentPosition(currentPosition + 7);
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1em" }} />
              </Button>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={{ lg: 1.25, md: 1, sm: 5, xs: 1 }}
            columns={12}
            sx={{
              pt: 0.5,
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {slotGames.map((item, i) => {
              if (i > currentPosition + 6) return null;
              if (i > currentPosition + 3 && !matchesMD) return null;
              if (currentPosition !== 0 && i < currentPosition) return null;

              return (
                <Grid
                  className={styles.card}
                  item
                  xs={3}
                  sm={3}
                  md={1.7}
                  lg={1.7}
                  key={item.identifier}
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "9 / 12",
                    overflow: "hidden",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link to={`/slots/${item.identifier}`}>
                    <img
                      src={getImageEndpoint(item, "s6")}
                      alt={item.title}
                      style={{ borderRadius: "8px", width: "100%" }}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <Stack gap={0.5}>
          <Stack
            direction="row"
            sx={{ width: "100%", position: "relative", alignItems: "center" }}
            justifyContent="space-between"
          >
            <Stack direction="row" gap={0.5} alignItems="center">
              {/* <img src={GamesIcon} style={{ width: "1.75em" }} /> */}
              <Typography
                sx={{ textAlign: "center" }}
                variant={matchesMD ? "h5" : "body1"}
                fontWeight={600}
                color="text.primary"
              >
                BGaming Originals
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              justifyContent="flex-end"
              sx={{
                px: 1,
                py: 0.25,
                zIndex: 20,
                height: "1.6em",
                display: { lg: "none", md: "block" },
              }}
            >
              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" },
                  padding: { xs: "0.5em", md: "0.5em" },
                }}
                disabled={currentPositionBgaming === 0}
                onClick={() => {
                  let pos = currentPositionBgaming - 7;
                  if (!matchesMD) {
                    pos = currentPositionBgaming - 4;
                  }
                  setCurrentPositionBgaming(pos < 0 ? 0 : pos);
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1em" }} />
              </Button>

              <Button
                sx={{
                  width: { md: "100%", xs: "auto" },
                  backgroundColor: "#1C2B39",
                  minWidth: { xs: "auto", md: "64px" },
                  padding: { xs: "0.5em", md: "0.5em" },
                }}
                disabled={
                  currentPositionBgaming + (matchesMD ? 7 : 4) >=
                  bgamingOriginals.length
                }
                onClick={() => {
                  if (!matchesMD) {
                    return setCurrentPositionBgaming(
                      currentPositionBgaming + 4
                    );
                  }
                  return setCurrentPositionBgaming(currentPositionBgaming + 7);
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1em" }} />
              </Button>
            </Stack>
          </Stack>

          <Grid
            container
            spacing={{ lg: 1.25, md: 1, sm: 5, xs: 1 }}
            columns={12}
            sx={{
              pt: 0.5,
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {bgamingOriginals.map((item, i) => {
              if (i > currentPositionBgaming + 6) return null;
              if (i > currentPositionBgaming + 3 && !matchesMD) return null;
              if (currentPositionBgaming !== 0 && i < currentPositionBgaming)
                return null;

              return (
                <Grid
                  className={styles.card}
                  item
                  xs={3}
                  sm={3}
                  md={1.7}
                  lg={1.7}
                  key={item.identifier}
                  sx={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "9 / 12",
                    overflow: "hidden",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link to={`/slots/${item.identifier}`}>
                    <img
                      src={getImageEndpoint(item, "s6")}
                      alt={item.title}
                      style={{ borderRadius: "8px", width: "100%" }}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <Stack gap={1}>
          {/* <Typography
            sx={{ textAlign: "center" }}
            variant="h5"
            fontWeight={600}
            color="text.primary"
          >
            Games
          </Typography>
          <Stack justifyContent={"center"}>
            <Grid
              container
              // gap={{ md: "auto", sm: 5, xs: 1 }}
              spacing={{ md: "auto", sm: 5, xs: 1 }}
              direction="row"
              flexWrap="wrap"
              // columns={8.7}
              columns={12}
              sx={{
                gridTemplateColumns: {
                  md: "repeat(4, 1fr)", // 4 items per row for md and larger
                  sm: "repeat(2, 1fr)", // 2 items per row for sm screens
                  xs: "repeat(2, 1fr)", // 1 item per row for xs screens
                },
                justifyContent: {
                  md: "normal",
                  sm: "normal",
                  xs: "normal",
                },
              }}
              // gridTemplateColumns="repeat(4, 1fr)"
              // justifyContent={{ md: "normal", sm: "center", xs: "center" }}
            >
              {games.map((game) => {
                if (matchesMD) {
                  return <VideoCard game={game} />;
                }
                return <ImgCard game={game} />;
              })}
            </Grid>
          </Stack> */}

          <GameFeed />
        </Stack>
      </Stack>
    </Container>
  );
};

const VideoCard = ({ game }: any) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    const video = playerRef.current;

    const handleEnded = () => {
      if (!isMouseOver && video) {
        video.pause();
        video.currentTime = 0; // Reset to the first frame after pausing
      }
    };

    if (video) {
      video.addEventListener("ended", handleEnded);
    }

    return () => {
      if (video) {
        video.removeEventListener("ended", handleEnded);
      }
    };
  }, [isMouseOver]);

  const handleMouseOver = () => {
    setIsMouseOver(true);
    if (playerRef.current) {
      playerRef.current.play();
    }
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
    if (playerRef.current) {
      if (playerRef.current.paused) {
        playerRef.current.currentTime = 0;
      }
    }
  };

  return (
    <Grid item xs={6} md={3}>
      <Link to={`/game/${game.url}`}>
        <StyledCard
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          hoverShadow={20}
          sx={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <video
            ref={playerRef}
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
              userSelect: "none",
            }}
            muted={true}
            loop={isMouseOver ? true : false}
            src={game.gif}
          />
          <Typography
            sx={{ position: "absolute", top: "2.5%", left: "2.5%" }}
            variant="h6"
          >
            {game.name}
          </Typography>
        </StyledCard>
      </Link>
    </Grid>
  );
};

const ImgCard = ({ game }: any) => {
  return (
    <Grid item xs={6} md={3}>
      <Link to={`/game/${game.url}`}>
        <StyledCard
          hoverShadow={20}
          sx={{
            width: "100%",
            height: "100%",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none",
              userSelect: "none",
            }}
            src={game.img}
          />
          <Typography
            sx={{ position: "absolute", top: "2.5%", left: "2.5%" }}
            variant="h6"
          >
            {game.name}
          </Typography>
        </StyledCard>
      </Link>
    </Grid>
  );
};

export default Home;
