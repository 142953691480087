import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./components/theme";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState, ReactNode } from "react";
import {
  selectBuildVersion,
  selectChatDrawer,
  selectInitialLoading,
  selectMaintenance,
  selectToken,
  selectUserType,
  verifyUser,
} from "./components/slices/userSlice";
import { AppDispatch } from "./components/store";
import Navbar from "./components/navbar";
import { BrowserRouter as Router } from "react-router-dom";
import { Chat } from "./components/chat";
import { Box, Stack } from "@mui/material";
import { useAppSelector } from "./components/store/hooks";
import {
  getAllAdminMessages,
  getAllMessages,
} from "./components/slices/chatSlice";
import { getOpenOrder } from "./components/slices/orderSlice";
import { hasCashierPermission } from "./utils";
import SvgPathLoader from "./components/loading/loading";
import { getMyBets } from "./components/slices/gameFeeds";
import { Helmet } from "react-helmet-async";
import AllRoutes from "./routes";
import { getSlotGames } from "./components/slices/slotsSlice";
import { getAllChallenges } from "./components/slices/challengesSlice";

interface PageProps {
  title: string;
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isChatDrawerOpen = useAppSelector(selectChatDrawer);
  const accesToken = useAppSelector(selectToken);
  const userType = useAppSelector(selectUserType);
  const loading = useAppSelector(selectInitialLoading);
  const [loadingBuffer, setLoadingBuffer] = useState(true);
  const isMaintenance = useAppSelector(selectMaintenance);
  const builVersion = useAppSelector(selectBuildVersion);
  const isLoggeded = useRef(false);
  const buildRef = useRef(builVersion);

  useEffect(() => {
    if (loadingBuffer) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loadingBuffer]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLoadingBuffer(false);
      }, 1500);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(verifyUser());
    dispatch(getSlotGames());
    dispatch(getAllChallenges());
    // dispatch(getAllMessages());
  }, [dispatch]);

  useEffect(() => {
    if (accesToken) {
      // dispatch(getOpenOrder());
      dispatch(getMyBets());
    }
    // if (hasCashierPermission(userType)) {
    //   dispatch(getAllAdminMessages());
    // }
  }, [accesToken]);

  useEffect(() => {
    if (isMaintenance) {
      buildRef.current = builVersion;
    }
  }, [isMaintenance]);

  useEffect(() => {
    buildRef.current = builVersion;
    if (!isLoggeded.current) {
      isLoggeded.current = true;
      console.log(
        `%cStop! Do not enter anything into the console, it will enable hackers to steal your account and/or your tokens!`,
        "color: red; font-size:18px;"
      );
    }
  }, []);

  useEffect(() => {
    if (buildRef.current === 0 && builVersion) {
      buildRef.current = builVersion;
    }
  }, [builVersion]);

  return (
    <div className="App">
      <Router>
        <Stack
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: 20,
            transform: "translate(-50%,-50%)",
            display: !loadingBuffer ? "none" : "flex",
            transition: "all 1s",
          }}
        >
          <SvgPathLoader />
        </Stack>
        <div
          id="main-container"
          style={{
            opacity: loadingBuffer ? 0 : 1,
            transition: "opacity 1s",
            overflow: "overlay",
          }}
        >
          <ThemeProvider theme={theme}>
            {!isMaintenance && <Navbar />}

            {!loadingBuffer && (
              <Box sx={{ display: "flex" }}>
                <AllRoutes
                  isMaintenance={
                    isMaintenance ||
                    (builVersion !== buildRef.current &&
                      !!buildRef.current &&
                      !!builVersion)
                  }
                  open={isChatDrawerOpen}
                  buildRef={buildRef.current}
                />
                <Chat />
              </Box>
            )}
          </ThemeProvider>
        </div>
      </Router>
    </div>
  );
};

export default App;
