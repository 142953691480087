import { Stack, Typography, Link, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/runewager.svg";
import {
  AboutUsList,
  CommunityList,
  GamesList,
  SupportList,
} from "./constants";
import styles from "./index.module.scss";
import { openModal } from "../slices/userSlice";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";

export const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const handleGameNavigate = (url: string) => {
    navigate(url);
  };

  const handleSupportNavigate = (game: any) => {
    if (game.url) {
      navigate(game.url);
      return;
    }
    if (game.modal) {
      dispatch(openModal(game.modal));
    }
  };
  return (
    <footer className={styles.footer}>
      <Container
        maxWidth="md"
        sx={{ marginBottom: { md: 0, sm: 6, xs: 6 }, py: 2 }}
      >
        <Stack gap={1}>
          <img alt="footer-logo" src={LogoImg} style={{ width: "15.3em" }} />
          <Typography color="text.primary">
            ⓒ 2024 Runewager.com | All Rights Reserved
          </Typography>
          <Typography color="text.primary">
            Runewager is not in any way affiliated or endorsed by Runescape or
            Jagex LTD
          </Typography>

          <Stack
            direction={{ md: "row", sm: "column" }}
            sx={{ width: "100%" }}
            gap={8}
          >
            <Stack direction="row" sx={{ width: "100%" }} gap={8}>
              <Stack gap={0.5} sx={{ width: "100%" }}>
                <Typography color="text.primary" variant="h5">
                  Games
                </Typography>
                <hr className={styles.line} />
                <Stack>
                  {GamesList.map((game) => (
                    <Link
                      onClick={() => {
                        if (game.url !== "slots") {
                          handleGameNavigate(`/game/${game.url}`);
                        } else {
                          handleGameNavigate(`/${game.url}`);
                        }
                      }}
                      underline="none"
                      rel="noreferrer"
                      color="text.primary"
                      className={styles.link}
                    >
                      {game.label}
                    </Link>
                  ))}
                </Stack>
              </Stack>
              <Stack gap={0.5} sx={{ width: "100%" }}>
                <Typography color="text.primary" variant="h5">
                  Support
                </Typography>
                <hr className={styles.line} />
                <Stack>
                  {SupportList.map((game) => (
                    <Link
                      onClick={() => handleSupportNavigate(game)}
                      underline="none"
                      rel="noreferrer"
                      color="text.primary"
                      className={styles.link}
                    >
                      {game.label}
                    </Link>
                  ))}
                  <Typography
                    color="text.primary"
                    variant="body1"
                    sx={{ color: "#1c2b39" }}
                  >
                    -
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }} gap={8}>
              <Stack gap={0.5} sx={{ width: "100%" }}>
                <Typography color="text.primary" variant="h5">
                  Community
                </Typography>
                <hr className={styles.line} />
                <Stack>
                  {CommunityList.map((game) => (
                    <Link
                      // onClick={() => handleGameNavigate(`/game/${game.url}`)}
                      href={game.url}
                      target="_blank"
                      underline="none"
                      rel="noreferrer"
                      color="text.primary"
                      className={styles.link}
                    >
                      {game.label}
                    </Link>
                  ))}
                </Stack>
              </Stack>
              <Stack gap={0.5} sx={{ width: "100%" }}>
                <Typography color="text.primary" variant="h5">
                  About us
                </Typography>
                <hr className={styles.line} />
                <Stack>
                  {AboutUsList.map((game) => (
                    <Link
                      onClick={() => handleSupportNavigate(game)}
                      underline="none"
                      rel="noreferrer"
                      color="text.primary"
                      className={styles.link}
                    >
                      {game.label}
                    </Link>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </footer>
  );
};
