import {
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Button as CustomButton } from "../button";
import {
  EXCHANGETYPE,
  IExchangeDataType,
  ORDERTYPES,
} from "../../types/index.d";
import { useState, useEffect } from "react";
import { InputFieldStyled } from "../inputField";
import ExchangeService from "../../services/exchange";
import { useAppSelector } from "../store/hooks";
import {
  getCurrencies,
  getUser,
  selectBalance,
  selectCryptoToggle,
  selectCurrencies,
  selectExchangeType,
  selectInitialLoading,
  selectOSRSRate,
  selectRS3Rate,
  selectToken,
  selectWithdrawLock,
  updateExchangeType,
  verifyUser,
} from "../slices/userSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { theme } from "../theme";
import { ExchangeOptions } from "./exchangeOptions";
import { useDispatch } from "react-redux";
import {
  createOrder,
  selectOrderErrorMessage,
  selectOrderLoading,
} from "../slices/orderSlice";
import { AppDispatch } from "../store";
import { TokenIcon } from "../tokenIcon";
import { RuneScapeIcon } from "../tokenIcon/runeScape";
import { RoundNumber } from "../../utils";
import { CryptoOptions, CryptoType } from "../../constants/exchange";
import { PromoCode } from "./promoCode";
import { Tip } from "./tip";
import { Kinguin } from "./kinguin";

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const usdtSelection = [
  {
    symbol: "usdttrc20",
    network: "TRC20",
  },

  {
    symbol: "usdtsol",
    network: "SOL",
  },
  {
    symbol: "usdtbsc",
    network: "BSC",
  },
  {
    symbol: "usdterc20",
    network: "TERC20",
  },
];

const ethSelection = [
  {
    symbol: "eth",
    network: "Eth",
  },
  {
    symbol: "ethbase",
    network: "Base",
  },
  {
    symbol: "ethbsc",
    network: "BSC",
  },
];

const usdcSelection = [
  {
    symbol: "usdc",
    network: "ETH",
  },
  {
    symbol: "usdcmatic",
    network: "MATIC",
  },
];

const ExchangeModal = () => {
  const [selectedType, setSelectedType] = useState<IExchangeDataType | null>(
    null
  );
  // const [currentType, setCurrentType] = useState(ORDERTYPES.DEPOSIT);
  const currentType = useAppSelector(selectExchangeType);
  const dispatch = useDispatch<AppDispatch>();
  const [toDeposit, setToDeposit] = useState("");
  const [potentialTokens, setPotentialTokens] = useState("");
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [inGameUsername, setInGameUsername] = useState("");
  const [lastModified, setLastModified] = useState<string | null>(null);
  const accessToken = useAppSelector(selectToken);
  const errorMessage = useAppSelector(selectOrderErrorMessage);
  const loading = useAppSelector(selectOrderLoading);
  const userBalance = useAppSelector(selectBalance);
  const [debounceTimer, setDebounceTimer] = useState<
    NodeJS.Timeout | number | null
  >(null);
  const withdrawLock = useAppSelector(selectWithdrawLock);
  const [cryptoType, setCryptoType] = useState(CryptoType.BTC);
  const [loadingToken, setLoadingToken] = useState(false);

  const initialLoading = useAppSelector(selectInitialLoading);
  const availableCurrencies = useAppSelector(selectCurrencies);

  const osrsValue = useAppSelector(selectOSRSRate);
  const rs3Value = useAppSelector(selectRS3Rate);

  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [currentList, setCurrentList] = useState(usdtSelection);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const toggleDeposits = useAppSelector(selectCryptoToggle);

  const setCurrentType = (orderType: ORDERTYPES) => {
    dispatch(updateExchangeType(orderType));
  };
  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer as NodeJS.Timeout);

    const newTimer = setTimeout(async () => {
      try {
        if (lastModified === "toDeposit") {
          setLoadingToken(true);
          const getToken = await ExchangeService.getBalance(
            selectedType?.type || EXCHANGETYPE.DEPOSITOSRS,
            Number(toDeposit),
            false,
            accessToken
          );
          if (getToken) {
            setPotentialTokens(getToken.potentialTokens);
            setLastModified("");
          }
        } else if (lastModified === "potentialTokens") {
          setLoadingToken(true);

          const depositAmount = await ExchangeService.getBalance(
            selectedType?.type || EXCHANGETYPE.DEPOSITCRYPTO,
            Number(potentialTokens),
            true,
            accessToken
          );
          if (depositAmount) {
            setToDeposit(depositAmount.potentialTokens);
            setLastModified("");
          }
        }
      } catch {
        console.log("Error");
      } finally {
        setLoadingToken(false);
      }
    }, 500);

    setDebounceTimer(newTimer);

    return () => {
      if (debounceTimer) clearTimeout(debounceTimer as NodeJS.Timeout);
    };
    // eslint-disable-next-line
  }, [toDeposit, potentialTokens, selectedType, accessToken, lastModified]);

  const handleToDepositChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.replace("-", "");
    if (value.length <= 10) {
      setToDeposit(value);
      setLastModified("toDeposit");
    }
  };

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.replace("-", "");
    if (value.length <= 10) {
      setPotentialTokens(value);
      setLastModified("potentialTokens");
    }
  };

  const handleBack = () => {
    setPotentialTokens("");
    setToDeposit("");
    setLastModified("");
    setSelectedType(null);
  };

  const isCrypto =
    selectedType?.type === EXCHANGETYPE.DEPOSITCRYPTO ||
    selectedType?.type === EXCHANGETYPE.WITHDRAWCRYPTO;
  const isWithdraw =
    selectedType?.type === EXCHANGETYPE.WITHDRAWOSRS ||
    selectedType?.type === EXCHANGETYPE.WITHDRAWRS3 ||
    selectedType?.type === EXCHANGETYPE.WITHDRAWCRYPTO;
  const isCryptoWithdraw = selectedType?.type === EXCHANGETYPE.WITHDRAWCRYPTO;
  // const [selectedCurrency, setSelectedCurrency] = useState("sol");
  // const [currentList, setCurrentList] = useState(usdtSelection)
  useEffect(() => {
    if (cryptoType === CryptoType.USDT) {
      setSelectedCurrency(usdtSelection[0]);
      setCurrentList(usdtSelection);
    }

    if (cryptoType === CryptoType.USDC) {
      setSelectedCurrency(usdcSelection[0]);
      setCurrentList(usdcSelection);
    }

    if (cryptoType === CryptoType.ETH) {
      setSelectedCurrency(ethSelection[0]);
      setCurrentList(ethSelection);
    }
  }, [cryptoType]);

  const handleDepositOrder = () => {
    if (
      selectedType &&
      ((inGameUsername.length < 13 && inGameUsername) || isCrypto)
    ) {
      dispatch(
        createOrder({
          type: selectedType?.type,
          amount: isWithdraw ? Number(toDeposit) : Number(toDeposit),
          inGameUsername,
          cryptoType:
            cryptoType === CryptoType.USDT ||
            cryptoType === CryptoType.USDC ||
            cryptoType === CryptoType.ETH
              ? selectedCurrency.symbol || ""
              : cryptoType,
          cryptoAddress,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getUser());
  }, []);

  if (initialLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress sx={{ color: "#33EC3D" }} />
      </Stack>
    );
  }

  const findCorrectCryptoIcon = CryptoOptions.find(
    (option) => option.type === cryptoType
  );

  return (
    <>
      {selectedType && (
        <Stack alignItems="center" sx={{ position: "relative" }}>
          <IconButton
            sx={{
              position: "absolute",
              left: "0%",
              top: matchesMD ? "0%" : "0%",
              transform: matchesMD
                ? "translate(-50%,0%)"
                : "translate(-50%,-20%)",
              color: theme.palette.text.primary,
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            color="text.primary"
            variant={matchesMD ? "h4" : "h6"}
            fontWeight={600}
            sx={{ mb: -2 }}
          >
            {selectedType?.label}
            {/* {!selectedType && "Wallet"} */}
          </Typography>
        </Stack>
      )}

      <Stack gap={1}>
        {!selectedType && (
          <ExchangeOptions
            currentType={currentType}
            setCurrentType={setCurrentType}
            setSelectedType={setSelectedType}
            disabled={withdrawLock > 0 && currentType === ORDERTYPES.WITHDRAWAL}
            setCryptoType={setCryptoType}
            osrsValue={osrsValue}
            rs3Value={rs3Value}
          />
        )}

        {!selectedType &&
          withdrawLock > 0 &&
          currentType === ORDERTYPES.WITHDRAWAL && (
            <Typography color="error">
              {`You need to wager ${RoundNumber(
                withdrawLock
              )} more Sweep Cash to be able to Redeem`}
            </Typography>
          )}

        {!toggleDeposits && currentType === ORDERTYPES.DEPOSIT && (
          <Typography color="error">
            {`Crypto Deposits currently Disabled`}
          </Typography>
        )}

        {selectedType && !isWithdraw && (
          <Stack gap={2}>
            {!isCrypto && (
              <Stack gap={0.2}>
                <Typography color="text.primary" fontWeight={500}>
                  In-game Username
                </Typography>
                <InputFieldStyled
                  error={inGameUsername.length > 12}
                  value={inGameUsername}
                  onChange={(e) => setInGameUsername(e.target.value)}
                  inputProps={{
                    maxLength: 12,
                  }}
                />
              </Stack>
            )}
            {isCrypto && (
              <Stack gap={0.2}>
                <Stack direction="column" gap={1}>
                  <Typography color="text.primary" fontWeight={500}>
                    Crypto
                  </Typography>

                  <Select
                    value={cryptoType}
                    onChange={(e) =>
                      setCryptoType(e.target.value as CryptoType)
                    }
                    input={
                      <InputFieldStyled
                        fullWidth
                        sx={{
                          "& .MuiInputBase-input": {
                            "&.Mui-disabled": {
                              backgroundColor: "#777d85",
                              WebkitTextFillColor: "black",
                            },
                          },
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {CryptoOptions.map((mine: any) => {
                      const findCorrectCryptoIcon = CryptoOptions.find(
                        (option) => option.type === mine.type
                      );
                      if (!findCorrectCryptoIcon) return;
                      return (
                        <MenuItem value={mine.type}>
                          <Stack direction="row" gap={1} alignItems="center">
                            <img
                              alt={findCorrectCryptoIcon.text}
                              src={findCorrectCryptoIcon.img}
                              style={{
                                width:
                                  findCorrectCryptoIcon.type ===
                                  CryptoType.OTHERS
                                    ? "3em"
                                    : "2em",
                              }}
                            />
                            <Typography color="text.primary" fontWeight={500}>
                              {mine.text}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Stack>
              </Stack>
            )}
            {findCorrectCryptoIcon &&
              selectedCurrency?.network &&
              (findCorrectCryptoIcon.type === CryptoType.USDC ||
                findCorrectCryptoIcon.type === CryptoType.ETH ||
                findCorrectCryptoIcon.type === CryptoType.USDT) && (
                <Stack>
                  <Typography color="text.primary" fontWeight={500}>
                    Network
                  </Typography>
                  <Select
                    fullWidth
                    value={selectedCurrency.network}
                    MenuProps={MenuProps}
                    input={<InputFieldStyled />}
                    // sx={{ border: "1px solid white" }}
                    // inputProps={{ MenuProps: { disableScrollLock: true } }}
                    onChange={(e) => {
                      const network = e.target.value;
                      const obj = currentList.find(
                        (o) => o.network === network
                      );
                      setSelectedCurrency(obj);
                    }}
                  >
                    {currentList.map((selection: any) => (
                      <MenuItem
                        value={selection.network}
                        key={selection.network}
                      >
                        {selection.network.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              )}
            <Stack gap={0.2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  {selectedType?.currency} {!isCrypto && "Gold (M)"}
                </Typography>
                {!isCrypto && <RuneScapeIcon />}
              </Stack>

              <InputFieldStyled
                type="number"
                value={toDeposit}
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={handleToDepositChange}
              />
            </Stack>
            <Stack gap={0.2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  Sweep Cash
                </Typography>
                <TokenIcon />
              </Stack>
              <InputFieldStyled
                type="string"
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={potentialTokens}
                onChange={handleTokenChange}
              />
            </Stack>
            <Stack gap={0.2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  Fun Cash
                </Typography>
                <TokenIcon freeCash />
              </Stack>
              <InputFieldStyled
                type="string"
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                disabled
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={
                  potentialTokens ? parseFloat(potentialTokens) * 10000 : null
                }
                onChange={handleTokenChange}
              />
            </Stack>
            {errorMessage && (
              <Typography color="error" fontWeight={500}>
                {errorMessage}
              </Typography>
            )}
            <CustomButton
              variantType="success"
              disabled={
                Number(potentialTokens) < 1 ||
                loading ||
                (inGameUsername.length < 1 && !isCrypto) ||
                loadingToken
              }
              onClick={handleDepositOrder}
            >
              Purchase
            </CustomButton>
          </Stack>
        )}
        {selectedType && isWithdraw && (
          <Stack gap={2}>
            {!isCrypto && (
              <Stack gap={0.2}>
                <Typography color="text.primary" fontWeight={500}>
                  In-game Username
                </Typography>
                <InputFieldStyled
                  error={inGameUsername.length > 12}
                  value={inGameUsername}
                  onChange={(e) => setInGameUsername(e.target.value)}
                />
              </Stack>
            )}
            {isCrypto && (
              <Typography color="text.primary">
                Users will be required to cover the fees, we recommend LTC for
                the lowest fees
              </Typography>
            )}
            {isCrypto && (
              <Stack gap={0.2}>
                <Stack direction="column" gap={1}>
                  <Typography color="text.primary" fontWeight={500}>
                    Crypto
                  </Typography>

                  <Select
                    value={cryptoType}
                    onChange={(e) =>
                      setCryptoType(e.target.value as CryptoType)
                    }
                    input={
                      <InputFieldStyled
                        sx={{
                          "& .MuiInputBase-input": {
                            "&.Mui-disabled": {
                              backgroundColor: "#777d85",
                              WebkitTextFillColor: "black",
                            },
                          },
                        }}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {CryptoOptions.map((mine: any) => {
                      const findCorrectCryptoIcon = CryptoOptions.find(
                        (option) => option.type === mine.type
                      );
                      if (!findCorrectCryptoIcon) return;
                      return (
                        <MenuItem value={mine.type}>
                          <Stack direction="row" gap={1} alignItems="center">
                            <img
                              alt={findCorrectCryptoIcon.text}
                              src={findCorrectCryptoIcon.img}
                              style={{
                                width:
                                  findCorrectCryptoIcon.type ===
                                  CryptoType.OTHERS
                                    ? "3em"
                                    : "2em",
                              }}
                            />
                            <Typography color="text.primary" fontWeight={500}>
                              {mine.text}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* {findCorrectCryptoIcon && (
                    <img
                      alt={findCorrectCryptoIcon.text}
                      src={findCorrectCryptoIcon.img}
                      style={{
                        width:
                          findCorrectCryptoIcon.type === CryptoType.OTHERS
                            ? "3em"
                            : "2em",
                      }}
                    />
                  )} */}
                </Stack>
              </Stack>
            )}
            {isCrypto && (
              <Stack gap={0.2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography color="text.primary" fontWeight={500}>
                    Crypto Address
                  </Typography>
                </Stack>
                <InputFieldStyled
                  type="string"
                  // error={Number(toDeposit || 0) > userBalance}
                  value={cryptoAddress}
                  onChange={(e) => setCryptoAddress(e.target.value)}
                />
              </Stack>
            )}

            <Stack gap={0.2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  Sweep Cash
                </Typography>
                <TokenIcon />
              </Stack>
              <InputFieldStyled
                type="number"
                error={Number(toDeposit || 0) > userBalance}
                value={toDeposit}
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={handleToDepositChange}
              />
            </Stack>

            <Stack gap={0.2}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  USD
                </Typography>
              </Stack>
              <InputFieldStyled
                type="number"
                value={toDeposit ? parseInt(toDeposit || "1") / 4 : ""}
                disabled
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={handleToDepositChange}
              />
            </Stack>
            <Stack gap={0.2} sx={{ display: isCrypto ? "none" : "flex" }}>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography color="text.primary" fontWeight={500}>
                  {selectedType?.currency} {!isCrypto && "Gold (M)"}
                </Typography>
                {!isCrypto && <RuneScapeIcon />}
              </Stack>

              <InputFieldStyled
                type="number"
                inputProps={{
                  min: 0,
                  maxLength: 10,
                }}
                onKeyDown={(e: any) => {
                  if (
                    !(e.key >= "0" && e.key <= "9") &&
                    !(e.ctrlKey && e.key === "v") &&
                    !(e.key === "Backspace" || e.key === ".")
                  ) {
                    e.preventDefault();
                  }

                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={potentialTokens}
                onChange={handleTokenChange}
              />
            </Stack>

            {errorMessage && (
              <Typography color="error" fontWeight={500}>
                {errorMessage}
              </Typography>
            )}
            <CustomButton
              variantType="success"
              disabled={
                (!isCryptoWithdraw && Number(toDeposit) < 5) ||
                (isCryptoWithdraw && Number(toDeposit) < 28) ||
                loading ||
                Number(toDeposit || 0) > userBalance ||
                (inGameUsername.length < 1 && !isCrypto) ||
                loadingToken
              }
              onClick={handleDepositOrder}
            >
              Redeem
            </CustomButton>
          </Stack>
        )}
        {(currentType === ORDERTYPES.PROMOCODE ||
          currentType === ORDERTYPES.REDEEMGIFT) && (
          <PromoCode currentType={currentType} />
        )}
      </Stack>

      {currentType === ORDERTYPES.TIP && <Tip />}
      {currentType === ORDERTYPES.KINGUIN && <Kinguin />}
    </>
  );
};

export default ExchangeModal;
