import {
  Container,
  Typography,
  Stack,
  Card,
  InputAdornment,
  MenuItem,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  InputFieldStyled,
  InputFieldWithStartAdornment,
} from "../../components/inputField";
import { theme } from "../../components/theme";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../components/store/hooks";
import { ISlotGame, selectSlotGames } from "../../components/slices/slotsSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  bgamingSlotsReleaseDates,
  getImageEndpoint,
  getSlotsToRecentlyPlayed,
} from "../../constants/slots";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { selectFavouriteGames } from "../../components/slices/userSlice";
import { useSelector } from "react-redux";
import { games } from "../../constants/games";
import {
  ChallengesDocument,
  selectChallenges,
} from "../../components/slices/challengesSlice";
import { TOKENTYPE } from "../../types/index.d";
import { TokenIcon } from "../../components/tokenIcon";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import RWFreeTokenIcon from "../../assets/freeCashToken.svg";
const sortByList = [
  "All",
  "Alphabetical (A-Z)",
  "Alphabetical (Z-A)",
  "Created at",
];

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const Challenges = () => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("All");
  const slotGames = useAppSelector(selectSlotGames);
  const [allGames, setAllGames] = useState<any[]>([]);
  const [shownGames, setShownGames] = useState<any[]>([]);
  const navigate = useNavigate();
  const challenges = useSelector(selectChallenges);

  useEffect(() => {
    const filteredGames = allGames.filter((game) =>
      game.title.toLowerCase().includes(search.toLowerCase())
    );

    if (sortBy === "Created at") {
      const sortedGames = [...filteredGames].sort((a, b) => {
        const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
        const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);

        return dateB.getTime() - dateA.getTime();
      });

      setShownGames(sortedGames);
      return;
    }

    // Apply sorting after filtering
    if (sortBy === "Alphabetical (A-Z)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setShownGames(sortedGames);
      return;
    }

    if (sortBy === "Alphabetical (Z-A)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        b.title.localeCompare(a.title)
      );
      setShownGames(sortedGames);
      return;
    }

    // If no sorting, just set filtered games
    setShownGames(filteredGames);
  }, [sortBy, search, slotGames]);

  useEffect(() => {
    if (challenges) {
      const result = challenges
        .map((challenge: ChallengesDocument) => {
          // Check in slots
          if (challenge.isSlot) {
            let slotMatch = slotGames.find(
              (slot) => slot.identifier === challenge.game
            );
            if (slotMatch)
              return {
                identifier: slotMatch.identifier,
                title: slotMatch.title,
                isSlots: true,
                imgSrc: getImageEndpoint(slotMatch, "s6"),
                ...challenge,
              };
          }

          let gameMatch = games.find(
            (game) =>
              game.name.toLowerCase() === challenge.game.toLowerCase() ||
              (challenge.game === "DUEL" && game.name === "Duel Arena")
          );
          if (gameMatch)
            return {
              identifier: gameMatch.url,
              title: gameMatch.name,
              isSlots: false,
              imgSrc: gameMatch.gif,
              ...challenge,
            };

          return null; // if not found in either
        })
        .filter((data) => data);
      setAllGames(result);
      setShownGames(result);
    }
  }, [challenges]);

  return (
    <Container maxWidth={"md"}>
      <Stack gap={1} justifyContent="flex-start" sx={{ minHeight: "60vh" }}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton
            sx={{
              bgcolor: "#1C2B39",
              height: "1.5em",
              maxWidth: "1.5em",
              fontSize: "1em",
              p: 2,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosIcon
              fontSize="inherit"
              sx={{ ml: 1, color: theme.palette.text.primary }}
            />
          </IconButton>
          <Typography
            sx={{ textAlign: "center" }}
            variant="h5"
            fontWeight={600}
            color="text.primary"
          >
            Challenges
          </Typography>
        </Stack>

        <Card sx={{ p: 2 }}>
          <Stack direction="row" gap={2}>
            <InputFieldStyled
              value={!search ? null : search}
              type="string"
              placeholder="Search"
              fullWidth
              // onBlur={handleBlur}
              // error={Boolean(title) && !disabled}
              // disabled={disabled}
              name="searchField"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              sx={{
                width: "50%",
                backgroundColor: theme.palette.primary.main,
                "& .MuiInputBase-input": {
                  paddingLeft: "calc(1em + 20px)",
                },
              }}
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ position: "absolute", zIndex: 2, marginLeft: "0.5em" }}
                >
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <Stack
              sx={{ width: "50%" }}
              direction="row"
              gap={1}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography color="text.primary">Sort By:</Typography>
              <Select
                sx={{ width: "50%" }}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                input={
                  <InputFieldStyled
                    // disabled={disableButtons}
                    sx={{
                      "& .MuiInputBase-input": {
                        "&.Mui-disabled": {
                          backgroundColor: "#2F5272",
                          WebkitTextFillColor: "black",
                        },
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
              >
                {sortByList.map((mine: string) => (
                  <MenuItem value={mine}>{mine}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Card>
        <Grid
          container
          spacing={{ md: 1, sm: 5, xs: 1 }}
          columns={12}
          sx={{
            gridTemplateColumns: {
              md: "repeat(4, 1fr)", // 4 items per row for md and larger
              sm: "repeat(2, 1fr)", // 2 items per row for sm screens
              xs: "repeat(2, 1fr)", // 1 item per row for xs screens
            },
            justifyContent: {
              md: "normal",
              sm: "normal",
              xs: "normal",
            },
          }}
        >
          {shownGames.map((item, i) => {
            return (
              <Grid
                item
                key={item.game}
                className={styles.card}
                // minHeight={160}
                // size={{
                xs={4}
                sm={4}
                md={2}
                lg={2}
                // }}
              >
                <Link
                  to={
                    item.isSlots ? `/slots/${item.game}` : `/game/${item.game}`
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Card>
                    <Stack>
                      <img
                        src={item.imgSrc}
                        alt={item.title}
                        style={{ borderRadius: "8px" }}
                      />
                    </Stack>
                    <Stack
                      sx={{ p: 1, textDecoration: "none", pb: 2 }}
                      gap={0.5}
                    >
                      <Typography
                        color="text.primary"
                        sx={{ textDecoration: "none" }}
                        fontWeight={600}
                      >
                        {item.title}
                      </Typography>
                      <Typography color="text.primary">
                        First to hit {item.multiplier}x with min {item.minBet}{" "}
                        {/* {getTokenIcon(item.tokenType)} */}
                        <img
                          src={
                            item.tokenType === TOKENTYPE.FREE
                              ? RWFreeTokenIcon
                              : RWTokenIcon
                          }
                          style={{
                            width:
                              item.tokenType === TOKENTYPE.FREE
                                ? "1em"
                                : "1.2em",
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        play
                      </Typography>
                      <Stack>
                        <Typography>Prize</Typography>
                        <Typography color="text.primary" fontWeight={600}>
                          {item.reward}{" "}
                          <img
                            src={
                              item.tokenType === TOKENTYPE.FREE
                                ? RWFreeTokenIcon
                                : RWTokenIcon
                            }
                            style={{
                              width:
                                item.tokenType === TOKENTYPE.FREE
                                  ? "1em"
                                  : "1.2em",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};

const getTokenIcon = (tokenType: TOKENTYPE) => {
  return <TokenIcon width={20} freeCash={tokenType === TOKENTYPE.FREE} />;
};

export default Challenges;
