import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Chip,
  Typography,
  Stack,
  Card as MUICard,
} from "@mui/material";
import Card from "./card";
import * as BlackJackUtilities from "./constants";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useSound from "use-sound";
import { theme } from "../theme";
import { selectVolume } from "../slices/userSlice";
import BetSound from "../../assets/audio/blackjack/deal.wav";
import { useAppSelector } from "../store/hooks";
import { InputFieldWithStartAdornment } from "../inputField";
import { RoundNumber } from "../../utils";
import { TOKENTYPE } from "../../types/index.d";

interface PlayAreaProps {
  dealersHand: BlackJackUtilities.Card[] | undefined;
  playersHands: any[] | undefined;
  deckRef: any;
  isGameEnd: boolean | undefined;
  currentCard: BlackJackUtilities.Card | undefined;
  multiplier?: number;
  payout?: number;
  tokenType?: TOKENTYPE;
  bet?: number;
  isWin?: boolean;
}
const AnimatedStack = motion(Stack);

const PlayArea: React.FC<PlayAreaProps> = (props) => {
  const playerCardsRef = useRef<any>();
  const volume = useAppSelector(selectVolume);
  const [playDealSound] = useSound(BetSound, { volume: volume / 100 });

  const deckRef = useRef<any>();
  const {
    dealersHand: dealersHandOriginal,
    playersHands,
    currentCard,
    isGameEnd,
    payout,
    multiplier,
    tokenType,
    bet,
    isWin,
  } = props;

  const lastCardRef = useRef<any>(null);
  const isFree = tokenType === TOKENTYPE.FREE;
  let high = currentCard
    ? BlackJackUtilities.calculateHigherProbability(currentCard)
    : null;
  if (currentCard && BlackJackUtilities.isAce(currentCard)) {
    high =
      BlackJackUtilities.calculateHigherProbabilityWithoutSame(currentCard);
  }
  let low = currentCard
    ? BlackJackUtilities.calculateLowerProbability(currentCard)
    : null;

  if (currentCard && BlackJackUtilities.isKing(currentCard)) {
    low = BlackJackUtilities.calculateLowerProbabilityWithoutSame(currentCard);
  }

  const highMul = high ? BlackJackUtilities.calculateMultiplier(high) : 0;
  const lowMul = low ? BlackJackUtilities.calculateMultiplier(low) : 0;

  return (
    <>
      <Box>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            sx={{
              position: { md: "relative", xs: "absolute" },

              top: { md: "auto", xs: "10%" },
              transform: { md: "translateY(-70%)", xs: "" },
            }}
            ref={deckRef}
          >
            {currentCard ? (
              <Card card={currentCard} hide={false} isMain index={2} />
            ) : null}
          </Stack>

          {playersHands?.length ? (
            <Stack
              sx={{
                position: "absolute",
                bottom: "5%",
                px: 2,
                width: "94%",
                // overflow: "hidden",
              }}
              gap={2}
            >
              <MUICard sx={{ widthL: "100%", bgcolor: "#213240", p: 2 }}>
                <Stack direction="row" gap={2}>
                  <Stack>
                    <Typography>Profit Higher ({highMul}x)</Typography>
                    <InputFieldWithStartAdornment
                      name="Profit High"
                      isFreeCash={isFree}
                      value={
                        payout !== 0
                          ? RoundNumber((payout || 0) * highMul - (bet || 0))
                          : 0
                      }
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiInputBase-input": {
                          paddingLeft: "calc(1em + 20px)",
                        },
                      }}
                      disabled
                    />
                  </Stack>
                  <Stack>
                    <Typography>Profit Lower ({lowMul}x)</Typography>
                    <InputFieldWithStartAdornment
                      name="Profit Lower"
                      isFreeCash={isFree}
                      value={
                        payout !== 0
                          ? RoundNumber((payout || 0) * lowMul - (bet || 0))
                          : 0
                      }
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiInputBase-input": {
                          paddingLeft: "calc(1em + 20px)",
                        },
                      }}
                      disabled
                    />
                  </Stack>
                  <Stack>
                    <Typography>
                      Total Profit ({RoundNumber(multiplier || 0)}
                      x)
                    </Typography>
                    <InputFieldWithStartAdornment
                      name="Total Profit "
                      isFreeCash={isFree}
                      value={
                        payout !== 0
                          ? RoundNumber((payout || 0) - (bet || 0))
                          : 0
                      }
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiInputBase-input": {
                          paddingLeft: "calc(1em + 20px)",
                        },
                      }}
                      disabled
                    />
                  </Stack>
                </Stack>
              </MUICard>
              <Box
                sx={{
                  display: "flex",
                  direction: "row",
                  gap: 1,
                  overflowX: playersHands?.length > 4 ? "scroll" : "none",
                }}
                ref={playerCardsRef}
              >
                {playersHands?.length &&
                  playersHands.map(
                    (card: BlackJackUtilities.Card, index: number) => {
                      const isLastCard = index === playersHands?.length - 1;
                      const prevMul = playersHands[index - 1]
                        ? playersHands[index - 1]?.multiplier
                        : undefined;

                      const prevAction = playersHands[index - 1]
                        ? playersHands[index - 1]?.event
                        : undefined;
                      return (
                        <AnimatedStack
                          key={index}
                          initial={{
                            x: "1000px",
                            position: "fixed",
                            width: "6em",
                          }}
                          animate={{
                            x: 0,
                            position: "relative",
                            width: "auto",
                          }}
                          transition={{ duration: 0.5 }}
                          onAnimationStart={() => {
                            playDealSound();
                            if (isLastCard && lastCardRef?.current) {
                              playerCardsRef.current.scrollLeft =
                                playerCardsRef.current.scrollWidth;
                            }
                          }}
                          onAnimationComplete={() => {
                            if (isLastCard && lastCardRef?.current) {
                              playerCardsRef.current.scrollLeft =
                                playerCardsRef.current.scrollWidth;
                            }
                          }}
                          style={{
                            position: "relative",
                            zIndex: playersHands.length - index,
                            // display: "inline-block",
                          }}
                          ref={isLastCard ? lastCardRef : null}
                        >
                          <Card
                            card={card}
                            hide={false}
                            lastCard={isLastCard}
                            index={index}
                            isGameEnd={isGameEnd}
                            prevMul={prevMul}
                            prevAction={prevAction}
                            isWin={isWin}
                          />
                        </AnimatedStack>
                      );
                    }
                  )}
              </Box>
            </Stack>
          ) : null}
        </Grid>
      </Box>
    </>
  );
};

export default PlayArea;
