import { Checkbox, Stack, Typography } from "@mui/material";
import { InputFieldStyled } from "../inputField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store/hooks";
import {
  closeModal,
  selectBalance,
  selectTippingUser,
  selectToken,
  updateExchangeType,
  updateTippingUser,
} from "../slices/userSlice";
import { Button } from "../button";
import Users from "../../services/user";
import { ORDERTYPES } from "../../types/index.d";
import { TokenIcon } from "../tokenIcon";
import { theme } from "../theme";

export const Tip = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [announce, setAnnounce] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const token = useAppSelector(selectToken);
  const selectUser = useAppSelector(selectTippingUser);
  const balance = useAppSelector(selectBalance);

  const handleUpdateTippingUser = (value: string) => {
    dispatch(updateTippingUser(value));
  };

  const handleTipUser = async () => {
    try {
      setError("");
      setLoading(true);
      const tipped = await Users.tipUser(
        token,
        Number(amount),
        selectUser,
        announce
      );
      if (tipped) {
        dispatch(updateTippingUser(""));
        dispatch(closeModal());
      }
    } catch (e: any) {
      if (e?.message) {
        setError(e?.message);
      }
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(updateTippingUser(""));
    dispatch(closeModal());
    dispatch(updateExchangeType(ORDERTYPES.DEPOSIT));
  };

  //   updateTippingUser
  return (
    <Stack gap={1}>
      <Stack gap={0.2}>
        <Typography color="text.primary" fontWeight={500}>
          Username
        </Typography>
        <InputFieldStyled
          error={selectUser.length > 12}
          value={selectUser}
          onChange={(e) => handleUpdateTippingUser(e.target.value)}
          inputProps={{
            maxLength: 12,
          }}
        />
      </Stack>
      <Stack gap={0.2}>
        <Stack gap={1} direction="row">
          <Typography
            color="text.primary"
            sx={{ fontSize: { xs: "10px", md: "14px" } }}
          >
            Sweep Cash
          </Typography>
          <Stack direction="row" gap={0.25}>
            <TokenIcon />
          </Stack>
        </Stack>
        <InputFieldStyled
          type="number"
          error={
            (Number(amount) !== 0 && Number(amount) < 1) ||
            balance < Number(amount)
          }
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          inputProps={{
            maxLength: 12,
          }}
          onKeyDown={(e: any) => {
            if (
              !(e.key >= "0" && e.key <= "9") &&
              !(e.ctrlKey && e.key === "v") &&
              !(e.key === "Backspace" || e.key === ".")
            ) {
              e.preventDefault();
            }

            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "-" ||
              e.key === "+"
            ) {
              e.preventDefault();
            }
          }}
        />
      </Stack>
      {error && <Typography color="error">{error}</Typography>}
      <Stack direction="row" alignItems="center">
        <Checkbox
          color="error"
          value={announce}
          defaultChecked
          onChange={(_e, value: boolean) => setAnnounce(value)}
          sx={{
            px: 0,
            pr: 1,
            color: theme.palette.error.main,
          }}
        />
        <Typography variant="subtitle1" color="text.primary">
          Announce in chat
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ width: "100%" }}
        justifyContent="flex-end"
        gap={1}
      >
        <Button
          variantType="error"
          disabled={loading}
          onClick={handleCancel}
          sx={{ width: "25%" }}
        >
          Cancel
        </Button>
        <Button
          variantType="success"
          disabled={
            Number(amount) < 1 ||
            loading ||
            selectUser.length < 1 ||
            Number(amount) > balance
          }
          onClick={handleTipUser}
          sx={{ width: "25%" }}
        >
          Tip
        </Button>
      </Stack>
    </Stack>
  );
};
