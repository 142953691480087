import { User } from "../components/slices/userSlice";
import { minesPayoutData } from "../constants/minesData";
import { userRankData } from "../constants/userRanks";
import { USERTYPE } from "../types/index.d";

export const RoundNumberFourDecimalPoints = (num: number) => {
  return Math.floor(num * 10000) / 10000;
};

export const RoundNumberThreeDecimalPoints = (num: number) => {
  return Math.floor(num * 1000) / 1000;
};

export const RoundNumberFiveDecimalPoints = (num: number) => {
  return Math.floor(num * 100000) / 100000;
};

export const RoundNumber = (num: number) => {
  return Math.floor(num * 100) / 100;
};

// export const RoundNumberCondition = (num: number) => {
//   const is2dp = Math.floor(num * 100) / 100;
//   if (is2dp) {
//     return is2dp;
//   }
//   const is3dp = Math.floor(num * 1000) / 1000;
//   if (is3dp) {
//     return is3dp;
//   }
//   return Math.floor(num * 10000) / 10000;
// };

export const RoundProfitOnWin = (num: number) => {
  return Number(num.toFixed(5));
};
export const RoundNumberCondition = (num: number) => {
  const is2dp = parseFloat(num.toFixed(2));
  if (is2dp !== Math.floor(num)) {
    return is2dp;
  }

  const is3dp = parseFloat(num.toFixed(3));
  if (is3dp !== Math.floor(num * 100) / 100) {
    return is3dp;
  }

  return parseFloat(num.toFixed(4));
};

export const getPayout = (mines: number, diamonds: number) => {
  const multiplierData = minesPayoutData.find(
    (data) => data.totalMines === mines && data.gemsFound === diamonds
  );
  return RoundNumber(multiplierData?.multiplier || 1);
};

export const getPlayerLevelData = (level: number | undefined) => {
  return userRankData.find((rank) => rank.level === level) || userRankData[0];
};

export const getPlayerImg = (name: string | undefined) => {
  return (
    userRankData.find(
      (rank) => rank.name.toLocaleUpperCase() === name?.toLocaleUpperCase()
    ) || userRankData[0]
  );
};

export const canUserCancel = (user: User) => {
  console.log(user);
};

export const hasCashierPermission = (userType: string) => {
  return (
    userType === USERTYPE.ADMIN ||
    userType === USERTYPE.OWNER ||
    userType === USERTYPE.CASHIER ||
    userType === USERTYPE.DEV
  );
};

export const formatTransactionType = (type: string): string => {
  return type
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

export const isBetGreaterthanSetValue = (
  bet: number,
  balance: number,
  value: number | undefined
) => {
  if (!value) {
    return false;
  }
  return (bet / balance) * 100 > value;
};

const expTable: { [level: number]: number } = {
  1: 0,
  2: 83,
  3: 174,
  4: 276,
  5: 388,
  6: 512,
  7: 650,
  8: 801,
  9: 969,
  10: 1154,
  11: 1358,
  12: 1584,
  13: 1833,
  14: 2107,
  15: 2411,
  16: 2746,
  17: 3115,
  18: 3523,
  19: 3973,
  20: 4470,
  21: 5018,
  22: 5624,
  23: 6291,
  24: 7028,
  25: 7842,
  26: 8740,
  27: 9730,
  28: 10824,
  29: 12031,
  30: 13363,
  31: 14833,
  32: 16456,
  33: 18247,
  34: 20224,
  35: 22406,
  36: 24815,
  37: 27473,
  38: 30408,
  39: 33648,
  40: 37224,
  41: 41171,
  42: 45529,
  43: 50339,
  44: 55649,
  45: 61512,
  46: 67983,
  47: 75127,
  48: 83014,
  49: 91721,
  50: 101333,
  51: 111945,
  52: 123660,
  53: 136594,
  54: 150872,
  55: 166636,
  56: 184040,
  57: 203254,
  58: 224466,
  59: 247886,
  60: 273742,
  61: 302288,
  62: 333804,
  63: 368599,
  64: 407015,
  65: 449428,
  66: 496254,
  67: 547953,
  68: 605032,
  69: 668051,
  70: 737627,
  71: 814445,
  72: 899257,
  73: 992895,
  74: 1096278,
  75: 1210421,
  76: 1336443,
  77: 1475581,
  78: 1629200,
  79: 1798808,
  80: 1986068,
  81: 2192818,
  82: 2421087,
  83: 2673114,
  84: 2951373,
  85: 3258594,
  86: 3597792,
  87: 3972294,
  88: 4385776,
  89: 4842295,
  90: 5346332,
  91: 5902831,
  92: 6517253,
  93: 7195629,
  94: 7944614,
  95: 8771558,
  96: 9684577,
  97: 10692629,
  98: 11805606,
  99: 13034431,
};
export function getLevelByTotalWagered(totalWagered: number): string {
  let level = 1;
  for (let lvl = 1; lvl <= 99; lvl++) {
    if (totalWagered < expTable[lvl]) {
      break;
    }
    level = lvl;
  }
  if (level < 10) {
    return `0${level}`;
  }
  return `${level}`;
}
