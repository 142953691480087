import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldStyled, InputFieldWithStartAdornment } from "../inputField";
import { isBetGreaterthanSetValue, RoundNumber } from "../../utils";
import {
  addBalance,
  addFreeCash,
  openModal,
  selectCurrentUser,
  selectIsTyping,
  selectLevelUpModal,
  selectPlinkoToggle,
  selectToken,
  selectTokenSelection,
  selectVolume,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import ChatContext from "../../contexts/chat/context";
import { DICEMODE, MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useDispatch } from "react-redux";
import AutoButtons, {
  BetButton,
  GameTypeButtons,
  IAutoPlayStates,
  RiskButtons,
} from "./buttons";
import {
  DEFAULT_AUTO_PLAY_DELAY_IN_MS,
  DEFAULT_MAX_BALLS,
  DEFAULT_SPACE_BAR_DELAY_IN_MS,
  LinesType,
  listOfLines,
  PLINKORISK,
} from "./constants";
import { DEFAULT_DISABLED_MESSAGE, DEFAULT_MIN_BET } from "../../constants";

import {
  Bodies,
  Body,
  Composite,
  Engine,
  Events,
  IEventCollision,
  Render,
  Runner,
  World,
} from "matter-js";
import { config, multiplier as multiplierValues } from "./config";
import GameBoard from "./gameBoard";
import styles from "./plinko.module.scss";
import { PLAY_PLINKO_GAME } from "../../constants/socket";
import {
  selectError,
  selectPlinkoGame,
  updatePlinko,
} from "../slices/plinkoSlice";
import { ballColors, getRandomPinPosition } from "./config/positions";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import { handleDoubleBet } from "../../utils/bets";

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const Plinko = ({ audioData }: any) => {
  const engine = Engine.create({
    constraintIterations: 4, // Default is 4
    positionIterations: 6, // Default is 6
    velocityIterations: 4, // Default is 6
  });
  const plinkoRisk = localStorage.getItem("plinkoRisk");
  const plinkoLines = localStorage.getItem("plinkoLines");

  const [lines, setLines] = useState<LinesType>(
    plinkoLines ? (parseInt(plinkoLines) as LinesType) : 8
  );
  const [risk, setRisk] = useState<PLINKORISK>(
    plinkoRisk && plinkoRisk === PLINKORISK.CRAZY
      ? PLINKORISK.LOW
      : PLINKORISK.CRAZY
  );
  const [inGameBallsCount, setInGameBallsCount] = useState<number>(0);
  const plinko = useAppSelector(selectPlinkoGame);
  // const errorMessage = useAppSelector(selectError);
  const [activeBlock, setActiveBlock] = useState(-1);
  const [lastMultipliers, setLastMultipliers] = useState<Record<any, any>[]>(
    []
  );
  const { socket } = useContext(ChatContext);
  const toggle = useAppSelector(selectPlinkoToggle);
  const { engine: engineConfig, world: worldConfig } = config;
  const worldWidth: number = worldConfig.width;
  const worldHeight: number = worldConfig.height;

  // States
  const dispatch = useDispatch();
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
  });
  const isLevelUp = useAppSelector(selectLevelUpModal);

  const autoPlayRef = useRef<NodeJS.Timeout | null>(null);
  const currentBalanceRef = useRef(player.balance | 0);
  const balancesRef = useRef<number[]>([]);
  const balancesIdsRef = useRef<string[]>([]);
  const [currentBet, setCurrentBet] = useState(0);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [title, setTitle] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const disableRef = useRef<any>();
  const levelUpRef = useRef<boolean>(isLevelUp);
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const isTyping = useAppSelector(selectIsTyping);
  const tokenType = useAppSelector(selectTokenSelection);
  const playerBalanceRef = useRef(0);
  const tokenTypeRef = useRef(tokenType);

  useEffect(() => {
    levelUpRef.current = isLevelUp;
  }, [isLevelUp]);

  useEffect(() => {
    if (inGameBallsCount === 0) {
      setLines(lines);
    }
  }, [inGameBallsCount]);

  useEffect(() => {
    localStorage.setItem("plinkoRisk", risk);
  }, [risk]);

  useEffect(() => {
    localStorage.setItem("plinkoLines", lines.toString());
  }, [lines]);

  useEffect(() => {
    setRisk(plinkoRisk ? (plinkoRisk as PLINKORISK) : PLINKORISK.LOW);
  }, []);

  useEffect(() => {
    engine.gravity.y = engineConfig.engineGravity;

    const element = document.getElementById("plinko");
    if (element) {
      const render = Render.create({
        element: element,
        bounds: {
          max: {
            x: worldWidth,
            y: worldHeight,
          },
          min: {
            x: 0,
            y: 0,
          },
        },
        options: {
          background: "var(--primary)",
          hasBounds: true,
          width: worldWidth,
          height: worldHeight,
          wireframes: false,
        },
        engine,
      });
      const runner = Runner.create({
        isFixed: true,
        delta: 1000 / 60,
      });

      Runner.run(runner, engine);
      Render.run(render);

      return () => {
        Engine.clear(engine);
        World.clear(engine.world, true);
        render.canvas.remove();
        render.textures = {};
      };
    }
  }, [lines, risk]);

  useEffect(() => {
    if (tokenType === TOKENTYPE.SWEEP) {
      currentBalanceRef.current = player.balance;
      return;
    }

    if (tokenType === TOKENTYPE.FREE) {
      currentBalanceRef.current = player.freeCash;
    }
  }, [player.balance, tokenType, player.freeCash]);

  const pinSize = 8 - lines / 4 + (lines === 8 ? 1.2 : 0);
  const widthUnit = (worldWidth - pinSize * 2) / (lines * 2 + 2);
  const heightUnit = (worldHeight - pinSize * 2) / (lines + 1);

  const pins = useMemo(() => {
    const pinArray = [];
    for (let i = 0; i < lines; i++) {
      for (let j = lines - i - 1; j <= lines - i + (i + 2) * 2; j += 2) {
        const pin = Bodies.circle(
          widthUnit * j + pinSize,
          heightUnit * (i + 2) + pinSize - 20,
          pinSize,
          {
            label: `pin-${i}`,
            render: {
              fillStyle: "#ffffff",
            },
            isStatic: true,
          }
        );
        pinArray.push(pin);
      }
    }
    return pinArray;
  }, [lines, widthUnit, heightUnit, pinSize]);

  const addBall = useCallback(
    (
      ballValue: number,
      xPos: number,
      risk: string,
      payout: number,
      id: string
    ) => {
      const ballX = xPos;
      const ballColor = ballColors[risk as PLINKORISK];
      const ball = Bodies.circle(ballX, heightUnit, pinSize * 1.8, {
        restitution: 1,
        friction: 0.6,
        label: `ball-${ballValue}-${ballX}-${payout}-${id}`,
        id: new Date().getTime(),
        frictionAir: 0.05,
        collisionFilter: {
          group: -1,
        },
        render: {
          fillStyle: ballColor,
        },
        isStatic: false,
      });
      Composite.add(engine.world, ball);
    },
    [pinSize, risk, lines]
  );

  useEffect(() => {
    if (plinko) {
      setInGameBallsCount((prev) => prev + 1);
      const randomXPos = getRandomPinPosition(
        plinko.lines,
        plinko.multiplierIndex
      );
      balancesRef.current.push(plinko.payout);

      if (playerBalanceRef.current) {
        playerBalanceRef.current -= plinko.bet;
      }
      addBall(plinko.bet, randomXPos, plinko.risk, plinko.payout, plinko._id);

      if (tokenTypeRef.current === TOKENTYPE.FREE) {
        dispatch(addFreeCash(-plinko.bet));
      } else {
        dispatch(addBalance(-plinko.bet));
      }
      dispatch(updatePlinko(plinko));
    }
  }, [plinko]);

  useEffect(() => {
    const rightWall = Bodies.rectangle(
      worldWidth - 125,
      worldWidth / 2 - 2,
      worldWidth * 2,
      40,
      {
        angle: -90,
        render: {
          visible: false,
        },
        isStatic: true,
      }
    );

    const floor = Bodies.rectangle(0, worldWidth + 1, worldWidth * 10, 30, {
      label: "block-1",
      render: {
        visible: false,
      },
      isStatic: true,
    });

    const leftWall = Bodies.rectangle(
      worldWidth / 3 - 75,
      worldWidth / 2 - 2,
      worldWidth * 2,
      40,
      {
        angle: 90,
        render: {
          visible: false,
        },
        isStatic: true,
      }
    );
    Composite.add(engine.world, [...pins, leftWall, rightWall, floor]);
  }, [engine]);

  const playGame = useCallback(
    (currentValue = currentBet, askConfirmation = true) => {
      if (!toggle) return;
      if (title) {
        setTitle(title);
        return;
      }
      if (disableButton) {
        return;
      }

      if (inGameBallsCount >= DEFAULT_MAX_BALLS) return;
      if (
        (currentBet > player.balance && tokenType === TOKENTYPE.SWEEP) ||
        !currentValue
      ) {
        betFieldRef.current?.focus();
        return;
      }

      if (
        (currentBet > player.freeCash && tokenType === TOKENTYPE.FREE) ||
        !currentValue
      ) {
        betFieldRef.current?.focus();
        return;
      }

      if (
        tokenType === TOKENTYPE.SWEEP &&
        !isAutoPlaying &&
        askConfirmation &&
        ((player.isBetConfirmation &&
          isBetGreaterthanSetValue(
            currentBet,
            player.balance,
            player?.betConfirmationValue
          )) ||
          isBetGreaterthanSetValue(currentBet, player.balance, 95))
      ) {
        setConfirmation(true);
        return;
      }

      if (playerBalanceRef.current && tokenType === TOKENTYPE.SWEEP) {
        if (player.balance - playerBalanceRef.current < currentValue) {
          setTitle("Bet can't exceed your balance");
          return;
        }
      }

      if (playerBalanceRef.current && tokenType === TOKENTYPE.FREE) {
        if (player.freeCash - playerBalanceRef.current < currentValue) {
          setTitle("Bet can't exceed your balance");
          return;
        }
      }

      const gameData = {
        currentBet: currentValue,
        risk,
        lines,
        tokenType,
      };
      tokenTypeRef.current = tokenType;
      if (playType === DICEMODE.AUTO) {
        if (!autoPlayStates.numberOfGames) {
          numberOfGamesRef.current = 100;
          setAutoPlayStates({
            ...autoPlayStates,
            numberOfGames: 100,
          });
        }
        setIsAutoPlaying(true);
        startAutoPlay();
      } else {
        playerBalanceRef.current += currentValue;
        playBetSound();
        socket?.emit(PLAY_PLINKO_GAME, {
          gameData,
          accessToken,
        });
      }
      return () => {
        if (disableRef.current) {
          clearTimeout(disableRef.current);
        }
      };
    },

    [
      disableButton,
      currentBet,
      risk,
      lines,
      playType,
      inGameBallsCount,
      player,
      player.balance,
      autoPlayStates,
      balancesRef.current,
      title,
      tokenType,
      toggle,
    ]
  );

  const handleStopAutoPlaying = () => {
    if (isAutoPlaying) {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
      autoPlayRef.current = null;
      setAutoPlayStates({
        ...autoPlayStates,
        numberOfGames: 0,
      });
      setIsAutoPlaying(false);
      // setDisableButton(false);
    }
  };

  useEffect(() => {
    if (isAutoPlaying && tokenType !== tokenTypeRef.current) {
      handleStopAutoPlaying();
    }
  }, [tokenType]);

  const numberOfGamesRef = useRef(autoPlayStates.numberOfGames);

  useEffect(() => {
    numberOfGamesRef.current = autoPlayStates.numberOfGames;
  }, [autoPlayStates]);

  const handleUpdateAutoPlay = useCallback(() => {
    if (!toggle || inGameBallsCount >= 20) {
      setIsAutoPlaying(false);
      return;
    }
    if (
      numberOfGamesRef.current === 0 ||
      currentBet > currentBalanceRef.current ||
      levelUpRef.current
    ) {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
      setIsAutoPlaying(false);
      return;
    }
    if (numberOfGamesRef.current) {
      const gameData = {
        currentBet: currentBet,
        risk,
        lines,
        tokenType,
      };
      setAutoPlayStates((prevState) => ({
        ...prevState,
        numberOfGames: prevState.numberOfGames - 1,
      }));
      playBetSound();

      socket?.emit(PLAY_PLINKO_GAME, {
        gameData,
        accessToken,
      });
    }
  }, [
    currentBet,
    risk,
    lines,
    player.balance,
    setAutoPlayStates,
    isLevelUp,
    setIsAutoPlaying,
    tokenType,
  ]);

  const startAutoPlay = () => {
    handleUpdateAutoPlay();
    autoPlayRef.current = setInterval(() => {
      handleUpdateAutoPlay();
    }, DEFAULT_AUTO_PLAY_DELAY_IN_MS);
  };

  const resumeAutoPlay = () => {
    autoPlayRef.current = setInterval(() => {
      handleUpdateAutoPlay();
    }, DEFAULT_AUTO_PLAY_DELAY_IN_MS);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (autoPlayRef.current) {
          clearInterval(autoPlayRef.current);
          autoPlayRef.current = null;
          setIsAutoPlaying(false);
        }
      } else {
        if (!autoPlayRef.current && numberOfGamesRef.current) {
          setIsAutoPlaying(true);
          resumeAutoPlay();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleUpdateAutoPlay, player, isLevelUp]);

  useEffect(() => {
    return () => {
      // console.log("ending");
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
      const sum = balancesRef.current.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      // console.log("adding leave", sum);
      // dispatch(addBalance(sum));

      if (tokenTypeRef.current === TOKENTYPE.FREE) {
        dispatch(addFreeCash(sum));
      } else {
        dispatch(addBalance(sum));
      }
    };
  }, []);

  useEffect(() => {
    const onCollideWithMultiplier = async (ball: Body, multiplier: Body) => {
      ball.collisionFilter.group = 2;
      World.remove(engine.world, ball);
      const payout = ball.label.split("-")[3];
      const id = ball.label.split("-")[4];
      const xPos = ball.position.x;
      const target = Math.floor((xPos - pinSize) / (widthUnit * 2));
      const ballValue = ball.label.split("-")[1];

      const multiplierValue = multiplierValues[risk][lines - 8][target];
      const audioToPlay = audioData[lines - 8][target];
      audioToPlay();
      if (target === activeBlock) {
        setActiveBlock(-1);
        setActiveBlock(target);
      } else {
        setActiveBlock(target);
      }

      const index = balancesRef.current.findIndex(
        (number) => number === parseFloat(payout)
      );

      if (!balancesIdsRef.current.includes(id)) {
        if (index !== -1) {
          balancesRef.current.splice(index, 1);
        }
        balancesIdsRef.current.push(id);
        // console.log("adding mul", payout, target, id);
        // dispatch(addBalance(parseFloat(payout)));

        if (tokenTypeRef.current === TOKENTYPE.FREE) {
          dispatch(addFreeCash(parseFloat(payout)));
        } else {
          dispatch(addBalance(parseFloat(payout)));
        }
        setInGameBallsCount((prev) => prev - 1);
        setLastMultipliers((prev) => [
          { mul: multiplierValue, index: target },
          ...prev.slice(0, 4),
        ]);
      }
    };

    const onBodyCollision = (event: IEventCollision<Engine>) => {
      const pairs = event.pairs;
      for (const pair of pairs) {
        const { bodyA, bodyB } = pair;
        if (bodyB.label.includes("ball") && bodyA.label.includes("block")) {
          onCollideWithMultiplier(bodyB, bodyA);
        }
      }
    };

    const onBounceCollision = (event: IEventCollision<Engine>) => {
      const pairs = event.pairs;
      for (const pair of pairs) {
        const { bodyA, bodyB } = pair;
        if (bodyB.label.includes("ball") && bodyA.label.includes("pin")) {
          const xPos = bodyA.position.x;
          const yPos = bodyA.position.y;
          let radius = pinSize;
          let bounceEffect: any = null;
          let bounceTimer = setInterval(() => {
            bounceEffect !== null && World.remove(engine.world, bounceEffect);
            bounceEffect = Bodies.circle(xPos, yPos, radius, {
              collisionFilter: { group: -1 },
              render: {
                fillStyle: "#fff3",
              },
              isStatic: true,
            });
            Composite.add(engine.world, bounceEffect);
            radius = radius + pinSize / 8;
            if (radius > pinSize * 3) {
              World.remove(engine.world, bounceEffect);
              clearInterval(bounceTimer);
            }
          }, 5);
        }
      }
    };

    const handleCollision = (event: IEventCollision<Engine>) => {
      onBodyCollision(event);
      onBounceCollision(event);
    };
    // Events.on(engine, "collisionStart", onBodyCollision);
    // Events.on(engine, "collisionStart", onBounceCollision);
    Events.on(engine, "collisionStart", handleCollision);

    return () => {
      // Events.off(engine, "collisionStart", onBodyCollision);
      // Events.off(engine, "collisionStart", onBounceCollision);
      Events.on(engine, "collisionStart", handleCollision);
    };
  }, [lines, risk, audioData]);

  useEffect(() => {
    const handleSpacebarPress = (event: KeyboardEvent) => {
      if (
        !isTyping &&
        event.code === "Space" &&
        !isAutoPlaying &&
        playType === DICEMODE.MANUAL
      ) {
        setTimeout(() => {
          playGame();
        }, DEFAULT_SPACE_BAR_DELAY_IN_MS);
      }
    };

    window.addEventListener("keyup", handleSpacebarPress);

    return () => {
      window.removeEventListener("keyup", handleSpacebarPress);
    };
  }, [
    isAutoPlaying,
    playType,
    currentBet,
    risk,
    lines,
    playGame,
    isTyping,
    tokenType,
  ]);

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Plinko
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={inGameBallsCount > 0 || isAutoPlaying}
                />
              </Stack>
              <Stack sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
                <BetButton
                  accessToken={accessToken}
                  disableButton={
                    disableButton ||
                    inGameBallsCount >= DEFAULT_MAX_BALLS ||
                    (inGameBallsCount > 0 && playType === DICEMODE.AUTO) ||
                    !toggle
                  }
                  playGame={playGame}
                  playType={playType}
                  handleLogin={handleLogin}
                  stopPlaying={handleStopAutoPlaying}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  variantType="primary"
                  disabled={isAutoPlaying}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.PLINKO}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={!accessToken || isAutoPlaying}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  risk={risk}
                  multiplier={lines}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    disabled={isAutoPlaying}
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        currentBet / 2 < DEFAULT_MIN_BET
                          ? DEFAULT_MIN_BET
                          : RoundNumber(currentBet / 2)
                      )
                    }
                    disabled={isAutoPlaying}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1.25}>
                <Stack gap={1.25}>
                  <RiskButtons
                    playType={risk}
                    setPlayType={setRisk}
                    isAutoPlaying={isAutoPlaying}
                    disabled={inGameBallsCount > 0 || isAutoPlaying}
                  />
                </Stack>
                <AutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={inGameBallsCount > 0 || isAutoPlaying}
                />

                <Stack direction="column" gap={1}>
                  <Typography color="text.primary">Lines</Typography>
                  <Select
                    value={lines}
                    onChange={(e) => {
                      if (e.target.value !== lines) {
                        setLines(Number(e.target.value) as LinesType);
                        setLastMultipliers([]);
                      }
                    }}
                    input={
                      <InputFieldStyled
                        sx={{
                          "& .MuiInputBase-input": {
                            "&.Mui-disabled": {
                              backgroundColor: "#2F5272",
                              WebkitTextFillColor: "black",
                            },
                          },
                        }}
                      />
                    }
                    disabled={inGameBallsCount > 0 || isAutoPlaying}
                    MenuProps={MenuProps}
                  >
                    {listOfLines.map((line: number) => (
                      <MenuItem value={line} key={line}>
                        {line}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  sx={{ display: { md: "block", sm: "none", xs: "none" } }}
                >
                  <BetButton
                    accessToken={accessToken}
                    disableButton={
                      disableButton ||
                      inGameBallsCount >= DEFAULT_MAX_BALLS ||
                      (inGameBallsCount > 0 && playType === DICEMODE.AUTO) ||
                      !toggle
                    }
                    playGame={playGame}
                    playType={playType}
                    handleLogin={handleLogin}
                    stopPlaying={handleStopAutoPlaying}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                <Stack
                  sx={{ display: { md: "none", sm: "block", xs: "block" } }}
                >
                  <GameTypeButtons
                    playType={playType}
                    setPlayType={setPlayType}
                    isAutoPlaying={inGameBallsCount > 0 || isAutoPlaying}
                  />
                </Stack>
                {/* {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )} */}

                {!toggle && (
                  <Typography variant="h6" color="error">
                    {DEFAULT_DISABLED_MESSAGE}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <GameOptions game={GAMES.PLINKO} />
          </Stack>
          <Stack
            sx={{
              p: { md: 3, xs: 0 },
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
            gap={{ md: 0, sm: 0, xs: 0 }}
            justifyContent="space-between"
          >
            <GameBoard
              lines={lines}
              risk={risk}
              pinSize={pinSize}
              activeBlock={activeBlock}
              multiplierHistory={lastMultipliers}
              leftBallCount={inGameBallsCount}
              isAuto={isAutoPlaying}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Plinko;
