export enum CHATTYPES {
  Community = "Community",
  ADMIN = "Admin",
  WITHDRAWAL = "WITHDRAW",
  DEPOSIT = "DEPOSIT",
}

export const ChatMenuOptions = [
  CHATTYPES.Community,
  CHATTYPES.WITHDRAWAL,
  CHATTYPES.DEPOSIT,
];

export const CHARACTER_LIMIT = 200;

export const DEFAULT_BASE_ONLINE_USERS = 50;
