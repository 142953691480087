import { useState, useEffect, useRef } from "react";
import { Button } from "../../components/button";
import {
  addDays,
  startOfWeek,
  differenceInMilliseconds,
  isSameDay,
} from "date-fns";
import {
  openModal,
  selectCurrentUser,
  selectToken,
} from "../../components/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { TokenIcon } from "../../components/tokenIcon";
import { Box, Stack, Typography } from "@mui/material";
import { MODALTYPE } from "../../types/index.d";
import Users from "../../services/user";
import { useAppSelector } from "../../components/store/hooks";

export enum BONUSES {
  INSTANT = "INSTANT",
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  LEVELUPS = "LEVELUPS",
  PROMOCODES = "PROMOCODES",
  GIVEAWAY = "GIVEAWAY",
  WAGERRACES = "WAGERRACES",
  RAIN = "RAIN",
  FREEPLAY = "FREEPLAY",
  FIRSTDEPOSIT = "FIRSTDEPOSIT",
  SECONDDEPOSIT = "SECONDDEPOSIT",
  THIRDDEPOSIT = "THIRDDEPOSIT",
}

const isSameDate = (date1: Date, date2: Date) => {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
};

const getNextFriday = () => {
  const currentDateAndTime = new Date();
  const utcDate = new Date(
    //Date.UTC(
    currentDateAndTime.getUTCFullYear(),
    currentDateAndTime.getUTCMonth(),
    currentDateAndTime.getUTCDate()
    //)
  );

  // Get the start of the week in UTC (assuming week starts on Monday)
  const startOfWeekUTC = startOfWeek(utcDate, { weekStartsOn: 1 });

  // Calculate the upcoming Friday in UTC
  const nextFridayUTC = addDays(startOfWeekUTC, 4);

  // If today is Friday, move to the next week's Friday
  if (utcDate.getUTCDay() > 5) {
    return addDays(nextFridayUTC, 7);
  }

  // If today is not Friday, check if we are past this week's Friday
  if (utcDate.getTime() > nextFridayUTC.getTime()) {
    return addDays(nextFridayUTC, 7);
  }

  return nextFridayUTC;
};

// function getNextFridayFromDate() {
//   // const date = new Date(testStartDate);
//   const date = new Date();
//   const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 });
//   return addDays(startOfWeekDate, 6);
// }

const getLastDayOfMonth = () => {
  const today = new Date();
  const utcDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );
  const lastDay = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth() + 1,
    0
  );
  return lastDay;
};
const getLastDayOfNextMonth = () => {
  const today = new Date();
  const utcDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );
  const lastDay = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth() + 2,
    0
  );
  return lastDay;
};

export const CountDownNextFriday = ({ handleClick, isMonth = false }: any) => {
  const [timeLeft, setTimeLeft] = useState("");
  const intervalRef = useRef<any>(null);

  const handleUpdate = () => {
    let nextBonusDate = isMonth ? getLastDayOfMonth() : getNextFriday();
    const now = new Date();
    const utcNow = new Date(
      //Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
      //)
    );

    let difference = differenceInMilliseconds(nextBonusDate, utcNow);
    if (difference < 0 && !isSameDay(utcNow, nextBonusDate)) {
      nextBonusDate = isMonth ? getLastDayOfMonth() : getNextFriday();
      difference = differenceInMilliseconds(nextBonusDate, utcNow);
    }

    if (
      isMonth &&
      difference < 0 &&
      now?.getUTCDate() !== nextBonusDate?.getDate()
    ) {
      nextBonusDate = getLastDayOfNextMonth();
      difference = differenceInMilliseconds(nextBonusDate, utcNow);
    }

    if (isSameDay(utcNow, nextBonusDate)) {
      clearInterval(intervalRef.current);
      setTimeLeft("Friday!");
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      if (days >= 1) {
        setTimeLeft(
          `${days} day${days > 1 ? "s" : ""} ${hours} hr${
            hours !== 1 ? "s" : ""
          }`
        );
      } else if (hours >= 1) {
        setTimeLeft(
          `${hours} hr${hours !== 1 ? "s" : ""} ${minutes} min${
            minutes !== 1 ? "s" : ""
          }`
        );
      } else {
        setTimeLeft(`${minutes} minute${minutes !== 1 ? "s" : ""}`);
      }
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      handleUpdate();
    }, 1000);
    handleUpdate();
    return () => clearInterval(intervalRef.current);
  }, [isMonth]);

  return (
    <>
      <ClaimButton
        handleClick={() =>
          handleClick(isMonth ? BONUSES.MONTHLY : BONUSES.WEEKLY)
        }
        bonusType={isMonth ? BONUSES.MONTHLY : BONUSES.WEEKLY}
        disabled={timeLeft !== "Friday!"}
        timeLeft={timeLeft}
      />
    </>
  );
};

export const ClaimButton = ({
  handleClick,
  bonusType,
  disabled = false,
  timeLeft = "",
}: {
  handleClick: (type: BONUSES) => void;
  bonusType: BONUSES;
  disabled?: boolean;
  timeLeft?: string;
}) => {
  const user = useSelector(selectCurrentUser);
  const getBonus = user?.bonuses?.find((bonus) => bonus.type === bonusType);
  const currentDate = new Date();
  const lastClaimed = getBonus?.lastClaimed
    ? new Date(getBonus.lastClaimed)
    : null;

  const sameDay =
    lastClaimed && currentDate.toDateString() === lastClaimed.toDateString();
  const isDisabled =
    (sameDay && bonusType !== BONUSES.INSTANT) ||
    (getBonus?.available || 0) <= 0.01;
  return (
    <>
      <Typography sx={{ textWrap: "balance", textAlign: "center" }}>
        {timeLeft !== "Friday!" ? timeLeft : ""}
      </Typography>
      <Button
        disabled={isDisabled || disabled}
        variantType="success"
        onClick={() => handleClick(bonusType)}
      >
        <TokenIcon /> {getBonus?.available?.toFixed(2) || 0}
      </Button>
    </>
  );
};

export const ClaimButtonFreePlay = ({
  handleClick,
  bonusType,
}: {
  handleClick: (type: BONUSES) => void;
  bonusType: BONUSES;
}) => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccount] = useState(0);
  const getBonus = user?.bonuses?.find((bonus) => bonus.type === bonusType);
  const currentDate = new Date();
  const lastClaimed = getBonus?.lastClaimed
    ? new Date(getBonus.lastClaimed)
    : null;

  const isDisabled = Boolean(
    lastClaimed && currentDate.toDateString() === lastClaimed.toDateString()
  );
  const token = useAppSelector(selectToken);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const res = await Users.getAccounts(token);
        if (res?.uniqueAccountsCount !== undefined) {
          setAccount(res?.uniqueAccountsCount);
        }
      } catch (e: any) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    if (token) {
      getAccounts();
    }
  }, []);

  const handleOpen = () => {
    if (!user.isVerified) {
      dispatch(openModal(MODALTYPE.VERIFYEMAIL));
      return;
    }
    if (!user.discordUUID) {
      dispatch(openModal(MODALTYPE.VERIFYDISCORD));
      return;
    }

    handleClick(bonusType);
  };

  return (
    <>
      <Button
        disabled={isDisabled || accounts !== 0 || loading}
        variantType="success"
        onClick={() => handleOpen()}
        sx={{ display: "flex", alignContent: "center" }}
      >
        <Box sx={{ mb: 0.25 }}>
          <TokenIcon />
        </Box>
        1 &nbsp;
        <Box sx={{ mb: 0.25 }}>
          <TokenIcon freeCash width={17} />
        </Box>
        10k
      </Button>
    </>
  );
};
