import { Stack, SxProps, Theme } from "@mui/system";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import RWFreeTokenIcon from "../../assets/freeCashToken.svg";

interface TokenIcon {
  width?: number;
  sx?: SxProps<Theme> | undefined;
  freeCash?: boolean;
}
export const TokenIcon = ({ width = 20, sx, freeCash = false }: TokenIcon) => {
  const style = sx ? sx : { width: `${width}px` };
  return (
    <Stack sx={style} alignItems="center" justifyContent="center">
       <img
        alt={freeCash ? "Runewager Fun Cash" : "Runewager Sweep Cash" }
        src={freeCash ? RWFreeTokenIcon : RWTokenIcon}
        style={{ width: "100%" }}
      />
    </Stack>
  );
};
