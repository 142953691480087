import {
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Stack,
  useMediaQuery,
  Card,
  Typography,
  Box,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Icons
import MinesIcon from "../../assets/navIcons/mines-icon.svg";
import DiceIcon from "../../assets/navIcons/dice-icon.svg";
import LimboIcon from "../../assets/navIcons/limbo-icon.svg";
import SlotsIcon from "../../assets/navIcons/slots-icon.svg";
import SlideIcon from "../../assets/navIcons/slide-icon.svg";
import CoinFlipIcon from "../../assets/navIcons/coinflip-icon.svg";
import GamesIcon from "../../assets/navIcons/games-icon.svg";
import ReferralIcon from "../../assets/navIcons/referral-icon.svg";
import RaceIcon from "../../assets/navIcons/race-icon.svg";
import BonusIcon from "../../assets/navIcons/bonuses-icon.svg";
import PlinkoIcon from "../../assets/navIcons/plinko-icon.svg";
import DuelIcon from "../../assets/navIcons/duel-icon.svg";
import BlackJackIcon from "../../assets/navIcons/blackjack-icon.svg";
import HiLoIcon from "../../assets/navIcons/hilo-icon.svg";
import KenoIcon from "../../assets/navIcons/keno-icon.svg";
import DepositBonusIcon from "../../assets/navIcons/deposit-bonus-icon.svg";
import FairnessIcon from "../../assets/navIcons/fairness-icon.svg";
import HelpIcon from "../../assets/navIcons/help-icon.svg";
import { theme } from "../theme";
import { MODALTYPE, ORDERTYPES } from "../../types/index.d";
import { openModal, updateExchangeType } from "../slices/userSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";

import WalletIcon from "@mui/icons-material/Wallet";

import EditNoteIcon from "@mui/icons-material/EditNote";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InfoIcon from "../../assets/navIcons/info-icon.svg";
import RewardsIcon from "../../assets/navIcons/rewards-icon.svg";
import PurchaseIcon from "../../assets/navIcons/purchase-icon.svg";
import RedeemIcon from "../../assets/navIcons/redeem-icon.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChallengesIcon from "../../assets/navIcons/challenges-icon.svg";

const gamesCard = {
  label: "Games",
  icon: <img src={GamesIcon} style={{ width: "2em" }} />,
  url: "",
  list: [
    {
      label: "Slots",
      icon: <img src={SlotsIcon} style={{ width: "2em" }} />,
      url: "slots",
    },
    {
      label: "Dice",
      icon: <img src={DiceIcon} style={{ width: "2em" }} />,
      url: "dice",
    },
    {
      label: "Slide",
      icon: <img src={SlideIcon} style={{ width: "2em" }} />,
      url: "slide",
    },
    {
      label: "Coin Flip",
      url: "coinflip",
      icon: <img src={CoinFlipIcon} style={{ width: "2em" }} />,
    },
    {
      label: "Plinko",
      icon: <img src={PlinkoIcon} style={{ width: "2em" }} />,
      url: "plinko",
    },
    {
      label: "Duel Arena",
      icon: <img src={DuelIcon} style={{ width: "2em" }} />,
      url: "duel-arena",
    },
    {
      label: "Limbo",
      icon: <img src={LimboIcon} style={{ width: "2em" }} />,
      url: "limbo",
    },
    {
      label: "Keno",
      icon: <img src={KenoIcon} style={{ width: "2em" }} />,
      url: "keno",
    },
    {
      label: "Blackjack",
      icon: <img src={BlackJackIcon} style={{ width: "2em" }} />,
      url: "blackjack",
    },
    {
      label: "Mines",
      icon: <img src={MinesIcon} style={{ width: "2em" }} />,
      url: "mines",
    },
    {
      label: "HiLo",
      icon: <img src={HiLoIcon} style={{ width: "2em" }} />,
      url: "hilo",
    },
  ],
};

const FavCard = {
  label: "Favourites",
  icon: (
    <StarBorderIcon style={{ marginLeft: "0.15em", marginRight: "0.15em" }} />
  ),
  url: "favourites",
};

const CahllengesCard = {
  label: "Challenges",
  icon: <img src={ChallengesIcon} style={{ width: "2em" }} />,
  url: "challenges",
};

const walletCard = {
  label: "Wallet",
  icon: <WalletIcon style={{ marginLeft: "0.15em", marginRight: "0.15em" }} />,
  url: "",
  list: [
    {
      label: "Purchase",
      icon: <img src={PurchaseIcon} style={{ width: "2em" }} />,
      url: MODALTYPE.EXCHANGE,
      exchangeType: ORDERTYPES.DEPOSIT,
    },
    {
      label: "Redeem",
      icon: <img src={RedeemIcon} style={{ width: "2em" }} />,
      url: MODALTYPE.EXCHANGE,
      exchangeType: ORDERTYPES.WITHDRAWAL,
    },
    {
      label: "Transactions",
      icon: (
        <ReceiptIcon style={{ marginLeft: "0.15em", marginRight: "0.15em" }} />
      ),
      url: "profile/transaction",
    },
    {
      label: "Orders",
      icon: (
        <EditNoteIcon style={{ marginLeft: "0.15em", marginRight: "0.15em" }} />
      ),
      url: "profile/order",
    },
  ],
};

const bonusCard = {
  label: "Rewards",
  icon: <img src={RewardsIcon} style={{ width: "2em" }} />,
  url: "",
  list: [
    {
      label: "Rakeback",
      url: "rakeback",
      icon: <img src={BonusIcon} style={{ width: "2em" }} />,
    },
    // {
    //   label: "Deposit Bonuses",
    //   url: "deposit-bonuses",
    //   icon: <img src={DepositBonusIcon} style={{ width: "2em" }} />,
    // },
    {
      label: "Refer A Friend",
      url: "affiliate",
      icon: <img src={ReferralIcon} style={{ width: "2em" }} />,
    },
    {
      label: "Wager Race",
      url: "wager-race",
      icon: <img src={RaceIcon} style={{ width: "2em" }} />,
    },
  ],
};

const infoCard = {
  label: "Info",
  icon: <img src={InfoIcon} style={{ width: "2em" }} />,
  url: "",
  list: [
    {
      label: "Fairness",
      url: "fairness",
      icon: <img src={FairnessIcon} style={{ width: "2em" }} />,
    },
    {
      label: "Help",
      url: "help",
      open: MODALTYPE.LIVESUPPORT,
      icon: <img src={HelpIcon} style={{ width: "2em" }} />,
    },
  ],
};
export const drawerItems = [
  {
    label: "Games",
    icon: <img src={GamesIcon} style={{ width: "2em" }} />,
    url: "",
    list: [
      {
        label: "Duel Arena",
        icon: <img src={DuelIcon} style={{ width: "2em" }} />,
        url: "duel-arena",
      },
      {
        label: "Plinko",
        icon: <img src={PlinkoIcon} style={{ width: "2em" }} />,
        url: "plinko",
      },
      {
        label: "Keno",
        icon: <img src={KenoIcon} style={{ width: "2em" }} />,
        url: "keno",
      },
      {
        label: "Slide",
        icon: <img src={SlideIcon} style={{ width: "2em" }} />,
        url: "slide",
      },
      {
        label: "Dice",
        icon: <img src={DiceIcon} style={{ width: "2em" }} />,
        url: "dice",
      },
      {
        label: "Mines",
        icon: <img src={MinesIcon} style={{ width: "2em" }} />,
        url: "mines",
      },
      {
        label: "Limbo",
        icon: <img src={LimboIcon} style={{ width: "2em" }} />,
        url: "limbo",
      },
      {
        label: "Coin Flip",
        url: "coinflip",
        icon: <img src={CoinFlipIcon} style={{ width: "2em" }} />,
      },
      {
        label: "Blackjack",
        icon: <img src={BlackJackIcon} style={{ width: "2em" }} />,
        url: "blackjack",
      },
      {
        label: "Hilo",
        icon: <img src={HiLoIcon} style={{ width: "2em" }} />,
        url: "hilo",
      },
    ],
  },
  {
    label: "Rewards",
    url: "rakeback",
    icon: <img src={BonusIcon} style={{ width: "2em" }} />,
  },
  // {
  //   label: "Deposit Bonuses",
  //   url: "deposit-bonuses",
  //   icon: <img src={DepositBonusIcon} style={{ width: "2em" }} />,
  // },
  {
    label: "Affiliate",
    url: "affiliate",
    icon: <img src={ReferralIcon} style={{ width: "2em" }} />,
  },
  {
    label: "Wager Race",
    url: "wager-race",
    icon: <img src={RaceIcon} style={{ width: "2em" }} />,
  },
];

const bottomListIcon = [
  {
    label: "Fairness",
    url: "fairness",
    icon: <img src={FairnessIcon} style={{ width: "2em" }} />,
  },
  {
    label: "Help",
    url: "help",
    open: MODALTYPE.LIVESUPPORT,
    icon: <img src={HelpIcon} style={{ width: "2em" }} />,
  },
];

const SideDrawerList = ({
  drawerOpen,
  setDrawerOpen,
  drawerListOpen,
  setDrawerListOpen,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const [showAllGames, setShowAllGames] = useState(false);

  const handleNavigate = (url: string) => {
    if (!matchesMD) {
      setDrawerOpen(false);
    }
    if (url === "slots") {
      navigate(`/${url}`);
    } else {
      navigate(`/game/${url}`);
    }
  };

  const handleNavigateBottom = (url: any) => {
    if (!matchesMD) {
      setDrawerOpen(false);
    }

    if (url.open) {
      dispatch(openModal(url.open));
    } else {
      navigate(`/${url.url}`);
    }
  };

  const handleDrawerOpen = (
    index = 0,
    item: { label: string; url: string }
  ) => {
    if (item.label === "Games") {
      setDrawerOpen(true);
      setDrawerListOpen(!drawerListOpen);
    } else {
      if (!matchesMD) {
        setDrawerOpen(false);
      }
      navigate(item.url);
    }
  };

  const handleWallet = (url: string, exchangeType?: ORDERTYPES) => {
    if (!matchesMD) {
      setDrawerOpen(false);
    }

    if (url === MODALTYPE.EXCHANGE && exchangeType) {
      dispatch(updateExchangeType(exchangeType));
      dispatch(openModal(url));
    } else {
      navigate(`/${url}`);
    }
  };

  return (
    <>
      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent={drawerOpen ? "flex-start" : "center"}
          alignItems={drawerOpen ? "center" : "center"}
          gap={1}
          sx={{
            minHeight: 32,
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#213240",
          }}
          onClick={() => {
            handleWallet(FavCard.url);
          }}
        >
          <Link
            to={`${FavCard.url}`}
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              sx={{
                minWidth: 0,
                ml: 0.25,
                mr: drawerOpen ? 1 : "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {FavCard.icon}
            </Typography>

            <Typography
              sx={{ display: drawerOpen ? "block" : "none", mb: 0.5 }}
              variant="body1"
              textAlign={"center"}
            >
              {FavCard.label}
            </Typography>
          </Link>
        </Stack>
      </Card>
      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent={drawerOpen ? "flex-start" : "center"}
          alignItems={drawerOpen ? "center" : "center"}
          gap={1}
          sx={{
            minHeight: 32,
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#213240",
          }}
          onClick={() => {
            handleWallet(CahllengesCard.url);
          }}
        >
          <Link
            to={`${CahllengesCard.url}`}
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              sx={{
                minWidth: 0,
                ml: 0.25,
                mr: drawerOpen ? 1 : "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {CahllengesCard.icon}
            </Typography>

            <Typography
              sx={{ display: drawerOpen ? "block" : "none", mb: 0.5 }}
              variant="body1"
              textAlign={"center"}
            >
              {CahllengesCard.label}
            </Typography>
          </Link>
        </Stack>
      </Card>

      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          sx={{
            minHeight: 32,
            justifyContent: drawerOpen ? "initial" : "center",
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#273F55",
          }}
        >
          <Box
            sx={{
              minWidth: 0,
              // mr: drawerOpen ? 3 : "auto",
              display: drawerOpen ? "none" : "block",
              justifyContent: "center",
              color: "white",
            }}
          >
            {gamesCard.icon}
          </Box>

          <Typography
            sx={{ display: drawerOpen ? "block" : "none", width: "100%" }}
            variant="body1"
            textAlign={"center"}
          >
            {gamesCard.label}
          </Typography>
        </Stack>

        <Stack>
          {gamesCard.list.map((item, index) => {
            if (!showAllGames && index > 5) return null;
            return (
              <Stack
                direction="row"
                justifyContent={drawerOpen ? "flex-start" : "center"}
                alignItems="center"
                gap={drawerOpen ? 1 : 0.25}
                sx={{
                  px: drawerOpen ? 0.5 : "2px",
                  "&:hover": {
                    backgroundColor: "#273F55",
                  },
                }}
                onClick={() => {
                  if (!matchesMD) {
                    handleNavigate(item.url);
                  }
                }}
              >
                <Link
                  to={item.url === "slots" ? "/slots" : `/game/${item.url}`}
                  style={{
                    width: "100%",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1 : 0,
                      width: drawerOpen ? "auto" : "100%",
                      justifyContent: "center",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                  </Box>

                  <Typography
                    sx={{
                      // display: drawerOpen ? "block" : "none",
                      // width: drawerOpen ? "100%" : "0%",
                      fontSize: drawerOpen ? "14px" : "0px",
                      transition: "all 0.2s ease-in-out",
                    }}
                    variant="body1"
                    color="text.primary"
                  >
                    {item.label}
                  </Typography>
                </Link>
              </Stack>
            );
          })}
        </Stack>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#273F55",
            },
          }}
          onClick={() => setShowAllGames(!showAllGames)}
        >
          {showAllGames ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Card>

      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          sx={{
            minHeight: 32,
            justifyContent: drawerOpen ? "initial" : "center",
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#273F55",
          }}
        >
          <Box
            sx={{
              minWidth: 0,
              // mr: drawerOpen ? 3 : "auto",
              display: drawerOpen ? "none" : "block",
              justifyContent: "center",
              color: "white",
            }}
          >
            {walletCard.icon}
          </Box>

          <Typography
            sx={{ display: drawerOpen ? "block" : "none", width: "100%" }}
            variant="body1"
            textAlign={"center"}
          >
            {walletCard.label}
          </Typography>
        </Stack>
        {walletCard.list.map((item, index) => {
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
              sx={{
                px: drawerOpen ? 0.5 : "4px",
                minHeight: "37px",
                "&:hover": {
                  backgroundColor: "#273F55",
                },
              }}
              onClick={() => {
                if (item?.exchangeType || !matchesMD) {
                  handleWallet(item.url, item?.exchangeType);
                }
              }}
            >
              <Link
                to={!item?.exchangeType ? `${item.url}` : ""}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 1 : 0,
                    width: drawerOpen ? "auto" : "100%",
                    justifyContent: "center",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  sx={{
                    // display: drawerOpen ? "block" : "none",
                    // width: drawerOpen ? "100%" : "0%",
                    fontSize: drawerOpen ? "14px" : "0px",
                    transition: "all 0.2s ease-in-out",
                  }}
                  variant="body1"
                  color="text.primary"
                >
                  {item.label}
                </Typography>
              </Link>
            </Stack>
          );
        })}
      </Card>

      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          sx={{
            minHeight: 32,
            justifyContent: drawerOpen ? "initial" : "center",
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#273F55",
          }}
        >
          <Box
            sx={{
              minWidth: 0,
              // mr: drawerOpen ? 3 : "auto",
              display: drawerOpen ? "none" : "block",
              justifyContent: "center",
              color: "white",
            }}
          >
            {bonusCard.icon}
          </Box>

          <Typography
            sx={{ display: drawerOpen ? "block" : "none", width: "100%" }}
            variant="body1"
            textAlign={"center"}
          >
            {bonusCard.label}
          </Typography>
        </Stack>
        {bonusCard.list.map((item, index) => {
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
              sx={{
                px: drawerOpen ? 0.5 : "4px",
                minHeight: "37px",
                "&:hover": {
                  backgroundColor: "#273F55",
                },
              }}
              onClick={() => {
                if (!matchesMD) {
                  handleWallet(item.url);
                }
              }}
            >
              <Link
                to={`${item.url}`}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 1 : 0,
                    width: drawerOpen ? "auto" : "100%",
                    justifyContent: "center",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                </Box>

                <Typography
                  sx={{
                    fontSize: drawerOpen ? "14px" : "0px",
                    transition: "all 0.2s ease-in-out",
                  }}
                  variant="body1"
                  color="text.primary"
                >
                  {item.label}
                </Typography>
              </Link>
            </Stack>
          );
        })}
      </Card>

      <Card
        sx={{
          m: 1,
          backgroundColor: "#213240",
          height: "auto",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
          sx={{
            minHeight: 32,
            justifyContent: drawerOpen ? "initial" : "center",
            transition: "all 0.2s ease-in-out",
            backgroundColor: "#273F55",
          }}
        >
          <Box
            sx={{
              minWidth: 0,
              // mr: drawerOpen ? 3 : "auto",
              display: drawerOpen ? "none" : "block",
              justifyContent: "center",
              color: "white",
            }}
          >
            {infoCard.icon}
          </Box>

          <Typography
            sx={{ display: drawerOpen ? "block" : "none", width: "100%" }}
            variant="body1"
            textAlign={"center"}
          >
            {infoCard.label}
          </Typography>
        </Stack>
        {infoCard.list.map((item, index) => {
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
              sx={{
                px: drawerOpen ? 0.5 : "4px",
                minHeight: "37px",
                "&:hover": {
                  backgroundColor: "#273F55",
                },
              }}
              onClick={() => {
                if (item.label === "Help" || !matchesMD) {
                  handleNavigateBottom(item);
                }
              }}
            >
              <Link
                to={item.label !== "Help" ? `${item.url}` : ""}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 1 : 0,
                    width: drawerOpen ? "auto" : "100%",
                    justifyContent: "center",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                </Box>

                <Typography
                  sx={{
                    fontSize: drawerOpen ? "14px" : "0px",
                    transition: "all 0.2s ease-in-out",
                  }}
                  variant="body1"
                  color="text.primary"
                >
                  {item.label}
                </Typography>
              </Link>
            </Stack>
          );
        })}
      </Card>
      {/* <Stack sx={{ position: "absolute", bottom: "1%", width: "100%" }}>
        {bottomListIcon.map((item, index) => (
          <ListItem key={item.label} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: drawerOpen ? "initial" : "center",
                width: "100%",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "#213240",
                },
              }}
              onClick={() => handleNavigateBottom(item)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawerOpen ? 3 : "auto",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{ opacity: drawerOpen ? 1 : 0, fontSize: 32 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </Stack> */}
    </>
  );
};

export default SideDrawerList;
