import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";
import Chat from "../../services/chat";
import { EXCHANGETYPE, IMessage } from "../../types/index.d";
import { RootState } from "../store";
import { Order } from "./orderSlice";
import { User } from "./userSlice";

export interface AdminChat {
  user: User;
  order: Order;
  messages: IMessage[];
}

export interface IRain {
  _id: string;
  totalRained: number;
  expired: boolean;
  expireAt: Date;
  records: IRecord[];
  createdAt: Date;
  startedAt: Date;
}
export interface IRecord {
  userId: string;
  totalRained: number;
  userName: string;
}

interface AdminChatInput {
  user: User;
  order: any;
  message: IMessage;
}
interface SocketState {
  socket: Socket | null;
  allChat: IMessage[];
  adminChat: AdminChat[];
  openChat: number | null;
  orderChat: IMessage[];
  rain: IRain | null;
}

const initialState: SocketState = {
  socket: null,
  allChat: [],
  openChat: null,
  adminChat: [],
  orderChat: [],
  rain: null,
};

export const getAllMessages = createAsyncThunk(
  "chat/allChat",
  async (_, thunkAPI) => {
    try {
      const mine = await Chat.allMessages();

      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const getAllAdminMessages = createAsyncThunk(
  "chat/allAdminChat",
  async (_, thunkAPI) => {
    try {
      const mine = await Chat.allAdminMessages();

      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Slice definition
export const chatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage(state, action: PayloadAction<IMessage>) {
      state.allChat.push(action.payload);
    },
    updateAllMessages(state, action: PayloadAction<IMessage[]>) {
      state.allChat = [...action.payload];
    },
    updateAllAdminMessages(state, action: PayloadAction<AdminChat[]>) {
      state.adminChat = action.payload?.filter(
        (order: any) => order.order.type !== EXCHANGETYPE.DEPOSITCRYPTO
      );
    },
    delMessage(state, action: PayloadAction<string>) {
      state.allChat = state.allChat.filter((x) => x._id !== action.payload);
    },
    openChatTo(state, action: PayloadAction<number>) {
      state.openChat = action.payload;
    },
    newOrder(state, action: PayloadAction<AdminChat>) {
      console.log("neww oprder", action.payload);
      state.adminChat.unshift(action.payload);
    },
    updateRain(state, action: PayloadAction<IRain>) {
      state.rain = action.payload;
    },
    updateOrderById(state, action: PayloadAction<Order>) {
      const orderId = action.payload._id;
      if (state.adminChat) {
        state.adminChat = state.adminChat.filter(
          (orderData) => orderData.order && orderData.order._id !== orderId
        );
      }
    },
    updateReadAtAdmin(
      state,
      action: PayloadAction<{ messages: IMessage[]; orderId: string }>
    ) {
      const orderId = action.payload.orderId;
      state.adminChat = state.adminChat.map((chatData) => {
        if (chatData.order._id === orderId) {
          chatData.messages = action.payload.messages;
        }
        return chatData;
      });
    },
    addAdminMessage(state, action: PayloadAction<AdminChatInput>) {
      state.adminChat.map((chat) => {
        if (chat.order._id === action.payload.order._id) {
          if (chat.messages?.length) {
            chat.messages.push(action.payload.message as any);
            return chat;
          }
          chat.messages = [action.payload.message as any];
          return chat;
        }
        return chat;
      });
    },
    // other reducers
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMessages.fulfilled, (state, action) => {
        state.allChat = action.payload.messages;
      })
      .addCase(getAllAdminMessages.fulfilled, (state, action) => {
        // state.adminChat = action.payload;
        // console.log("admin chat ", action.payload);
        state.adminChat = action.payload?.filter(
          (order: any) => order.order.type !== EXCHANGETYPE.DEPOSITCRYPTO
        );
      });
  },
});

// Expose the actions
export const {
  addMessage,
  openChatTo,
  newOrder,
  addAdminMessage,
  updateOrderById,
  updateReadAtAdmin,
  delMessage,
  updateAllMessages,
  updateAllAdminMessages,
  updateRain,
} = chatReducer.actions;

// Selectors
export const selectAllChat = (state: RootState) => state.chat.allChat;
export const selectOpenChatTo = (state: RootState) => state.chat.openChat;
export const selectAdminChat = (state: RootState) => state.chat.adminChat;
export const selectRain = (state: RootState) => state.chat.rain;
// other selectors

// Reducer
export default chatReducer.reducer;
