import {
  RoundNumber,
  RoundNumberFiveDecimalPoints,
  RoundNumberFourDecimalPoints,
  RoundNumberThreeDecimalPoints,
  RoundProfitOnWin,
} from "../../utils";
import {
  selectBalance,
  selectFreeCash,
  selectIsPlayingSlots,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { MenuItem, Stack, Typography } from "@mui/material";
import { theme } from "../theme";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { TokenIcon } from "../tokenIcon";
import { TOKENTYPE } from "../../types/index.d";

export const Balance = () => {
  const balance = useAppSelector(selectBalance);
  const isPlayingSlots = useAppSelector(selectIsPlayingSlots);
  const balanceRef = useRef(balance);
  const [currentBalance, setCurrentBalance] = useState(balance);
  const [balanceToAnimate, setBalanceToAnimate] = useState(0);

  useEffect(() => {
    if (balance !== balanceRef.current) {
      let diff =
        (RoundProfitOnWin(balance) * 100000 -
          RoundProfitOnWin(balanceRef.current) * 100000) /
        100000;
      let balanceDiff = RoundProfitOnWin(diff);
      balanceRef.current = balance;
      setBalanceToAnimate(balanceDiff);
    }
  }, [balance]);

  const handleAnimationEnd = () => {
    setBalanceToAnimate(0);
    setCurrentBalance(balance);
  };

  return (
    <Stack
      sx={{
        py: 0.75,
        px: 2,
        borderRadius: "5px",
        backgroundColor: "#273F55",
        width: "100%",
        minWidth: "100px"
      }}
      alignItems="center"
      gap={0.5}
      direction="row"
    >
      <TokenIcon width={30} />
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ fontSize: { md: "18px", xs: "14px" } }}
      >
        {isPlayingSlots ? "In Play" : RoundNumber(currentBalance)}
      </Typography>

      {!isPlayingSlots && balanceToAnimate ? (
        <Typography
          variant="h6"
          key={balanceToAnimate}
          sx={{ color: balanceToAnimate > 0 ? "green" : "red" }}
          className={styles.animatedChange}
          onAnimationEnd={() => handleAnimationEnd()}
        >
          {balanceToAnimate > 0
            ? `+${RoundNumberFourDecimalPoints(balanceToAnimate)}`
            : `${RoundNumberFourDecimalPoints(balanceToAnimate)}`}
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export const BalanceSelection = ({
  setTokenSelection,
  tokenSelection,
}: any) => {
  const freeBalance = useAppSelector(selectFreeCash);
  const balance = useAppSelector(selectBalance);

  return (
    <>
      <MenuItem
        value={TOKENTYPE.SWEEP}
        onClick={() => setTokenSelection(TOKENTYPE.SWEEP)}
        sx={{
          bgcolor: tokenSelection === TOKENTYPE.SWEEP ? "#273F55" : "#1C2B39",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} alignItems="center" gap={0.25}>
            <TokenIcon />
            <Typography
              color="text.primary"
              variant="h6"
              fontWeight={600}
              sx={{ fontSize: { md: "18px", xs: "14px" } }}
            >
              SC
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ fontSize: { md: "18px", xs: "14px" } }}
          >
            {RoundNumber(balance)}
          </Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        value={TOKENTYPE.FREE}
        onClick={() => setTokenSelection(TOKENTYPE.FREE)}
        sx={{
          bgcolor: tokenSelection === TOKENTYPE.FREE ? "#273F55" : "#1C2B39",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} alignItems="center" gap={0.25}>
            <TokenIcon freeCash />
            <Typography
              color="text.primary"
              variant="h6"
              fontWeight={600}
              sx={{ fontSize: { md: "18px", xs: "14px" } }}
            >
              FC
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ fontSize: { md: "18px", xs: "14px" } }}
          >
            {RoundNumber(freeBalance)}
          </Typography>
        </Stack>
      </MenuItem>
    </>
  );
};

export const FreeCash = () => {
  const balance = useAppSelector(selectFreeCash);
  const balanceRef = useRef(balance);
  const [currentBalance, setCurrentBalance] = useState(balance);
  const [balanceToAnimate, setBalanceToAnimate] = useState(0);
  const isPlayingSlots = useAppSelector(selectIsPlayingSlots);
  useEffect(() => {
    if (balance !== balanceRef.current) {
      let diff =
        (RoundProfitOnWin(balance) * 100000 -
          RoundProfitOnWin(balanceRef.current) * 100000) /
        100000;
      let balanceDiff = RoundProfitOnWin(diff);
      balanceRef.current = balance;
      setBalanceToAnimate(balanceDiff);
    }
  }, [balance]);

  const handleAnimationEnd = () => {
    setBalanceToAnimate(0);
    setCurrentBalance(balance);
  };

  useEffect(() => {
    if (balanceToAnimate !== 0) {
      setCurrentBalance(balance);
    }
  }, [balanceToAnimate]);

  return (
    <Stack
      sx={{
        py: 0.75,
        px: 2,
        borderRadius: "5px",
        backgroundColor: "#273F55",

        width: "100%",
      }}
      alignItems="center"
      gap={0.5}
      direction="row"
    >
      <TokenIcon width={30} freeCash />
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ fontSize: { md: "18px", xs: "14px" } }}
      >
        {isPlayingSlots ? "In Play" : RoundNumber(currentBalance)}
      </Typography>

      {!isPlayingSlots && balanceToAnimate ? (
        <Typography
          variant="h6"
          key={balanceToAnimate}
          sx={{ color: balanceToAnimate > 0 ? "green" : "red" }}
          className={styles.animatedChange}
          onAnimationEnd={() => handleAnimationEnd()}
        >
          {balanceToAnimate > 0
            ? `+${RoundNumberFourDecimalPoints(balanceToAnimate)}`
            : `${RoundNumberFourDecimalPoints(balanceToAnimate)}`}
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
};
