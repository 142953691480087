// MyComponent.tsx
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  selectNotifications,
  clearNotifications,
} from "../slices/notificationSlice";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import styles from "./index.module.scss";

const formatMessage = (text: string) => {
  const html = text.replace(
    "[TOKEN]",
    `<img src=${RWTokenIcon} alt="Token Image" style="width: 1.5em; vertical-align: middle;"/>`
  );
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    notifications.forEach((notification) => {
      if (!notification.isDismissed) {
        let message = notification.message;
        let variant = notification.variant;
        if (message.includes("[TOKEN]")) {
          variant = "custom";
        }
        enqueueSnackbar(<span>{formatMessage(message)}</span>, {
          variant: variant as any,
          className: variant === "custom" ? styles.customSnackbar : "",
        });
        dispatch(clearNotifications());
      }
    });
  }, [notifications, dispatch, enqueueSnackbar]);

  return null;
};

export default Notifications;
