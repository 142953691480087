import {
  Stack,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { theme } from "../theme";
import {
  EXCHANGETYPE,
  IMessage,
  MESSAGETYPE,
  MODALTYPE,
  ORDERTYPES,
  USERTYPE,
} from "../../types/index.d";
import {
  getLevelByTotalWagered,
  getPlayerImg,
  getPlayerLevelData,
} from "../../utils";
import { UserTypeIcon } from "./icons";
import LogoImgMobile from "../../assets/runewagerMobile.svg";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import FCTokenIcon from "../../assets/freeCashToken.svg";
import { GAMES, GAMESICONS } from "../../constants/games";
import styles from "./index.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatContext from "../../contexts/chat/context";
import {
  ADMIN_DELETE_MESSAGE_USER,
  ADMIN_MUTE_USER,
} from "../../constants/socket";
import { useAppSelector } from "../store/hooks";
import {
  openModal,
  selectToken,
  updateExchangeType,
  updateTippingUser,
} from "../slices/userSlice";
import { chatDictionary, parseHiddenUsername } from "../../constants";
import { customEmojisData } from "../../constants/messaages";
import { useDispatch } from "react-redux";
import ChallengesIcon from "../../assets/navIcons/challenges-icon.svg";
import { orderForSlots } from "../slices/slotsSlice";
interface ITextBubble {
  index: number;
  userName: string;
  message: IMessage;
  renderTimeDifference: any;
  rank?: number;
  userType?: string;
  userId?: string;
  id?: string;
  handleToggle?: any;
  totalWagered?: number;
}

const getAvatar = (
  imgSrc: string,
  isOwner: boolean,
  messageType: MESSAGETYPE,
  game: any
) => {
  if (
    messageType === MESSAGETYPE.GAMES &&
    (game.includes("Challenge Ended") || game.includes("New Challenge"))
  ) {
    return ChallengesIcon;
  }
  if (messageType === MESSAGETYPE.GAMES) {
    return GAMESICONS[game as GAMES]?.img || "";
  }

  if (messageType === MESSAGETYPE.LEVELUP) {
    return getPlayerImg(game)?.imgSrc || "";
  }
  return isOwner ? LogoImgMobile : imgSrc;
};

export const isModUser = (userType: string) => {
  return (
    userType === USERTYPE.ADMIN ||
    userType === USERTYPE.OWNER ||
    userType === USERTYPE.CASHIER ||
    userType === USERTYPE.MOD
  );
};

const getTitle = (userType: string) => {
  if (userType === USERTYPE.CASHIER) {
    return "Cashier";
  }
  return "Owner";
};

const getUserName = (messageType: MESSAGETYPE, userName: string) => {
  if (messageType === MESSAGETYPE.GAMES) {
    return userName.replaceAll("_", " ");
  }

  if (messageType === MESSAGETYPE.LEVELUP) {
    return "LEVEL UP";
  }
  return userName;
};

export function containsOnlyEmojis(str: string) {
  const emojiRegex =
    /^[\uD83C-\uDBFF\uDC00-\uDFFF\u2600-\u27BF\uFE0F\u1F900-\u1F9FF\u1FA70-\u1FAFF]+$/;

  const tokens = str.split(/\s+/);

  return tokens.every((token) => emojiRegex.test(token));
}

export function containsOnlyCustomEmojis(str: string) {
  let newString = str;
  customEmojisData.forEach((emoji) => {
    const regex = new RegExp(emoji.id, "g");
    newString = newString.replaceAll(regex, "");
  });
  return newString.trim() === "";
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function containsCharactersAndNumbers(inputString: string) {
  const pattern = /[a-zA-Z0-9]/;
  return pattern.test(inputString);
}

export function checkContainsAllEmojis(str: string) {
  let newString = str;

  customEmojisData.forEach((emoji) => {
    const regex = new RegExp(escapeRegExp(emoji.id), "g");
    newString = newString.replaceAll(regex, "");
  });
  const isString = containsCharactersAndNumbers(newString);
  if (isString) return false;
  let newStrings = newString.split(/\s+/).filter((token) => token.length > 0);

  const emojiRegex =
    /^[\uD83C-\uDBFF\uDC00-\uDFFF\u2600-\u27BF\uFE0F\u1F900-\u1F9FF\u1FA70-\u1FAFF]+$/;

  return (
    newStrings.length > 0 && newStrings.every((token) => emojiRegex.test(token))
  );
}

export const TextBubble = (props: ITextBubble) => {
  const {
    index,
    userName,
    id,
    message,
    renderTimeDifference,
    rank,
    userType = "User",
    userId = "",
    handleToggle,
    totalWagered,
  } = props;
  const getPlayerLevel = getPlayerLevelData(rank);
  const { socket } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const accessToken = useAppSelector(selectToken);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMute = () => {
    const isMod = isModUser(userType);
    if (isMod) {
      socket?.emit(ADMIN_MUTE_USER, {
        id: userId,
        accessToken,
      });
    }
  };

  const handleDel = () => {
    const isMod = isModUser(userType);
    if (isMod) {
      socket?.emit(ADMIN_DELETE_MESSAGE_USER, {
        id: id,
        accessToken,
      });
    }
  };

  const isAdmin = isModUser(userType);

  const isOwner =
    message?.userType === "ADMIN" ||
    message?.userName === "Auto Message" ||
    message?.userType === USERTYPE.CASHIER;

  const isGame =
    message.type === MESSAGETYPE.GAMES || message.type === MESSAGETYPE.LEVELUP;
  const regex = /@(\w+)/;

  const match = message.text.match(regex);
  let isUserTagged = false;
  if (match) {
    isUserTagged =
      match[1].toLocaleLowerCase() === userName.toLocaleLowerCase();
  }

  const handleNavigate = () => {
    if (
      message.userName === GAMES.SLIDE &&
      message.text.includes("Bonus round has started")
    ) {
      navigate("/game/slide");
      if (!matchesMD) {
        handleToggle(false);
      }
    }
  };

  const handleMessage = () => {
    if (isGame) {
      return (
        <Typography
          sx={{
            textWrap: "pretty",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
          }}
          color="#9897A9"
          // dangerouslySetInnerHTML={{
          //   __html: formatMessage(message.text, message.type),
          // }}
        >
          {formatMessage(message.text, isAdmin)}
        </Typography>
      );
    }

    if (message.text.includes('{"url":"https://')) {
      try {
        const previewData = JSON.parse(message.text);
        return (
          <img
            src={previewData.url}
            alt={previewData.url}
            style={{ width: "80%" }}
          />
        );
      } catch {
        return null;
      }
    }

    if (message.text.includes("{{{custom-")) {
      try {
        let text = message.text;
        const toIncrasesize =
          containsOnlyCustomEmojis(text) || checkContainsAllEmojis(text);

        customEmojisData.forEach((emoji) => {
          const regex = new RegExp(emoji.id, "g");
          text = text.replaceAll(
            regex,
            `<img src="${emoji.imgUrl}" alt="${emoji.id}" style="width:${
              toIncrasesize ? "40px" : "15px"
            };height:${
              toIncrasesize ? "40px" : "15px"
            }; display:inline-block;" />`
          );
        });

        if (text) {
          return (
            <Typography
              sx={{
                textWrap: "pretty",
                wordBreak: "break-word",
                whiteSpace: "break-spaces",
                fontSize: toIncrasesize ? "2em" : "auto",
                display: "flex",
              }}
              color="#9897A9"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></Typography>
          );
        }
        return null;
      } catch {
        return null;
      }
    }

    if (containsOnlyEmojis(message.text) && message.text.length < 7) {
      return (
        <Typography
          sx={{
            textWrap: "pretty",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
            fontSize: "2em",
          }}
          color="#9897A9"
        >
          {createLinkedMessage(message.text, handleToggle, matchesMD)}
        </Typography>
      );
    }

    return (
      <Typography
        sx={{
          textWrap: "pretty",
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        }}
        color="#9897A9"
      >
        {createLinkedMessage(message.text, handleToggle, matchesMD)}
      </Typography>
    );
  };
  const messageData = handleMessage();

  const handleOpenTip = () => {
    if (isGame) return;
    dispatch(updateExchangeType(ORDERTYPES.TIP));
    dispatch(updateTippingUser(message.userName));
    dispatch(openModal(MODALTYPE.EXCHANGE));
  };
  if (!messageData) {
    return null;
  }
  return (
    <ListItem
      key={index}
      onClick={() => handleNavigate()}
      className={message?.userName === "RAIN" ? styles.gameBorder : ""}
      sx={{
        m: 1,
        // mr: 0,
        borderRadius: "8px",
        backgroundColor: userName === message.userName ? "#273F55" : "#213240",
        alignItems: "flex-start",
        userSelect: "text",
        boxShadow: isUserTagged
          ? "inset 0 0 5px 0 #ffffffb5"
          : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
      secondaryAction={
        isModUser(userType) ? (
          <Box sx={{ display: isModUser(userType) ? "block" : "none" }}>
            <IconButton
              onClick={(e) => handleClick(e)}
              edge="end"
              aria-label="delete"
              sx={{ color: "white" }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              disableScrollLock={true}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleMute}>Mute</MenuItem>
              <MenuItem onClick={handleDel}>Delete</MenuItem>
            </Menu>
          </Box>
        ) : null
      }
    >
      <ListItemAvatar sx={{ position: "relative", mt: 1 }}>
        <Tooltip
          title={
            isGame || userName === message.userName ? null : (
              <React.Fragment>
                <MenuItem
                  sx={{ background: "#273F55", borderRadius: "4px", ml: -1 }}
                  onClick={handleOpenTip}
                >
                  Tip
                </MenuItem>
              </React.Fragment>
            )
          }
          placement="right"
        >
          <Stack>
            <Avatar
              variant="square"
              src={getAvatar(
                getPlayerLevel.imgSrc,
                isOwner,
                message.type,
                message.userName
              )}
              sx={{
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
            ></Avatar>
            {totalWagered !== undefined && !isOwner ? (
              <Stack
                sx={{
                  bgcolor:
                    userName === message.userName ? "#213240" : "#273F55",
                  position: "absolute",
                  bottom: "-10%",
                  right: 0,
                  transform: "translateX(-50%)",
                  p: 0.5,
                  borderRadius: 8,
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>
                  {getLevelByTotalWagered(totalWagered)}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Stack direction="row" gap={0.25} alignItems="center">
                {message.userType && (
                  <Tooltip
                    title={
                      isOwner ? getTitle(message?.userType) : message.userType
                    }
                    arrow
                    placement="bottom"
                  >
                    <Stack>
                      <UserTypeIcon userType={message.userType} />
                    </Stack>
                  </Tooltip>
                )}
                <Typography
                  onClick={handleOpenTip}
                  sx={{ cursor: !isGame ? "pointer" : "auto" }}
                >
                  {getUserName(message.type, message.userName)}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              color={theme.palette.text.disabled}
              sx={{ opacity: 0.7, fontSize: "0.75em" }}
            >
              {message?.createdAt && renderTimeDifference(message?.createdAt)}
            </Typography>
          </Stack>
        }
        secondary={messageData}
      />
    </ListItem>
  );
};

const slotDictionary = orderForSlots.map((slot) => {
  return {
    command: `/${slot}`,
    isDiff: false,
    path: `/slots/${slot}`,
  };
});
const createChatDictionary = [...chatDictionary, ...slotDictionary];

const createLinkedMessage = (
  text: string,
  handleToggle: any,
  matchesMD: boolean
) => {
  const regex = new RegExp(
    `(${createChatDictionary.map((item) => item.command).join("|")})`,
    "g"
  );
  const parts = text.split(regex);

  const handleLinkClick = (e: any) => {
    if (!matchesMD) {
      handleToggle(false);
    }
  };

  return parts.map((part, index) => {
    const commandObj = createChatDictionary.find(
      (item) => item.command === part
    );
    if (commandObj) {
      if (commandObj?.isDiff) {
        return (
          <Link
            key={index}
            to={commandObj.path}
            style={{ color: "#33EC3D" }}
            target="_blank"
            onClick={handleLinkClick}
            rel="noopener noreferrer"
          >
            {commandObj.command}
          </Link>
        );
      } else {
        return (
          <Link
            key={index}
            to={commandObj.path}
            style={{ color: "#33EC3D" }}
            onClick={handleLinkClick}
          >
            {commandObj.command}
          </Link>
        );
      }
    }
    return part;
  });
};

const formatMessage = (text: string, isAdmin: boolean) => {
  const newMessage = parseHiddenUsername(text, isAdmin);
  let html = newMessage;
  if (html.includes("[TOKEN]")) {
    html = newMessage.replaceAll(
      "[TOKEN]",
      `<img src=${RWTokenIcon}  alt="Token Image" style="width: 1.5em; vertical-align: middle;"/>`
    );
  }
  if (html.includes("[FREETOKEN]")) {
    html = newMessage.replaceAll(
      "[FREETOKEN]",
      `<img src=${FCTokenIcon}  alt="Token Image" style="width: 1.25em; vertical-align: middle;"/>`
    );
  }
  // let html = newMessage.replaceAll(
  //   "[TOKEN]",
  //   `<img src=${RWTokenIcon}  alt="Token Image" style="width: 1.5em; vertical-align: middle;"/>`
  // );

  // html = newMessage.replaceAll(
  //   "[FREETOKEN]",
  //   `<img src=${FCTokenIcon}  alt="Token Image" style="width: 1.25em; vertical-align: middle;"/>`
  // );
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
