import React from "react";
import { Card as MuiCard } from "@mui/material";
import styles from "./index.module.scss";
import BackCard from "../../assets/blackjack/blackjack-back.svg";
import CardImages from "../../constants/blackJack";
import { motion, AnimatePresence } from "framer-motion";
interface CardProps {
  card: {
    suit: string;
    rank: string;
  } | null;
  hide: boolean;
  border?: string;
}

const Card: React.FC<CardProps> = ({ card, hide, border }) => {
  const cardImagePath =
    card && !hide && card?.rank !== "U"
      ? CardImages[card.suit][card.rank]
      : BackCard;
  const topAndBottom = card === null || hide ? "" : card.rank;
  const cardClass = topAndBottom !== "" ? styles.root : styles.rootBack;

  return (
    <MuiCard
      className={cardClass}
      style={{
        perspective: 1000,
        position: "relative",
        height: "6.5em",
        background: "transparent",
        // width: "100%",
      }}
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={hide ? "back" : "front"}
          initial={{ rotateY: hide ? 0 : 180, background: "transparent" }}
          animate={{ rotateY: 0 }}
          exit={{ rotateY: hide ? 0 : -180 }}
          transition={{ duration: 0.25 }}
          style={{
            position: "absolute",
            height: "100%",
            background: "transparent",
          }}
        >
          <img
            alt={card ? `${card.suit} ${card.rank}` : "Back"}
            src={cardImagePath}
            style={{ border: border, borderRadius: "7px" }}
            className={styles.cardImg}
          />
        </motion.div>
      </AnimatePresence>
    </MuiCard>
  );
};

export default Card;
